const ModalTermo = () => {

  return (
    <div className="modal fade modalbox" id="termsModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Termos e Condições</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="container col-md-7">
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>Pegatroco</h4>
                        <p className="text-dark">
                            Como parte integrante dos <a href="https://pegatroco.com.br/termos-uso" className="text-dark fw-bold" target="_blank" rel="noreferrer">Termos de Uso</a> e <a href="https://pegatroco.com.br/politica-cookie" className="text-dark fw-bold" target="_blank" rel="noreferrer">Políticas de Cookies</a> da Plataforma Pegatroco, este documento, denominado Política de Privacidade, tem por finalidade estabelecer as regras sobre a obtenção, uso e armazenamento dos dados e informações coletadas dos USUÁRIOS, além do registro de suas atividades de acordo com as leis em vigor no Brasil.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            Quando o USUÁRIO aceita essa Política de Privacidade confere sua livre e expressa concordância com os termos aqui estipulados.
                        </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>1. Coleta de dados e informações</h4>
                        <p className="text-dark">
                            <strong className="fs-5">1.1.</strong> Os dados são coletados quando os USUÁRIOS interagirem com as funcionalidades existentes na Plataforma Pegatroco, fornecendo as informações voluntariamente, como na criação de cadastro e na contratação de serviços.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">1.2.</strong> As informações que a Plataforma Pegatroco poderá coletar incluem, mas não se limitam a:
                        </p>
                        <p className="text-dark">
                            a) CPF;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            b) RG;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            c) Data de Nascimento;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            d) E-mail;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            e) Número do telefone celular;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            f) Criação de senha;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            g) Dados da conta bancária.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">1.3.</strong> A Plataforma Pegatroco poderá fazer o uso de cookies, cabendo ao USUÁRIO configurar o seu navegador de Internet caso deseje bloqueá-los. Nesta hipótese, algumas funcionalidades da Plataforma Pegatroco poderão ser limitadas.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">1.4.</strong> Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação pelo USUÁRIO, no entanto, respeitarão sempre a legislação vigente, os termos desta política e as opções do USUÁRIO a respeito de sua coleta e armazenamento.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">1.5.</strong> A Pegatroco não é responsável pela veracidade ou precisão nas informações prestadas ou inseridas pelo USUÁRIO ou pela sua desatualização, sendo de responsabilidade do USUÁRIO prestá-las com exatidão e atualizá-las.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">1.6.</strong> A Plataforma Pegatroco não se destina a menores de 18 (dezesseis) anos de idade. Caso o USUÁRIO não possua a idade mínima exigida, ele não deve acessar e utilizar nossa Plataforma.
                        </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>2. Uso de dados</h4>
                        <p className="text-dark">
                            <strong className="fs-5">2.1.</strong> Os dados são coletados quando os USUÁRIOS interagirem com as funcionalidades existentes na Plataforma Pegatroco, fornecendo as informações voluntariamente, como na criação de cadastro e na contratação de serviços.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">2.2.</strong> As informações que a Plataforma Pegatroco poderá coletar incluem, mas não se limitam a:
                        </p>
                        <p className="text-dark">
                            a) Identificar e autenticar os USUÁRIOS adequadamente;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            b) Administrar, prestar os serviços e cumprir as obrigações decorrentes do pedido de contratação dos serviços disponibilizados;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            c) Atender adequadamente às suas solicitações e dúvidas;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            d) Manter os cadastros atualizados para fins de contato por telefone, correio eletrônico, SMS ou por outros meios de comunicação;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            e) Aperfeiçoar seu uso e experiência da Plataforma Pegatroco;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            f) Efetuar estatísticas, estudos, pesquisas e levantamentos pertinentes às atividades do comportamento dos USUÁRIOS ao utilizarem a Plataforma Pegatroco, realizando tais operações de forma anonimizada;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            g) Informar sobre novidades, produtos, serviços, funcionalidades, conteúdos, notícias e demais eventos relevantes e de relacionamento com os USUÁRIOS;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            h)Resguardar direitos e obrigações relacionadas ao uso da Plataforma Pegatroco;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            i) Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            j) Compartilhar os dados coletados com parceiros da Pegatroco para a manutenção da Plataforma Pegatroco, de seus produtos e de seus serviços;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            k) Compartilhar e ceder os dados coletados para parceiros, contanto que para a finalidade específica de enriquecer sua base de dados e evitar a ocorrência de fraudes e riscos associados;
                        </p>
                        <br></br>
                        <p className="text-dark">
                            l) Compartilhar e ceder os dados coletados para as instituições financeiras responsáveis pelo produto/serviço contratado pelo USUÁRIO na Plataforma.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">2.2.</strong> A base de dados formada por meio da coleta de dados na Plataforma Pegatroco é de propriedade e responsabilidade da Pegatroco, e não será comercializada e/ou alugada para terceiros, podendo ser compartilhada apenas com seus parceiros de negócios.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">2.3.</strong> O USUÁRIO é corresponsável pelo sigilo de suas informações pessoais e financeiras. O compartilhamento de senhas e dados de acesso configura violação à esta Política de Privacidade.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">2.4.</strong> Desde já o USUÁRIO está ciente que a Plataforma Pegatroco poderá realizar o enriquecimento da sua base de dados, adicionando informações oriundas de outras fontes legítimas, o qual manifesta consentimento expresso ao concordar com os termos da presente Política de Privacidade.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">2.5.</strong> Internamente os dados de nossos USUÁRIOS são acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade, legalidade, necessidade e relevância para os objetivos da Pegatroco, além do compromisso de confidencialidade e preservação da privacidade nos termos desta Política de Privacidade.
                        </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>3. Armazenamento de dados e informações</h4>
                        <p className="text-dark">
                            <strong className="fs-5">3.1.</strong> Os dados coletados estarão armazenados em ambiente seguro e controlado. A Pegatroco se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de acessos não autorizados ocasionados por falhas, vírus ou quaisquer outras técnicas de invasão do banco de dados da Plataforma Pegatroco, salvo nos casos em que tiver dolo ou culpa, observado o estado da técnica disponível, uma vez que nenhum sistema de segurança pode ser considerado inviolável.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">3.2.</strong> Os dados obtidos do USUÁRIO poderão ser armazenados em servidor próprio da Pegatroco ou de terceiro contratado para esse fim, sejam eles alocados no Brasil ou no exterior, podendo ainda ser armazenados por meios de tecnologia de cloud computing e/ou outras que surjam futuramente, visando sempre a melhoria e aperfeiçoamento das atividades da Pegatroco.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">3.3.</strong> O USUÁRIO poderá solicitar a exibição, retificação ou ratificação dos dados pessoais que lhe dizem respeito, por meio das ferramentas de atendimento disponibilizadas na Plataforma Pegatroco, bem como a exclusão de todos os dados pessoais coletados e registrados pela Plataforma.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">3.4.</strong> A Plataforma Pegatroco poderá, para fins de auditoria e preservação de direitos, permanecer com o histórico de registro dos dados do USUÁRIO por até 5 (cinco) anos contados a partir do cancelamento de eventual Conta de Acesso, podendo a Pegatroco excluí-los definitivamente segundo sua conveniência em prazo menor. Este prazo poderá ser estendido nas hipóteses que a lei ou norma regulatória assim estabelecer, para atendimento à ordem judicial ou para preservação de direitos.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">3.5.</strong> Os dados preservados na condição acima indicada terão seu uso limitado às hipóteses descritas nos itens “c”, “h” e “i” da cláusula 2.1 da presente Política de Privacidade.
                        </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>4. Disposições gerais</h4>
                        <p className="text-dark">
                            <strong className="fs-5">4.1.</strong> O teor desta Política de Privacidade poderá ser atualizado ou modificado a qualquer momento, conforme a finalidade da Plataforma Pegatroco ou conveniência da Pegatroco, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo ao USUÁRIO verificá-la sempre que efetuar o acesso à Plataforma.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">4.2.</strong> Ocorrendo atualizações neste documento ou nos Termos e Condições de Uso, a Pegatroco notificará o USUÁRIO mediante as ferramentas disponíveis na Plataforma e/ou os meios de contato fornecidos pelo USUÁRIO.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">4.3.</strong> Os USUÁRIOS deverão entrar em contato em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade, por meio dos canais de atendimento apontados a seguir cujo horário de funcionamento é de segunda a sexta, das 08:00 às 18:00 horas, exceto feriados nacionais:
                        </p>
                        <p className="text-dark">a) Email: atendimento@pegatroco.com.br;</p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">4.4.</strong> Caso empresas terceirizadas realizem o processamento de quaisquer dados coletados pela Plataforma Pegatroco, deverão respeitar as condições aqui estipuladas, obrigatoriamente.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            <strong className="fs-5">4.5.</strong> Caso alguma disposição desta Política de Privacidade seja considerada ilegal ou ilegítima por autoridade da localidade em que o USUÁRIO resida ou da sua conexão à internet, as demais condições permanecerão em pleno vigor e efeito.
                        </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>5. Lei aplicável e jurisdição</h4>
                        <p className="text-dark">
                            <strong className="fs-5">5.1.</strong> Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca do USUÁRIO para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                        </p>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-5">
                        <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                        <h4>6. Glossário</h4>
                        <p className="text-dark">
                            <strong className="fs-5">6.1.</strong> Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:
                        </p>
                        <br></br>
                        <p className="text-dark">
                            Cloud Computing: Ou computação em nuvem, é tecnologia de virtualização de serviços construída a partir da interligação de mais de um servidor por meio de rede de informação comum (p. ex. a Internet), com objetivo de aumentar a disponibilidade dos serviços sustentados.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            IP: Abreviatura de Internet Protocol. É um conjunto de números que identifica a conexão de acesso à Internet utilizada pelos USUÁRIOS para acessar a Plataforma Pegatroco.
                        </p>
                        <p className="text-dark">a) Logs ou registros de acesso: Registros de atividades dos USUÁRIOS efetuadas na Plataforma Pegatroco;</p>
                        <br></br>
                        <p className="text-dark">
                            Plataforma: Designa a plataforma da Pegatroco, denominada Pegatroco
                        </p>
                        <br></br>
                        <p className="text-dark">
                            Session ID: Identificação da sessão do visitante no processo de compra de produtos ou quando é efetuado o acesso à área restrita.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            Pegatroco: ICOMA E YOKOO LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o no. 17.096.642/0001-39, com sede na R. Antônio Alves, 34-27 - Jardim Aeroporto, Bauru, São Paulo.
                        </p>
                        <br></br>
                        <p className="text-dark">
                            USUÁRIO: Pessoa Física que acessa ou interage com as atividades oferecidas pela plataforma.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
  
}

export default ModalTermo;