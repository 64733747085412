const ModalTermoUso = () => {

  return (
    <div className="modal fade modalbox" id="termosUsoModal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Termos de uso</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="container col-md-7">
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                            <h4>Pegatroco</h4>
                            <p className="text-dark">
                            A seguir apresentamos a você os Termos e Condições de Uso, documento que relaciona as principais regras que devem ser observadas por todos que acessam a PLATAFORMA Pegatroco. ou utilizam suas funcionalidades.
                            </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>1. Funcionalidades da Plataforma Pegatroco.</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">1.1.</strong> Para utilizar as funcionalidades da PLATAFORMA Pegatroco, o USUÁRIO deverá criar uma Conta de Acesso e ser maior de 18 (dezoito) anos e juridicamente capaz.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">1.1.1.</strong> A Pegatroco poderá recusar ou não aprovar solicitações de cadastros realizadas por USUÁRIOS que não fornecerem dados corretos ou não enviarem os documentos solicitados.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">1.2.</strong> A Pegatroco poderá alterar os critérios de elegibilidade de acesso e utilização da PLATAFORMA Pegatroco a qualquer momento, sem que para isso tenha de fazer qualquer tipo de comunicação ou aviso prévio aos USUÁRIOS.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">1.3.</strong> A Pegatroco possui a faculdade de se recusar a conceder ou cancelar a Conta de Acesso de qualquer USUÁRIO que a utilizar de forma fraudulenta, violar ou tentar violar os presentes Termos e Condições de Uso, a Política de Privacidade, outras políticas ou qualquer documento legal na PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">1.4.</strong> Para criar uma Conta de Acesso na PLATAFORMA Pegatroco é necessário que o USUÁRIO envie seus dados pessoais.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">1.5.</strong> A PLATAFORMA Pegatroco permite realizar simulações de crédito, de acordo com o perfil do USUÁRIO.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">1.5.1</strong> Para contratar um produto ou serviço bancário disponibilizado na PLATAFORMA Pegatroco, o USUÁRIO deverá firmar contrato específico com a Instituição Financeira responsável pelo produto/serviço de interesse, que estará disponível na própria PLATAFORMA Pegatroco.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>2. Responsabilidades e Obrigações dos Usuários</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.</strong> Os USUÁRIOS são responsáveis e se obrigam a:
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.1</strong> Utilizar com retidão e de forma ética a PLATAFORMA Pegatroco, respeitando as condições que regem a sua utilização e finalidade.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.2</strong> Fornecer dados cadastrais corretos, completos e atualizados, além de informar canal de contato apto a ser acionado pela Pegatroco para o melhor cumprimento dos serviços. Se a qualquer momento for constatado que o USUÁRIO forneceu dados falsos ou não condizentes com a realidade, a Pegatroco se reserva ao direito de suspender ou cancelar sua Conta de Acesso, sem prejuízo de adotar as medidas que entender cabíveis.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.3</strong> Manter o sigilo dos dados de sua Conta de Acesso em relação a terceiros e utilizá-la de modo individual e intransferível, não disponibilizando, divulgando ou compartilhando sua senha ou qualquer mecanismo de autenticação com quem quer que seja. Caso suspeite que a confidencialidade de sua senha foi violada, o USUÁRIO deverá proceder sua troca ou atualização o mais rápido possível ou contar os canais de atendimento disponíveis.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.4</strong> Adotar senha de acesso, forte com no mínimo 6 e no máximo 8 dígitos, sem sequências obvias de fácil dedução. Em adição, o USUÁRIO não deve reutilizar sua senha em aplicativos, portais e sites de terceiros.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.5</strong> Adotar medidas em seus dispositivos tecnológicos para evitar o acesso físico e lógico por terceiros não autorizados, tais como utilização de senha e/ou biometria.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.6</strong> Reconhecer que todos os acessos e operações realizadas após sua autenticação digital bem-sucedida são interpretados como sendo de sua responsabilidade de forma incontestável, inclusive aqueles derivados de uso indevido ou divulgação desta para terceiros.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.7</strong> Deixar seus sistemas de anti-spam, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais da PLATAFORMA Pegatroco, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados. A Pegatroco não envia e-mails, SMS ou outros tipos de comunicação com links que não sejam do domínio pegatroco.com.br, desta forma, caso o USUÁRIO receba alguma mensagem desse tipo, deve estar ciente dos riscos ao clicar no link, pois pode ser tentativa de fraude conhecida como phishing.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.8</strong> Respeitar todos os direitos de propriedade intelectual de titularidade da Pegatroco, incluindo todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma ali disponíveis. Da mesma forma, os USUÁRIOS somente poderão reproduzir os conteúdos disponíveis na PLATAFORMA Pegatroco, em especial suas marcas e layout do ambiente, caso tenham sido expressamente autorizados pela Pegatroco neste sentido.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.9</strong> Não acessar áreas de programação da PLATAFORMA Pegatroco, seu banco de dados, códigos fonte ou qualquer outro conjunto de dados disponíveis nestes ambientes.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.10</strong> Não realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor inapropriadamente das funcionalidades da PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.1.11</strong> Não utilizar softwares spider ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado que atue de modo similar.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.2.</strong> As funcionalidades que compõem a PLATAFORMA Pegatroco. são oferecidas na forma de prestação de serviço, não conferindo ao USUÁRIO nenhum direito sobre o software utilizado pela Pegatroco ou sobre suas estruturas de informática que sustentam a PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.3.</strong> A eventual remoção, bloqueio ou suspensão de qualquer conteúdo ou funcionalidade da PLATAFORMA Pegatroco em decorrência de alguma reclamação, deverá ser sempre compreendida como demonstração de boa-fé e intenção de solução amigável de conflitos, jamais, como reconhecimento de culpa ou de qualquer infração pela Pegatroco a direito de terceiro.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.4.</strong> Na incidência de danos à Pegatroco ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, inclusive aqueles cuja origem for de atos praticados por meio de sua Conta de Acesso, assumindo o polo passivo de ação judicial ou procedimento administrativo e requerendo a exclusão da Pegatroco, devendo arcar totalmente com as despesas e custas processuais atinentes, deixando-a livre de prejuízos e ônus.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">2.4.1</strong> O descumprimento de quaisquer das obrigações aqui estipuladas poderá acarretar na suspensão total ou parcial das funcionalidades, ou exclusão da Conta de Acesso, sem aviso prévio, conforme previsto neste documento.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>3. Isenção e Limitações de Responsabilidade</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">3.1.</strong> Os dados coletados estarão armazenados em ambiente seguro e controlado. A Pegatroco se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de acessos não autorizados ocasionados por falhas, vírus ou quaisquer outras técnicas de invasão do banco de dados da Plataforma Pegatroco, salvo nos casos em que tiver dolo ou culpa, observado o estado da técnica disponível, uma vez que nenhum sistema de segurança pode ser considerado inviolável.
                                </p>
                                <p className="text-dark">a) preservar a sua funcionalidade, com links não quebrados e utilizando layout que respeita a usabilidade e navegabilidade, sempre que possível;</p>
                                <br></br>
                                <p className="text-dark">b) exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas;</p>
                                <br></br>
                                <p className="text-dark">c) proteger, por meio do estado da técnica disponível, os dados coletados pelas funcionalidades disponibilizadas.</p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.2.</strong> A Pegatroco envida seus esforços para a manutenção da disponibilidade contínua e permanente da PLATAFORMA Pegatroco que, no entanto, pode ocorrer, eventualmente, alguma indisponibilidade temporária decorrente de manutenção necessária ou mesmo gerada por motivo de força maior, como desastres naturais, falhas nos sistemas de comunicação e acesso à Internet ou fatos de terceiro que fogem de sua esfera de vigilância e responsabilidade.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.2.1.</strong> Se isso ocorrer, a Pegatroco fará o que estiver ao seu alcance para restabelecer o acesso à PLATAFORMA Pegatroco o mais breve possível, dentro das limitações técnicas de seus serviços e serviços de terceiros, dos quais depende para ficar online.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.2.2.</strong> Eventuais procedimentos de manutenção que acarretem a indisponibilidade da PLATAFORMA Pegatroco por longos períodos serão informados por meio dos canais oficiais de comunicação, como e-mails, perfis oficiais em mídias sociais ou telefone de atendimento.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.3.</strong> O USUÁRIO não possui qualquer direito para exigir a disponibilidade da PLATAFORMA Pegatroco tampouco poderá pleitear indenização ou reparação de danos em caso da PLATAFORMA Pegatroco permanecer fora do ar, independente da motivação.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.4.</strong> A Pegatroco não se responsabiliza:
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.4.1.</strong> Por quaisquer problemas, bugs, glitches ou funcionamentos indevidos que ocorrerem nos dispositivos e equipamentos dos USUÁRIOS e sejam resultantes direta ou indiretamente do uso regular da PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.4.2</strong> Por qualquer dano direto ou indireto ocasionado por eventos de terceiros, a exemplo, mas não se limitando a ataque de hackers, falhas no sistema, no servidor ou na conexão à internet, inclusive por ações de softwares que possam, de algum modo, danificar ativos físicos ou lógicos ou a conexão dos USUÁRIOS em decorrência do acesso, utilização ou navegação na PLATAFORMA PEGATROCO, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos neste.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.4.3</strong> Pela navegação dos USUÁRIOS nos links externos disponibilizados na PLATAFORMA Pegatroco, sendo seus deveres a leitura dos Termos e Condições de Uso e Política de Privacidade do recurso acessado e agir conforme o determinado.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">3.4.4</strong> Por verificar, controlar, aprovar ou garantir a adequação ou exatidão das informações ou dados disponibilizados em tais links, não sendo, portanto, responsáveis por prejuízos, perdas ou danos ocorridos pela visita de tais sites, cabendo ao interessado verificar a confiabilidade das informações e dados ali exibidos antes de tomar alguma decisão ou praticar algum ato.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>4. Privacidade dos Usuários</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">4.1.</strong> A Pegatroco possui documento próprio, denominado Política de Privacidade, que regula o tratamento dos dados coletados na PLATAFORMA Pegatroco, sendo parte integrante e inseparável dos presentes Termos e Condições de Uso e pode ser acessada pelo link encontrado em seu rodapé.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">4.2.</strong> Caso alguma disposição da Política de Privacidade conflite com qualquer outra do presente documento, deverá prevalecer o descrito na norma mais específica.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>5. Disposições Gerais</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">5.1.</strong> Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca do USUÁRIO para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                                </p>
                                <p className="text-dark">
                                    a) E-mail: atendimento@pegatroco.com.br
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.2.</strong> Os USUÁRIOS poderão se valer dos canais de atendimento toda vez que presenciarem ou verificarem conteúdo impróprio na PLATAFORMA Pegatroco, seja ele notadamente ilícito ou contrário às regras de uso aqui estipuladas.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.3.</strong> Os presentes Termos e Condições de Uso estão sujeitos a constante melhoria e aprimoramento. Assim, a Pegatroco se reserva o direito de modificá-los a qualquer momento, conforme sua finalidade ou conveniência, tal qual para adequação e conformidade legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo aos USUÁRIOS verificá-lo sempre que efetuar o acesso na PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.3.1</strong> Ocorrendo atualizações neste documento ou na Política de Privacidade, a Pegatroco notificará o USUÁRIO mediante as ferramentas disponíveis na PLATAFORMA Pegatroco ou pelos meios de contato fornecidos pelo USUÁRIO.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.4.</strong> Ao navegar pela PLATAFORMA Pegatroco e utilizar suas funcionalidades, o USUÁRIO aceita guiar-se pelos Termos e Condições de Uso e pela Política de Privacidade que se encontram vigentes na data de acesso. Por isso, é recomendável que o USUÁRIO se mantenha atualizado.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.5.</strong> A tolerância do eventual descumprimento de quaisquer das cláusulas e condições do presente instrumento não constituirá novação das obrigações aqui estipuladas e tampouco impedirá ou inibirá a exigibilidade das mesmas a qualquer tempo.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.6.</strong> Caso alguma disposição destes Termos e Condições de Uso ou da Política de Privacidade publicadas na PLATAFORMA Pegatroco for julgada inaplicável ou sem efeito, o restante de ambos os documentos continua a viger, sem a necessidade de medida judicial que declare tal assertiva.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.7.</strong> O USUÁRIO reconhece que toda comunicação realizada por e-mail (ao endereço de e-mail informado no seu cadastro) é válida, eficaz e suficiente para a divulgação de qualquer assunto que se refira aos serviços objeto deste contrato, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado, ressalvadas as disposições expressamente diversas previstas nestes Termos e Condições de Uso.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    <strong className="fs-5">5.7.</strong> A PLATAFORMA Pegatroco tem como base a data e horários oficiais de Brasília.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>6. Lei Aplicável e Jurisdição</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">6.1.</strong> Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca do USUÁRIO para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center mt-5">
                            <div className="col-md-12 col-lg md-margin-30 align-self-center text-left">
                                <h4>7. Glossário</h4>
                                <p className="text-dark">
                                    <strong className="fs-5">7.1.</strong> Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    Anti-Spam: Sistema que evita correspondências não desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as movendo para pasta específica.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    Conta de Acesso: Credencial de um USUÁRIO cadastrado que permite o acesso às funcionalidades exclusivas da PLATAFORMA PEGATROCO, que é definida pelo nome de usuário e senha.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    Crawler/Spider: Programas desenvolvidos para atuar ou obter informações de modo automatizado em sites.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    Layout: Conjunto compreendido entre aparência, design e fluxos da PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    Link: Terminologia para endereço de internet
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    Pegatroco: B2K SOLUÇÕES LTDA, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 17.096.642/0001-39, com sede na R. Antônio Alves, 34-27 - Jardim Aeroporto, Bauru, São Paulo.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    PLATAFORMA: Designa a PLATAFORMA da Pegatroco, denominada Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    USUÁRIO: Pessoa Física que acessa ou interage com as atividades oferecidas pela PLATAFORMA Pegatroco.
                                </p>
                                <br></br>
                                <p className="text-dark">
                                    TERMO DE AUTORIZAÇÃO PARA CONSULTA DE MARGEM CONSIGNÁVEL EM FOLHA DE PAGAMENTO PEGATROCO
                                </p>
                                <p className="text-dark">
                                    Pelo presente, autorizo a Pegatroco, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 17.096.642/0001-39, com sede na R. Antônio Alves, 34-27 - Jardim Aeroporto, Bauru, São Paulo. a consultar a margem consignável de minha folha de pagamento, no intuito de indicar produtos bancários relacionados à empréstimos e financiamentos pessoais.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
  
}

export default ModalTermoUso;