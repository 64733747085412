const MenuFooter = () => {

    return (
        <div className="appBottomMenu" id="menu-footer">
            <a href="/" className="item">
                <div className="col">
                    <i className="fas fa-home fs-4"></i>
                    <strong>Home</strong>
                </div>
            </a>
            <a href="/contratos" className="item">
                <div className="col">
                    <i className="fas fa-file-alt fs-4"></i>
                    <strong>Contratos</strong>
                </div>
            </a>
            <a href="/duvidas" className="item">
                <div className="col">
                    <i className="fas fa-question fs-4"></i>
                    <strong>Dúvidas</strong>
                </div>
            </a>
            <a href="/conta" className="item">
                <div className="col">
                    <i className="fas fa-user-circle fs-4"></i>
                    <strong>Conta</strong>
                </div>
            </a>
            <a href="/configuracoes" className="item">
                <div className="col">
                    <i className="fas fa-cog fs-4"></i>
                    <strong>Configurações</strong>
                </div>
            </a>
        </div>
    );

}

export default MenuFooter;