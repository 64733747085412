export const OrgaoEmissor = () => {

    const orgaos = [
        {
            "value": "SSP",
            "label": "SSP"
        },
        {
            "value": "ABNC",
            "label": "ABNC"
        },
        {
            "value": "CGPI",
            "label": "CGPI"
        },
        {
            "value": "CGPMAF",
            "label": "CGPMAF"
        },
        {
            "value": "CNIG",
            "label": "CNIG"
        },
        {
            "value": "CNT",
            "label": "CNT"
        },
        {
            "value": "CORECON",
            "label": "CORECON"
        },
        {
            "value": "COREN",
            "label": "COREN"
        },
        {
            "value": "CRA",
            "label": "CRA"
        },
        {
            "value": "CRAS",
            "label": "CRAS"
        },
        {
            "value": "CRB",
            "label": "CRB"
        },
        {
            "value": "CRC",
            "label": "CRC"
        },
        {
            "value": "CRE",
            "label": "CRE"
        },
        {
            "value": "CREA",
            "label": "CREA"
        },
        {
            "value": "CRECI",
            "label": "CRECI"
        },
        {
            "value": "CREFIT",
            "label": "CREFIT"
        },
        {
            "value": "CRESS",
            "label": "CRESS"
        },
        {
            "value": "CRF",
            "label": "CRF"
        },
        {
            "value": "CRM",
            "label": "CRM"
        },
        {
            "value": "CRMV",
            "label": "CRMV"
        },
        {
            "value": "CRN",
            "label": "CRN"
        },
        {
            "value": "CRO",
            "label": "CRO"
        },
        {
            "value": "CRP",
            "label": "CRP"
        },
        {
            "value": "CRPRE",
            "label": "CRPRE"
        },
        {
            "value": "CRQ",
            "label": "CRQ"
        },
        {
            "value": "CRRC",
            "label": "CRRC"
        },
        {
            "value": "CSC",
            "label": "CSC"
        },
        {
            "value": "CTPS",
            "label": "CTPS"
        },
        {
            "value": "DETRAN",
            "label": "DETRAN"
        },
        {
            "value": "DIC",
            "label": "DIC"
        },
        {
            "value": "DIREX",
            "label": "DIREX"
        },
        {
            "value": "DPF",
            "label": "DPF"
        },
        {
            "value": "DPMAF",
            "label": "DPMAF"
        },
        {
            "value": "DPT",
            "label": "DPT"
        },
        {
            "value": "DST",
            "label": "DST"
        },
        {
            "value": "FGTS",
            "label": "FGTS"
        },
        {
            "value": "FIPE",
            "label": "FIPE"
        },
        {
            "value": "FLS",
            "label": "FLS"
        },
        {
            "value": "GOVGO",
            "label": "GOVGO"
        },
        {
            "value": "IFP",
            "label": "IFP"
        },
        {
            "value": "IGP",
            "label": "IGP"
        },
        {
            "value": "IICCECF/RO",
            "label": "IICCECF/RO"
        },
        {
            "value": "IIMG",
            "label": "IIMG"
        },
        {
            "value": "IML",
            "label": "IML"
        },
        {
            "value": "IPC",
            "label": "IPC"
        },
        {
            "value": "IPF",
            "label": "IPF"
        },
        {
            "value": "MAE",
            "label": "MAE"
        },
        {
            "value": "MEX",
            "label": "MEX"
        },
        {
            "value": "MMA",
            "label": "MMA"
        },
        {
            "value": "OAB",
            "label": "OAB"
        },
        {
            "value": "OMB",
            "label": "OMB"
        },
        {
            "value": "PCMG",
            "label": "PCMG"
        },
        {
            "value": "PMMG",
            "label": "PMMG"
        },
        {
            "value": "POF",
            "label": "POF"
        },
        {
            "value": "POM",
            "label": "POM"
        },
        {
            "value": "SDS",
            "label": "SDS"
        },
        {
            "value": "SECC",
            "label": "SECC"
        },
        {
            "value": "SEJUSP",
            "label": "SEJUSP"
        },
        {
            "value": "SES",
            "label": "SES"
        },
        {
            "value": "SESP",
            "label": "SESP"
        },
        {
            "value": "SJS",
            "label": "SJS"
        },
        {
            "value": "SJTC",
            "label": "SJTC"
        },
        {
            "value": "SJTS",
            "label": "SJTS"
        },
        {
            "value": "SNJ",
            "label": "SNJ"
        },
        {
            "value": "SPTC",
            "label": "SPTC"
        }

    ]
    return orgaos;
}
