import Swal from 'sweetalert2';

export const swalNotificacaoInicial = () => {

  Swal.fire({
    // icon: 'info',
    // title: `😀 ${primeiroNome}`,
    html: '<center><p align="left">Para prosseguir com a sua contratação, precisamos que tenha em mãos seus documentos.</p><p align="left" style="color:blue"><strong>Preencha os campos de forma completa e sem abreviações</strong><br></p></center>',
    confirmButtonColor: '#28a745',
    confirmButtonText: `OK`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
  });

}