import axios from "axios";

import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactPixel from 'react-facebook-pixel';

import Logo from '../../assets/img/pegatroco.png';

import { auth, firestore } from '../../services/firebase';

// Tools
import { cpfMask } from '../../tools/CpfMask';
import { CelularMask } from '../../tools/CelularMask';
import { DataMask } from '../../tools/DataMask';
import { LetraMask } from '../../tools/LetraMask';
import { ValidacaoCpf } from '../../tools/ValidacaoCpf';
import { VerificaNome } from '../../tools/VerificaNome';

import LodingIcon from '../../assets/img/loading-icon.png';
import { BiArrowBack } from "react-icons/bi";

// Components
import Swal from 'sweetalert2';
import ModalTermo from '../../components/modal-termo';

// Helper
import enviaCodigoWhats from '../../helper/enviacodigocelular';
import verificaCodigoWhats from '../../helper/verificacodigocelular';
import Autenticacao from "../../helper/autenticacao/autenticar";

const CompletarCadastro = () => {

  const [cadastro, setCadastro] = useState(1);
  const [loading, setLoading] = useState(false);
  const [perfil, setPerfil] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [codigo, setCodigo] = useState('');
  const [tentativa, setTentativa] = useState(null);
  const [avisoTelefone, setAvisoTelefone] = useState(false);
  const [validandoCodigo, setValidandoCodigo] = useState(false);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');
  const [cadastroSocial, setCadastroSocial] = useState(false);

  //Url Campanha
  const url_atual = window.location.search;
  const urlParams = new URLSearchParams(url_atual);
  //Utm Source
  const traffic_source = urlParams.get('utm_source');
  //Traffic Medium
  const traffic_medium = urlParams.get('utm_medium');
  //Traffic Campaign
  const traffic_campaign = urlParams.get('utm_campaign');
  //Traffic Value
  const traffic_value = urlParams.get('traffic_value');
  //Traffic Term
  const traffic_term = urlParams.get('utm_term');
  //Código Indicador
  const codigo_indicador = urlParams.get('code_user');

  const [searchParams] = useSearchParams();
  const next_page = searchParams.get('next') ? searchParams.get('next') : null;

  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // Steper Cadastro
  async function steperCadastro(values = {}) {


    // Telefone
    if (telefone === '') {
      Toast.fire({
        icon: 'warning',
        title: 'Preencha com o seu telefone!'
      });
    }
    else if (telefone.length < 15) {

      Toast.fire({
        icon: 'warning',
        title: 'Preencha seu Telefone Completo!'
      });

      setAvisoTelefone(true)

    }
    else {
      setAvisoTelefone(false)
      Swal.fire({
        icon: `info`,
        html: `<p class="fs-4 text-dark">O número <span class="fw-bold">${telefone}</span> está correto?</p>`,
        showDenyButton: true,
        confirmButtonText: 'Está Correto',
        denyButtonText: `Não Está Correto`,
        confirmButtonColor: `#11d962`,
        allowOutsideClick: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          enviaCodigo();
        } else if (result.isDenied) {
          Swal.close();
          document.getElementById('celular').focus();
        }
      })
    }



  }

  // Cadastro cliente CRM
  async function CadastroCrm(values) {

    // Inicia Loading
    setLoading(true);

    // Obj rd station
    const rd_station = {};
    // Adição de chave ao obj
    traffic_source ? rd_station.traffic_source = traffic_source : null;
    traffic_medium ? rd_station.traffic_medium = traffic_medium : null;
    traffic_campaign ? rd_station.traffic_campaign = traffic_campaign : null;
    traffic_value ? rd_station.traffic_value  = traffic_value : null;
    traffic_term ? rd_station.traffic_term = traffic_term : null;

    const obj_cadastro = {
      'nome': values.nome,
      'email': values.email,
      'telefone': values.telefone,
      'cpf': values.cpf,
      'data_nascimento': values.data_nascimento,
      'tipo_simulacao': values.tipo_simulacao,
      'uuid_google': values.uuid_google,
      'rd_station':rd_station,
      'cadastro':true
    };

    var config = {
      method: 'POST',
      url: process.env.REACT_APP_URL_API + `/cliente/cadastro`,
      data: obj_cadastro
    };

    const cliente = await axios(config)
      .then(function (response) {

        // Para Loading
        setLoading(false);

        // Sucesso
        if (response.data && response.data.cadastro) {
          return {
            'httpcode': 200,
            'cadastro':true
          };
        }
        // Blacklist
        else if (response.data && response.data.blacklist) {
          return { 
            'httpcode': 400,
            'cadastro':false,
            'blacklist':true
          };
        }
        // Não Autorizado (Generico)
        else {
          return { 
            'httpcode': 400,
            'cadastro':false
          };
        }

      })
      .catch(function (error) {

        // Para Loading
        setLoading(false);

        return { 
          'httpcode': 500,
          'cadastro':false 
        };
      });

    return cliente;
  }

  // Atualizar o cadastro CRM
  async function AtualizarUsuario(event) {

    event.preventDefault();

    const usuario = firestore.collection('usuario');

    const dadosUsuario = sessionStorage.getItem('obj_client') ? JSON.parse(sessionStorage.getItem('obj_client')) : null;

    //TOOL PARA VERIFICAR SE NOME ESTÁ COMPLETO
    const verifica_nome = VerificaNome(nome);

    // Data de nascimento não preenchida
    if (dataNascimento.trim() === '') {
      Toast.fire({
        icon: 'warning',
        title: 'Preencha a Data de nascimento!'
      });
    }
    // Nome
    else if (nome === '' || !verifica_nome) {
      Toast.fire({
        icon: 'warning',
        title: 'Preencha o seu nome completo!'
      });
    }
    //CPF
    else if (cadastroSocial && !cpf) {
      Toast.fire({
        icon: 'warning',
        title: 'Informe seu CPF!'
      });
    }
    //Perfil
    else if (cadastroSocial && !perfil) {
      Toast.fire({
        icon: 'warning',
        title: 'Informe seu Perfil!'
      });
    }
    // Cadastro
    else {
      if (dadosUsuario != null) {

        await usuario.doc(dadosUsuario.uid).set(
          {
            email: dadosUsuario.email,
            nome: nome,
            telefone: telefone,
            cpf: cpf
          }
        );

        //Cadastro cliente CRM
        const obj_crm = {
          'nome': nome,
          'email': dadosUsuario.email,
          'telefone': telefone,
          'cpf': cpf,
          'data_nascimento': dataNascimento,
          'tipo_simulacao': perfil,
          'uuid_google': dadosUsuario.uid
        };

        // Cadastro CRM
        const cadastroCrm = await CadastroCrm(obj_crm);

        // Cadastro realizado
        if (cadastroCrm.cadastro) {

          // Autenticação
          const autenticacao = await Autenticacao({uuid:dadosUsuario.uid,email: dadosUsuario.email});

          // Retorno do token
          if(autenticacao && autenticacao.token){

            // Regitra nome/identify no Session Storage (Autenticação)
            sessionStorage.setItem('identify', autenticacao.token);
            sessionStorage.setItem('nome', nome);

            const autenticacaoTipo = dadosUsuario.autenticacao;

            // Cadastro via Gmail
            if (autenticacaoTipo === "gmail") {
              // Track cadastro gmail
              ReactPixel.trackCustom('CadastrouGoogle');

              window.gtag('event', 'conversion', { 'send_to': 'AW-11012337992/5mHgCIfruYAYEMjii4Mp' });
            }
            // Cadastro por e-mail/senha
            else {
              // Track cadastro email/senha
              ReactPixel.trackCustom('Cadastrou');

              window.gtag('event', 'conversion', { 'send_to': 'AW-11012337992/oVH8CITruYAYEMjii4Mp' });
            }

            Swal.fire({
              icon: 'success',
              title: `Cadastro realizado!`,
              text: 'Seu cadastro foi finalizado com sucesso, agora você consegue acessar o App.',
              confirmButtonColor: '#1741C4',
              confirmButtonText: `Acessar`,
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                next_page ? window.location = next_page : window.location = '/';
              }
            });

          }
          // Não teve retorno do token
          else {
            Swal.fire({
              icon: 'warning',
              text: 'Não conseguimos realizar sua autenticação, clique no botão OK e vamos te direcionar para a página de login.',
              confirmButtonColor: '#1DCC70',
              confirmButtonText: `Ok`,
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location = `/${url_atual}`;
              }
            });
          }

        }
        // Cadastro não realizado
        else if (!cadastroCrm.cadastro) {
          Swal.fire({
            icon: 'warning',
            text: 'Já existe um usuário cadastrado, verifique as informações e tente novamente.',
            confirmButtonColor: '#1DCC70',
            confirmButtonText: `Ok`,
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = `/${url_atual}`;
            }
          });
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });
        }


      }
      // Erro generico
      else {
        Toast.fire({
          icon: 'warning',
          title: 'Ops, tivemos um problema!'
        });

        window.location = `/login/${url_atual}`;
      }

    }

  }

  // Enviar o Código Verificação Whatsapp
  async function enviaCodigo() {

    // Inicia Loading
    setLoading(true)

    // Envio do código
    const {enviado} = await enviaCodigoWhats(telefone)

    if (enviado) {
      // Parar Loading
      setLoading(false);

      Toast.fire({
        icon: 'success',
        title: 'Código Enviado!'
      });

      setCadastro(3);

    }
    else {
      setLoading(false)
      Swal.fire({
        icon: `info`,
        text: `Tivemos um problema ao enviar o código no telefone informado, confira seu telefone e tente novamente!`,
        confirmButtonText: 'Tentar novamente',
        confirmButtonColor: `#11d962`,
        allowOutsideClick: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.close();
        }
      })

    }

  }

  //Validação Código 
  async function verificaCodigo() {

    setValidandoCodigo(true)

    const data = {
      'telefone': telefone,
      'codigo': codigo,
    }

    const {valido} = await verificaCodigoWhats(data);

    // Código válido
    if (valido) {
      setValidandoCodigo(false)
      Toast.fire({
        icon: 'success',
        title: 'Código Validado com Sucesso!'
      });
      setCadastro(4)
    }
    // Código inválido
    else {
      setValidandoCodigo(false)

      Toast.fire({
        icon: 'warning',
        title: 'Código Inválido!'
      });

      if (tentativa) {
        setTentativa(tentativa - 1)
      }
      else {
        setTentativa(3)
      }
    }

  }

  //Nova tentativa de código
  async function tentarCodigoNovamente() {

    setTentativa(null)
    setCadastro(2)
  }

  //FUNÇÃO PARA CRIAR USUÁRIO NO FIREBASE
  async function CriacaoUsuario() {

    if (senha === confirmacaoSenha) {

      await auth.createUserWithEmailAndPassword(email, senha)
        .then((result) => {
          setCadastro(2)
          const { displayName, uid, email } = result.user;

          ConsultaUsuario({ 'uid': uid, 'nome': displayName, 'email': email });

        })
        .catch((error) => {

          if (error && error.code && error.code === "auth/email-already-in-use") {
            Toast.fire({
              icon: 'warning',
              title: 'Já existe um cadastro com esse E-mail!'
            });
          }
          else {
            Toast.fire({
              icon: 'warning',
              title: 'Ops, tivemos um problema!'
            });

            window.location = '/login' + url_atual;
          }


        });
    }
    else {
      Toast.fire({
        icon: 'warning',
        title: 'As senhas são diferentes!'
      });
    }


  }

  // Consulta Usuário Firebase
  async function ConsultaUsuario(values) {

    const usuario = firestore.collection('usuario').doc(values.uid);

    const getUsuario = await usuario.get()

    if (!getUsuario) {
      await CadastroUsuario(values);
    }
    else {

      if (getUsuario.data()) {
        const nome = getUsuario.data().nome ? getUsuario.data().nome : null;
        const email = getUsuario.data().email ? getUsuario.data().email : null;
        const cpf = getUsuario.data().cpf ? getUsuario.data().cpf : null;
        const telefone = getUsuario.data().telefone ? getUsuario.data().telefone : null;

        // Dashboard
        if (nome != null && email != null && cpf != null && telefone != null) {
          window.location = "/";
        }
        // Completar Cadastro
        else {

          // Session Storage
          const obj_client = {
            'nome': values.nome,
            'email': values.email,
            'uid': values.uid
          }

          sessionStorage.setItem('obj_client', JSON.stringify(obj_client));

          window.location = "/completar-cadastro" + url_atual;
        }
      }
      // Cadastro Usuario
      else {

        await CadastroUsuario(values);

      }


    }

  }

  // Cadastro Usuário Firebase
  async function CadastroUsuario(values) {

    const usuario = firestore.collection('usuario');

    await usuario.doc(values.uid).set(
      {
        email: values.email,
        nome: values.nome
      }
    );

    // Session Storage
    const obj_client = {
      'nome': values.nome,
      'email': values.email,
      'uid': values.uid,
      'autenticacao': 'email'
    }

    sessionStorage.setItem('obj_client', JSON.stringify(obj_client));

  }


  useEffect(() => {

    const cadastro_cliente = sessionStorage.getItem('obj_client');

    if (cadastro === 1 && cadastro_cliente) {

      setCadastro(2)

      setCadastroSocial(true)
    }
    else {
      var cpf = sessionStorage.getItem('cpf') ? sessionStorage.getItem('cpf') : null;
      setCpf(cpf ? cpf : '')

      var perfil = sessionStorage.getItem('perfil') ? sessionStorage.getItem('perfil') : null;
      setPerfil(perfil ? perfil : '')
    }

  }, [cadastro])

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <div id="appCapsule">
              <HelmetProvider>
                <Helmet>
                  <title>Pegatroco | Finalização do Cadastro</title>
                </Helmet>
              </HelmetProvider>
              <div className="section mt-2 text-center">
                <img src={Logo} alt="Logo" className="img-fluid mb-2" width="500"></img>
                <h3 className="mt-5">Para descobrir os valores disponíveis complete o cadastro!</h3>
              </div>
              <div className="section mb-5 p-2">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-5 align-self-center">
                    <div className="card">
                      <div className="card-body pb-1">

                        <React.Fragment>

                          {(cadastro === 1 ?
                            (<>
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="email1">E-mail</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    id="email1"
                                    placeholder="Seu e-mail"
                                    required
                                    onChange={event => setEmail(event.target.value)}
                                    value={email}
                                  />
                                </div>
                              </div>

                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="password1">Senha</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password1"
                                    autoComplete="off"
                                    placeholder="Sua senha"
                                    required
                                    onChange={event => setSenha(event.target.value)}
                                    value={senha}
                                  />
                                </div>
                              </div>

                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="password2">Confirmação da senha</label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    id="password2"
                                    autoComplete="off"
                                    placeholder="Confirme a senha"
                                    required
                                    onChange={event => setConfirmacaoSenha(event.target.value)}
                                    value={confirmacaoSenha}
                                  />
                                </div>
                              </div>

                              <button
                                type="button"
                                className="btn btn-primary btn-block btn-lg mt-5"
                                onClick={() => CriacaoUsuario()}>
                                Próximo
                              </button>
                            </>) :
                            cadastro === 2 ? (
                              <>
                                <div className="form-group basic">

                                  <div className="input-wrapper">
                                    <label className="label" htmlFor="celular">Número do WhatApp</label>
                                    <input
                                      type="tel"
                                      className="form-control"
                                      id="celular"
                                      placeholder="Seu celular (WhatsApp)"
                                      onChange={event => setTelefone(CelularMask(event.target.value))}
                                      value={telefone}
                                      maxLength={15}
                                      required
                                    />
                                    <p className="mt-3 text-dark text-justify">
                                      O WhatsApp é nosso principal canal de comunicação, por isso precisamos que esteja correto.
                                    </p>

                                    {avisoTelefone ?
                                      <><p className="mt-3 text-justify text-danger">Não se esqueça de adicionar o 9 no ínicio do número!</p></> : ''}
                                  </div>

                                </div>

                                <button className="btn btn-primary btn-block btn-lg mt-5"
                                  onClick={() => steperCadastro({ 'step': 3 })}
                                >
                                  Verificar meu celular
                                </button>
                              </>
                            ) :
                              cadastro == 3 ?
                                (<>

                                  {tentativa == 0 ?
                                    <>
                                      <a href="#"
                                        onClick={() => { tentarCodigoNovamente() }}>
                                        < BiArrowBack
                                          size={30}
                                          className="text-dark" />
                                      </a>
                                    </>
                                    : <></>}

                                  <div className={tentativa == 0 ? `form-group basic` : `form-group basic mb-5`}>

                                    <p className="text-dark">
                                      Enviamos um código de 6 dígitos em seu Whatsapp, digite o código no campo abaixo para continuar seu cadastro.
                                    </p>
                                    <input type="text"
                                      className="form-control verification-input"
                                      id="smscode"
                                      placeholder="••••••"
                                      maxLength="6"
                                      value={codigo}
                                      onChange={(event) => setCodigo(event.target.value)} />
                                  </div>

                                  {tentativa == 0 ?
                                    <><p className="text-dark">Você pode <a href="#" onClick={() => { tentarCodigoNovamente() }}>alterar seu número</a> ou <a href="#" onClick={() => { tentarCodigoNovamente() }}>solicitar um novo código</a> para continuar.</p></>
                                    : <></>}
                                  {tentativa ?
                                    <><p>Você tem {tentativa} tentativa(s) restante(s).</p></>
                                    : tentativa == 0 ?
                                      <><p><a href="https://api.whatsapp.com/send?phone=551433138461">Contate nosso suporte</a>.</p></>
                                      : <></>}

                                  <button
                                    type="submit"
                                    className="btn btn-primary btn-block btn-lg"
                                    onClick={() => {
                                      verificaCodigo()
                                    }}
                                    disabled={tentativa == 0 ? true : validandoCodigo ? true : false}>
                                    {validandoCodigo ? 'Validando...' : 'Validar Código'}
                                  </button>

                                </>)
                                :
                                (
                                  <>
                                    {perfil == 'representante-legal' ?

                                      <form method="POST" onSubmit={AtualizarUsuario}>

                                        <p className="fw-bold text-center text-dark">*As informações fornecidas devem ser do beneficiário*</p>

                                        <div className="form-group basic">
                                          <div className="input-wrapper">
                                            <label className="label" htmlFor="nome">Nome do beneficiário</label>
                                            <input
                                              type="tel"
                                              className="form-control"
                                              id="nome"
                                              autoComplete="off"
                                              placeholder="Qual o nome do beneficiáiro"
                                              onChange={event => setNome(LetraMask(event.target.value))}
                                              value={nome}
                                            />
                                          </div>
                                        </div>
                                        <div className="form-group basic">
                                          <div className="input-wrapper">
                                            <label className="label" htmlFor="dt-nasicmento">Data de nascimento do beneficiário</label>
                                            <input
                                              type="tel"
                                              className="form-control"
                                              id="dt-nasicmento"
                                              placeholder="Data de aniversário do beneficiário"
                                              onChange={event => setDataNascimento(DataMask(event.target.value))}
                                              value={dataNascimento}
                                            />
                                          </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block btn-lg mt-5">
                                          Finalizar Cadastro
                                        </button>
                                      </form>

                                      :

                                      <form method="POST" onSubmit={AtualizarUsuario}>

                                        <div className="form-group basic">
                                          <div className="input-wrapper">
                                            <label className="label" htmlFor="nome">Nome completo</label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="nome"
                                              autoComplete="off"
                                              placeholder="Seu nome completo"
                                              required
                                              onChange={event => setNome(LetraMask(event.target.value))}
                                              value={nome}
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group basic">
                                          <div className="input-wrapper">
                                            <label className="label" htmlFor="celular">Data de nascimento</label>
                                            <input
                                              type="tel"
                                              className="form-control"
                                              id="celular"
                                              placeholder="Data do seu aniversário"
                                              onChange={event => setDataNascimento(DataMask(event.target.value))}
                                              value={dataNascimento}
                                            />
                                          </div>
                                        </div>


                                        {cadastroSocial ?
                                          <>

                                            <div className="form-group basic">
                                              <div className="input-wrapper">
                                                <label className="label" htmlFor="cpf">Número do CPF</label>
                                                <input
                                                  type="tel"
                                                  className="form-control"
                                                  id="cpf"
                                                  placeholder="Digite seu CPF"
                                                  onChange={event => setCpf(cpfMask(event.target.value))}
                                                  value={cpf}
                                                  maxLength={15}
                                                  required
                                                />
                                              </div>
                                            </div>

                                            <div className="form-group basic">
                                              <div className="input-wrapper">
                                                <label className="label" htmlFor="banco">Seu Perfil</label>
                                                <select
                                                  className="form-control"
                                                  id="perfil"
                                                  onChange={event => setPerfil(event.target.value)}
                                                  value={perfil}
                                                >
                                                  <option value="">Selecione um Perfil</option>
                                                  <option value="fgts">Trabalhador CLT com FGTS ativo ou inativo</option>
                                                  <option value="inss">Aposentado e Pensionista do INSS ou BPC/LOAS</option>
                                                  <option value="representante-legal">Representante Legal de Beneficiários INSS.</option>
                                                  <option value="auxilio-brasil">Auxílio Brasil</option>
                                                  <option value="siape">SIAPE - Servidor Federal</option>
                                                  <option value="fgts">Outros</option>
                                                </select>
                                              </div>
                                            </div>

                                          </>
                                          :
                                          ''
                                        }

                                        <div className="custom-control custom-checkbox mt-2 mb-1">
                                          <div className="form-check text-dark">
                                            Ao continuar, você aceita os <button type="button" className="btn btn-light" data-bs-toggle="modal" data-bs-target="#termsModal">termos e condições</button>
                                          </div>
                                        </div>

                                        <button type="submit" className="btn btn-primary btn-block btn-lg mt-5">
                                          Finalizar Cadastro
                                        </button>

                                      </form>

                                    }
                                  </>
                                )
                          )
                          }

                        </React.Fragment>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ModalTermo></ModalTermo>
            </div>
          </>
      }
    </>
  )

}

export default CompletarCadastro;
