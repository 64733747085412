import Swal from 'sweetalert2';

import ReactPixel from 'react-facebook-pixel';

export const swalErroSimulacao = nome => {

  // Trackview
  ReactPixel.trackCustom('TenteNovamente');

  Swal.fire({
    icon: 'warning',
    // title: `${nome}`,
    html: `<p>Poxa, não estamos conseguindo simular agora, isso aconteceu devido à uma instabilidade do sistema do banco. Estamos aguardando o retorno deles para que tudo seja resolvido, mais fique tranquilo que um de nossos atendentes entrará em contato com você!</p>`,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
    showDenyButton: true,
    denyButtonText: 'WhatsApp',
    denyButtonColor: '#28a645',
    allowOutsideClick: false
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
    else if(result.isDenied){
      window.location.href='https://api.whatsapp.com/send?phone=551433138461'
    }
  });

}