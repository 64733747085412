import MenuFooter from "../../components/menu-footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Configuracoes = () => {

  async function Logout(){
    sessionStorage.removeItem('identify');

    window.location = "/login";
  }

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Pega Troco | Configurações</title>
        </Helmet>
      </HelmetProvider>
      <div className="appHeader">
          <div className="left">
              <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
              </a>
          </div>
          <div className="pageTitle">
              Configurações
          </div>
      </div>
      <div id="appCapsule">
        <div className="section inset mt-2">
          <div className="row justify-content-center">
            <div className="col-md-7 align-self-center">
              {/* <ul className="listview image-listview text inset no-line">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                Dark Mode
                            </div>
                            <div className="form-check form-switch ms-2">
                                <input className="form-check-input dark-mode-switch" type="checkbox" id="darkmodeSwitch"/>
                                <label className="form-check-label" htmlFor="darkmodeSwitch"></label>
                            </div>
                        </div>
                    </div>
                </li>
              </ul> */}
              <ul className="listview image-listview text inset no-line mt-5">
                <li>
                    <div className="item">
                        <div className="in">
                            <div>
                                Sair
                            </div>
                            <div className="form-check form-switch ms-2">
                              <button className="btn btn-danger" alt="Sair" onClick={Logout}>
                                <ion-icon name="log-out-outline"></ion-icon>
                              </button>
                            </div>
                        </div>
                    </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <MenuFooter></MenuFooter>
    </>
  );

}

export default Configuracoes;