import axios from "axios";

const enviaCodigoWhats = async (telefone, res) => {

    var config = {
        method: 'POST',
        url: process.env.REACT_APP_URL_API + '/envio-codigo',
        data: {
            'telefone': telefone
        }
    }
    const retorno_envio = await axios(config)

    .then(function (response) {

        // Desfragmentação do response
        const {
            data
        } = response;

        // Enviado
        const enviado = data && data.enviado ? true : false;

        // Codigo enviado
        if(enviado){
            return {
                httpcode: 200,
                enviado: true,
                response: 'Código enviado com sucesso!'
            }
        }
        // Código não enviado
        else {
            return {
                httpcode: 400,
                enviado: false,
                response: 'Código não foi enviado!'
            }
        }

    })

    .catch(function (error) {
        if (error.response) {
            return { 
                httpcode: error.response.status,
                enviado: false,
                response: 'Erro ao enviar código de validação!'
            };
        } else {
            return { 
                httpcode: 500,
                enviado: false,
                response: 'Erro ao enviar código de validação!'
            };
        }
    });

    return retorno_envio;
}

export default enviaCodigoWhats;