import Swal from 'sweetalert2';

export const swalNotificacaoReprovado = () => {

  Swal.fire({
    html: `
    <center>
      <p align="left">
      Poxa, desta vez não foi possível aprovar o crédito que você solicitou. <br>Mas fique tranquilo, temos outras opções para você no nosso app, basta selecionar outra modalidade!    
      <p>
    </center>`,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location = '/';
    }
  });

}