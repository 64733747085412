import axios from "axios";

const Autenticacao = async (obj) => {

  var config = {
    method: 'POST',
    url: process.env.REACT_APP_URL_API+`/obter-token`,
    data : obj
  };

  const retorno_autenticacao = await axios(config)
  .then(function (response) {

    // Desfragmentação do response
    const {
      data
    } = response;

    // Retorno do token
    if(data && data.token){
      return {
        'httpcode':200,
        'autenticacao':true,
        'token':data.token,
        'response':data.response
      }
    }
    // Não teve o retorno do token
    else {
      return {
        'httpcode':401,
        'autenticacao':false,
        'response':'Não foi possivel obter o token!'
      }
    }

  })
  .catch(function (error) {
    
    if (error.response) {
      return {
        'httpcode':error.response.status,
        'autenticacao':false,
        'response':'Não foi possivel obter o token!'
      };
      
    } else {
      return {
        'httpcode':500,
        'autenticacao':false,
        'response':'Não foi possivel obter o token!'
      };
    }


  });

  return retorno_autenticacao;

}

export default Autenticacao;