import Swal from 'sweetalert2';

export const AutenticacaoInvalida = () => {

  // Deleta dados da session storage
  sessionStorage.removeItem('identify');
  sessionStorage.removeItem('nome');

  Swal.fire({
    icon: 'info',
    // title: `${nome}`,
    html: `<p>Sua sessão no App da Pegatroco expirou, clique no botão <strong>OK</strong> para direcionarmos você para a página de autenticação!</p>`,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
    allowOutsideClick: false
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location.href='/login'
    }
  });

}