import Swal from 'sweetalert2';

export const NotificacaoIndisponivel = () => {

  Swal.fire({
    html: `
    <center>
      <p align="left">
      Poxa, a simulação está indisponivel no momento, tente novamente mais tarde!
      <p>
    </center>`,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location = '/';
    }
  });

}