import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import axios from "axios";

import { PrimeiroNome } from '../../tools/PrimeiroNome';
import LodingIcon from '../../assets/img/loading-icon.png';

import Swal from 'sweetalert2';

import MenuFooter from "../../components/menu-footer";

import { swalSemSaldo } from "../../components/notify/SemSaldo";
import { swalErroSimulacao } from "../../components/notify/ErroSimulacao";
import { swalPropostaAndamento } from "../../components/notify/PropostaAndamento";

import { firestore } from '../../services/firebase';

import ReactPixel from 'react-facebook-pixel';

// Tools
import { formatarMoeda } from "../../tools/formatarMoeda";
import { NumeroMask } from "../../tools/NumeroMask";

const SimulacaoAuxilioBrasil = () => {

  // Trackview
  ReactPixel.trackCustom('ClicouAuxilioBrasil');

  // Código Familiar
  const [codigoFamiliar, setCodigoFamiliar] = useState('');
  // Valor do auxilio
  const [valorAuxilio, setValorAuxilio] = useState('');

  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  const [loading, setLoading] = useState(false);
  const elementLoading = <div id="loader">
                          <img src={LodingIcon} alt="icon" className="loading-icon"/>
                        </div>;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // Simulação Auxilio Brsail
  async function simularAuxilioBrasil(){

    // Código Familiar
    if(codigoFamiliar.trim() === ''){
      Toast.fire({
        icon: 'info',
        title: 'Preencha o Código Familar!'
      });
      // Focus
      document.getElementById('codigo-familiar').focus();
    }
    // Valor do auxilio
    else if(valorAuxilio.trim() === ''){
      Toast.fire({
        icon: 'info',
        title: 'Preencha o Valor do Auxílio Brasil!'
      });
      // Focus
      document.getElementById('valor-auxilio').focus();
    }
    else {

      setLoading(true);

      var config = {
        method: 'post',
        url: process.env.REACT_APP_URL_API+`/consulta-auxilio-brasil`,
        data : {
          'uid_google':sessionStorage.getItem('identify'),
          'codigo_familiar':codigoFamiliar,
          'renda':valorAuxilio
        }
      };
    
      axios(config)
      .then(async function (response) {

        setLoading(false);
  
        if(response.data && response.data.simulacoes){
          sessionStorage.setItem('obj_simulacao', JSON.stringify(response.data));
  
          // Retorno das simulações
          const simulacoes = response.data && response.data.simulacoes ? response.data.simulacoes : null;
  
          const simulacao = firestore.collection('simulacao');
  
          var array_mes = ['1','2','3','4','5','6','7','8','9','10','11','12'];
  
          // Obtém a data/hora atual
          var data = new Date();
          // Formatação de data
          var dia = data.getDate();
          var mes = array_mes[data.getMonth()];
          var ano = data.getFullYear();
          var str_data = ano+'-'+mes+'-'+dia;
  
          await simulacao.doc(sessionStorage.getItem('identify')).set(
            {
              auxilio:{
                nome:sessionStorage.getItem('nome'),
                data:str_data,
                contratacao:false,
                simulacoes:simulacoes
              }
            }
          );
  
          window.location = '/contratacao';
  
        }
        // Sem Saldo
        else if(response.data && response.data.sem_saldo){
          swalSemSaldo(primeiroNome);
        }
        // Proposta em andamento
        else if(response.data && response.data.proposta_andamento){
          swalPropostaAndamento(primeiroNome);
        }
        // Erro
        else {
          swalErroSimulacao(primeiroNome);
        }
    
      })
      .catch(function (error) {
        setLoading(false);
  
        Toast.fire({
          icon: 'error',
          title: 'Simulação temporariamente indisponível!'
        });
      });

    }

  }

  return (
    <>
      {
        loading 
        ? 
        elementLoading :
        <>
          <HelmetProvider>
            <Helmet>
              <title>Pega Troco | Simulação Auxílio Brasil</title>
            </Helmet>
          </HelmetProvider>
          <div className="appHeader">
              <div className="left">
                  <a href="/" className="headerButton goBack">
                      <ion-icon name="chevron-back-outline"></ion-icon>
                  </a>
              </div>
              <div className="pageTitle">
                Simulação Auxílio Brasil
              </div>
          </div>
          <div id="appCapsule">
            <div className="section mb-2">
              <div className="row mt-2 justify-content-center">
                <div className="col-md-7 align-self-center">
                  <div className="bill-box">
                    <div className="price">Para realizarmos a sua simulação, vamos precisar dos dados abaixo:</div>
                    <div className="row">
                        <br></br>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="codigo-familiar">Código Familiar</label>
                            <input 
                              type="tel" 
                              className="form-control" 
                              id="codigo-familiar" 
                              autoComplete="off"
                              placeholder="Seu Código Familiar"
                              required
                              onChange={event => setCodigoFamiliar(NumeroMask(event.target.value))}
                              value={codigoFamiliar}
                              maxLength="11"
                            />
                            <span className="text-right">
                              <a href="https://cadunico.dataprev.gov.br/#/consultaSimples" target="_blank" rel="noreferrer" className="text-danger fw-bolder">
                                Veja aqui o seu Código Familiar!
                              </a>
                            </span>
                          </div>
                          

                        </div>
                        <br></br>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                              <label className="label" htmlFor="valor-auxilio">Valor total do Auxílio Brasil</label>
                              <input 
                                type="tel"
                                className="form-control"
                                id="valor-auxilio"
                                placeholder="Valor total do Auxílio Brasil"
                                onChange={event => setValorAuxilio(formatarMoeda(event.target.value))}
                                value={valorAuxilio}
                                required
                              />
                          </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                      <div className="col">
                        <button className="btn btn-primary btn-lg" onClick={simularAuxilioBrasil}>
                          Simular
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MenuFooter></MenuFooter>
        </>
      }
    </>
  );
}

export default SimulacaoAuxilioBrasil;