import Swal from 'sweetalert2';

export const swalNaoInteresseBancario = data => {


  Swal.fire({
    title: ``,
    denyButtonText: 'Vou desistir',
    confirmButtonText: `Vou prosseguir`,
    confirmButtonColor: '#1DCC70',
    html: `<p align="left">Medo de colocar seus dados bancários? <span class="fw-bold">Nós te entendemos!</span></p>
    <p align="left">Veja aqui nossas avaliações no <a class="" href="https://g.page/r/CVxCMsMUTywQEAI/review" target="_blank">Google</a> e tenha mais segurança lendo sobre nós no <a class="" href="https://www.reclameaqui.com.br/empresa/pegatroco/" target="_blank">ReclameAqui</a></p>
    <p class="fw-bold" align="left">Agora vamos prosseguir?</p>`,
    allowOutsideClick: false,
    showDenyButton: true,
    denyButtonColor: '#6e6e6e',
    customClass: {
      denyButton: 'button-size',
      confirmButton: 'button-size fw-bold'
    }
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
    if (result.isDenied) {
      window.location = '/';
    }
  });

}