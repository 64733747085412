import React, { useEffect, useState } from "react";
import MenuFooter from "../../components/menu-footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import axios from "axios";

// Component
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";
import Swal from "sweetalert2";

// Imagens
import LodingIcon from '../../assets/img/loading-icon.png';
import NaoLocalizado from '../../assets/img/nao-localizado.png';
import Banco from '../../assets/img/banco.png';

// Tools
import { DataEnMask } from '../../tools/DataEnMask';

// Helpers
import alterarStatusContrato from "../../helper/alterarcontrato";
import ConsultaIp from "../../helper/geoip";

const ContratoDetalhado = () => {

    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    // Dados da contratação
    const [dadosContratacao, setDadosContratacao] = useState({});
    // Dados de pagamento
    const [dadosPagamento, setDadosPagamento] = useState({});
    // Dados de Status
    const [status, setStatus] = useState({});
    // Nome do produto
    const [produto, setProduto] = useState(false);

    const elementLoading = <div id="loader">
        <img src={LodingIcon} alt="icon" className="loading-icon" />
    </div>;

    // Estilização IMG Não localizado
    const naolocalizadoStyle = {
        width: '65%'
    };

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    // Consulta contratos Andamento
    useEffect(() => {

        // Token
        const token = sessionStorage.getItem('identify');
        // Filtro
        const filtro = `?id_contrato=${id}`

        var config = {
            method: 'GET',
            url: process.env.REACT_APP_URL_API + `/contrato/consulta${filtro}`,
            data: '',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        axios(config)
            .then(async function (response) {

                // Desfragmentação do response
                const {
                    data
                } = response;

                // Retorno de contrato
                if (data && data.contrato) {

                    // Contrato
                    const {
                        contrato
                    } = data;

                    // Primeiro contrato
                    const primeiro_contrato = contrato && contrato[0] ? contrato[0] : {};

                    // Desfragmentação do primeiro contrato
                    const {
                        id,
                        contratacao,
                        pagamento,
                        produto,
                        status
                    } = primeiro_contrato;

                    // Definindo alteração de estado
                    setDadosContratacao(contratacao);
                    setDadosPagamento(pagamento);
                    setStatus(status)
                    setProduto(produto);
                }
                // Stop Loading
                setLoading(false);

            })
            .catch(function (error) {

                // Stop Loading
                setLoading(false);

                if (error.response) {

                    // Desfragmentação do erro
                    const {
                      status
                    } = error.response;
          
                    // Não autorizado
                    if(status == 401){
                      // Swal de notificação
                      AutenticacaoInvalida();
                    }
                    
                }

            });
    }, [id]);

    return (
        <>
            {
                loading
                    ?
                    elementLoading :
                    <>
                        <HelmetProvider>
                            <Helmet>
                                <title>Pega Troco | Contratos</title>
                            </Helmet>
                        </HelmetProvider>
                        <div className="appHeader">
                            <div className="left">
                                <a href="/contratos" className="headerButton goBack">
                                    <ion-icon name="chevron-back-outline"></ion-icon>
                                </a>
                            </div>
                            <div className="pageTitle">
                                Contrato Detalhado
                            </div>
                            {/* <div className="right">
                            <a href="app-notifications.html" className="headerButton">
                                <ion-icon className="icon" name="notifications-outline"></ion-icon>
                                <span className="badge badge-danger">4</span>
                            </a>
                        </div> */}
                        </div>
                        <div id="appCapsule">
                            <div className="section mt-2 mb-2">

                                <div className="row justify-content-center">
                                    <div className="col-md-7 align-self-center">
                                        <React.Fragment>
                                            {

                                                dadosContratacao && dadosPagamento && status && produto ? (
                                                    <>
                                                        <div className="listed-detail mt-3">
                                                            <div className="icon-wrapper">
                                                                <div className="iconbox bg-white">
                                                                    <img src={Banco} className="img-fluid" alt="Imagem do banco" width="45" />
                                                                </div>
                                                            </div>
                                                            <h3 className="text-center mt-2">{dadosContratacao && dadosContratacao.nome_banco ? dadosContratacao.nome_banco : null}</h3>
                                                        </div>

                                                        <ul className="listview flush transparent simple-listview no-space mt-3">
                                                            <li>
                                                                <strong>Status</strong>
                                                                <span>
                                                                    {status && status.nome ? status.nome : null}
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <strong>Número do contrato</strong>
                                                                <span>{dadosContratacao.numero_contrato ? dadosContratacao.numero_contrato : null}</span>
                                                            </li>
                                                            {
                                                                dadosContratacao.nome_banco != "Crefaz" ?
                                                                <li>
                                                                <strong>Link de assinatura digital</strong>
                                                                    {
                                                                        dadosContratacao.link_formalizacao ?
                                                                            <span className="fw-bold"><a href={dadosContratacao.link_formalizacao} target="_blank" rel="noreferrer">Clique aqui!</a></span>
                                                                            : <span className="pt-azul fw-bold">Gerando link...</span>
                                                                    }
                                                                </li> : <></>
                                                                
                                                            }
                                                            <li>
                                                                <strong>Quantidade de parcelas</strong>
                                                                <span>{dadosContratacao.prazo ? dadosContratacao.prazo : null}</span>
                                                            </li>
                                                            {/* <li>
                                                    <strong>Parcelas restantes</strong>
                                                    <span>{contrato.dados && contrato.dados.prazo ? contrato.dados.prazo : null}</span>
                                                </li> */}
                                                            {dadosContratacao.valor_parcela ?
                                                                <li>
                                                                    <strong>Valor da parcela</strong>
                                                                    <span>{dadosContratacao.valor_parcela.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span>
                                                                </li>
                                                                : null}
                                                            <li>
                                                                <strong>Banco de Pagamento</strong>
                                                                <span>{dadosPagamento.nome_banco ? dadosPagamento.nome_banco : dadosPagamento.codigo_banco ? dadosPagamento.codigo_banco : null}</span>
                                                            </li>
                                                            <li>
                                                                <strong>Agência</strong>
                                                                <span>{dadosPagamento.agencia ? dadosPagamento.agencia.replace('-undefined', '').replace('null', '') : null}</span>
                                                            </li>
                                                            <li>
                                                                <strong>Conta</strong>
                                                                <span>{dadosPagamento.conta ? dadosPagamento.conta.replace('null', '') : null}</span>
                                                            </li>
                                                            <li>
                                                                <strong>Tipo da conta</strong>
                                                                <span>{dadosPagamento.tipo_conta ? dadosPagamento.tipo_conta : null}</span>
                                                            </li>
                                                            <li>
                                                                <strong>Data de contratação</strong>
                                                                <span>{dadosContratacao.data_criacao ? DataEnMask(dadosContratacao.data_criacao) : null}</span>
                                                            </li>
                                                            <li>
                                                                <strong>Valor do contrato</strong>
                                                                <h2 className="m-0">{dadosContratacao.valor_emprestimo ? dadosContratacao.valor_emprestimo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}</h2>
                                                            </li>
                                                        </ul>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="row justify-content-center mt-4">
                                                            <img src={NaoLocalizado} className="img-fluid" alt="Imagem não localizado" style={naolocalizadoStyle}></img>
                                                            <h4 className="text-center mt-4 fs-5">Nenhum contrato localizado!</h4>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MenuFooter></MenuFooter>
                    </>
            }
        </>
    )

}

export default ContratoDetalhado;