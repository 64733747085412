import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../../assets/css/style.css';

import LodingIcon from '../../assets/img/loading-icon.png';
import Logo from '../../assets/img/pegatroco.png';
import Swal from 'sweetalert2';

import { auth, firestore, firebase } from '../../services/firebase';

import ReactPixel from 'react-facebook-pixel';
import Autenticacao from "../../helper/autenticacao/autenticar";


const Login = () => {

  // Trackview
  ReactPixel.trackCustom('PageViewLogin');

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');

  const [searchParams] = useSearchParams();

  const url_atual = window.location.search;

  const next_page = searchParams.get('next') ? searchParams.get('next') : null;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const elementLoading = <div id="loader">
                          <img src={LodingIcon} alt="icon" className="loading-icon"/>
                        </div>;

  // Autenticação com Google
  async function handleLogin(){
    if(!user){
      await signInWithGoogle();
    }
  }

  // Autenticação Apple
  async function handleLoginApple(){
    if(!user){
      await signInWithApple();
    }
  }

  // Autenticação via E-mail e Senha
  async function signInEmail(event){

    event.preventDefault();

    const result = await auth.signInWithEmailAndPassword(email, senha)
    .then((result) => {

      const {uid, email} = result.user;

      ConsultaUsuario({'uid':uid, 'email':email});
      
    })
    .catch((error) => {

      if(error && error.code){
        Toast.fire({
          icon: 'warning',
          title: 'E-mail ou Senha incorretos!'
        });
      }
      else {
        Toast.fire({
          icon: 'warning',
          title: 'Ops, tivemos um problema!'
        });

        window.location = `/login/${url_atual}`;
      }

    });

    return result;
  }

  // Autenticação com Google
  async function signInWithGoogle(){
    const provider = new firebase.auth.GoogleAuthProvider();

    const result = await auth.signInWithPopup(provider)
    .then((result) => {

      const {displayName, photoURL, uid, email} = result.user;

      if(!displayName || !photoURL){
        throw new Error('Nenhuma informação sobre a conta Google localizada!');
      }

      setUser({
        id:uid,
        name:displayName,
        email:email,
        avatar:photoURL
      });

      
      ConsultaUsuario({'uid':uid, 'nome':displayName, 'email':email});
      
    })
    .catch((error) => {
      return {'httpcode':500}
    });

    return result;

  }

  // Autenticação com Apple
  async function signInWithApple(){
    const provider = new firebase.auth.OAuthProvider('apple.com');
    
    const result = await auth.signInWithPopup(provider)
    .then(async (result) => {

      const {displayName, uid, email} = result.user;

      // if(!displayName || !uid){
      //   throw new Error('Nenhuma informação sobre a conta Apple localizada!');
      // }

      setUser({
        id:uid,
        name:displayName,
        email:email,
        avatar:null
      });

      
      ConsultaUsuario({'uid':uid, 'nome':displayName, 'email':email});
      
    })
    .catch((error) => {
      return {'httpcode':500}
    });

    return result;
  }

  // Consulta Usuário Firebase
  async function ConsultaUsuario(values){

    setLoading(true);

    const usuario = firestore.collection('usuario').doc(values.uid);
    
    const getUsuario = await usuario.get()

    if(!getUsuario){
      await CadastroUsuario(values);
      setLoading(false);
    }
    else {
      
      if(getUsuario.data()){

        // Nome do cliente
        const nome = getUsuario.data().nome ? getUsuario.data().nome : null;
        // E-mail
        const email = getUsuario.data().email ? getUsuario.data().email : null;
        // CPF
        const cpf = getUsuario.data().cpf ? getUsuario.data().cpf : null;
        // Telefone
        const telefone = getUsuario.data().telefone ? getUsuario.data().telefone : null;
  
        // Usuário localizado
        if(nome && email && cpf && telefone){
         
          // Autenticação
          const autenticacao = await Autenticacao({uuid:values.uid, email: email});

          // Stop Loading
          setLoading(false);

          // Autenticação realizada
          if(autenticacao&& autenticacao.token){

            // Track de autenticação
            ReactPixel.trackCustom('Entrou');

            // Remoção de obj
            sessionStorage.removeItem('obj_client');
            // Adição de valores ao session storage
            sessionStorage.setItem('identify', autenticacao.token);
            sessionStorage.setItem('nome', nome);

            // Redirecionamento
            next_page ? window.location = next_page : window.location = "/";
            
          }
          // Sistema Off
          else {
            Toast.fire({
              icon: 'warning',
              title: 'Sistema temporariamente indisponível!'
            });          
          }
          
        }
        // Completar Cadastro
        else {
  
          // Session Storage
          const obj_client = {
            'nome':values.nome,
            'email':values.email,
            'uid':values.uid
          }
  
          sessionStorage.setItem('obj_client', JSON.stringify(obj_client));
  
          window.location = "/completar-cadastro"+url_atual;
        }
      }
      // Cadastro Usuario
      else {
  
        await CadastroUsuario(values);

      }
      

    }

  }

  // Cadastro Usuário Firebase
  async function CadastroUsuario(values){

    const usuario = firestore.collection('usuario');
    
    await usuario.doc(values.uid).set(
      {
        email:values.email,
        nome:values.nome
      }
    );

    // Session Storage
    const obj_client = {
      'nome':values.nome,
      'email':values.email,
      'uid':values.uid
    }
    
    sessionStorage.setItem('obj_client', JSON.stringify(obj_client));

    window.location = "/completar-cadastro"+url_atual;

  }

  return (
    <>
      {
        loading 
        ? 
        elementLoading :
        <>
          <div id="appCapsule">
            <HelmetProvider>
              <Helmet>
                <title>Pegatroco | Login</title>
              </Helmet>
            </HelmetProvider>
            <div className="section mt-2 text-center">
                <img src={Logo} alt="Logo" className="img-fluid mb-2" width="500"></img>
                <h4>Realize o login para continuar.</h4>
            </div>
            <div className="section mb-5 p-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-5 align-self-cente">
                  <form method="POST" onSubmit={signInEmail}>
                      <div className="card">
                          <div className="card-body pb-1">
                              <div className="form-group basic">
                                  <div className="input-wrapper">
                                      <label className="label" htmlFor="email1">E-mail</label>
                                      <input 
                                        type="email"
                                        className="form-control"
                                        id="email1"
                                        placeholder="Seu e-mail"
                                        required
                                        onChange={event => setEmail(event.target.value)}
                                        value={email}
                                      />
                                  </div>
                              </div>

                              <div className="form-group basic">
                                  <div className="input-wrapper">
                                      <label className="label" htmlFor="password1">Senha</label>
                                      <input 
                                        type="password"
                                        className="form-control"
                                        id="password1"
                                        autoComplete="off"
                                        placeholder="Sua senha"
                                        required
                                        onChange={event => setSenha(event.target.value)}
                                        value={senha}
                                      />
                                  </div>
                              </div>

                              <button type="submit" className="btn btn-primary btn-block btn-lg mt-5">Entrar</button>

                              <div className="separator">
                                Ou
                              </div>

                              <button type="button" className="btn btn-light btn-block btn-lg mb-3" onClick={handleLogin}>
                                <svg className="mx-2" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.64 9.20443C17.6405 8.58714 17.5857 7.97105 17.4764 7.36353H9V10.8449H13.8436C13.635 11.9699 13.0009 12.9231 12.0477 13.5613V15.8194H14.9564C16.6582 14.2526 17.64 11.9453 17.64 9.20443Z" fill="#4285F4"></path><path d="M8.99975 18C11.4298 18 13.467 17.194 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99975 14.4204C6.65566 14.4204 4.67157 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9832 5.48156 18 8.99975 18Z" fill="#34A853"></path><path d="M3.96409 10.7101C3.58772 9.60115 3.58772 8.39902 3.96409 7.29008V4.95825H0.957274C0.327798 6.2126 0 7.59664 0 9.00007C0 10.4035 0.327798 11.7875 0.957274 13.0419L3.96409 10.7101Z" fill="#FBBC05"></path><path d="M8.99977 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891812 11.4257 0 8.99975 0C5.48157 0 2.43794 2.01683 0.957031 4.95818L3.96386 7.29003C4.67159 5.16276 6.65568 3.57956 8.99977 3.57956V3.57955Z" fill="#EA4335"></path></svg>
                                Continuar com o Google
                              </button>

                              <button type="button" className="btn btn-light btn-block btn-lg mb-5" onClick={handleLoginApple}>
                                <svg className="mx-2" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>
                                  <path d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"/>                                
                                </svg>
                                Continuar com a Apple
                              </button>
                          </div>
                      </div>


                      <div className="form-links mt-2">
                          <div>
                              <a href={'/cadastro'+url_atual}>Cadastre-se</a>
                          </div>
                          <div><a href="/esqueceu-senha" className="text-dark">Esqueceu sua senha?</a></div>
                      </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Login;