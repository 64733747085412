import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Naolocalizado from '../../assets/img/404.png';

const NotFoud = () => {

  return (
    <div id="appCapsule">
      <HelmetProvider>
        <Helmet>
          <title>Pegatroco | 404</title>
        </Helmet>
      </HelmetProvider>
      <div className="section mb-5 p-2">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5 align-self-cente">
            <div className="section mt-2 text-center">
              <h1>Página não encontrada</h1>
              <img src={Naolocalizado} alt="Logo" className="img-fluid mb-2" width="500"></img>
            </div>

            <a href="/" className="btn btn-primary btn-block btn-lg mt-5">
              Voltar para Home
            </a>

          </div>
        </div>
      </div>
    </div>
  );

}

export default NotFoud;