import Swal from 'sweetalert2';

export const swalPropostaAndamento = nome => {

  Swal.fire({
    // icon: 'warning',
    // title: `${nome}`,
    html: `<p align="left"><strong class="fs-5">${nome}</strong>, verificamos que você já tem uma proposta em andamento.</p><p>Qualquer dúvida entre em contato conosco!</p>`,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
    allowOutsideClick: false,
    showCloseButton: true,
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      window.location = '/duvidas';
    }
  });

}