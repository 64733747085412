import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import MenuFooter from "../../components/menu-footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { PrimeiroNome } from '../../tools/PrimeiroNome';
import ContratacaoFgts from "../../components/contratacao/fgts";
import ContratacaoInss from "../../components/contratacao/inss";
import ContratacaoAuxilio from "../../components/contratacao/auxilio";
import ContratacaoCartao from "../../components/contratacao/cartao";

import { swalNaoInteresse } from "../../components/notify/NaoInteresse";

import ReactPixel from 'react-facebook-pixel';
import axios from "axios";
import ConsultaSimulacao from "../../helper/consultasimulacao";
import { BsLightningFill } from "react-icons/bs";

import LodingIcon from '../../assets/img/loading-icon.png';

import Swal from 'sweetalert2'
import StepContratacao from "../step_contratacao";

const Contratacao = () => {

  const navigate = useNavigate();

  const { uuid } = useParams();

  const [contratacao, setContratacao] = useState(false);

  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  // Objeto completo
  const obj_simulacao = JSON.parse(sessionStorage.getItem('obj_simulacao'));

  // UUID Simulacao
  const uuid_simulacao = obj_simulacao && obj_simulacao.uuid_simulacao ? obj_simulacao.uuid_simulacao : uuid;

  // Simulações
  const [simulacoes, setSimulacoes] = useState(obj_simulacao != null && obj_simulacao.simulacoes ? obj_simulacao.simulacoes : []);

  // Tipo (inss/fgts)
  const [tipo_simulacao, setTipoSimulacao] = useState(obj_simulacao && obj_simulacao.tipo_simulacao ? obj_simulacao.tipo_simulacao : simulacoes.tipo_simulacao ? simulacoes.tipo_simulacao : '');

  //IDENTIFY
  const identify = sessionStorage.getItem('identify') ? sessionStorage.getItem('identify') : null;

  //LOADING
  const [loading, setLoading] = useState(false);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;


  //Função para consultar simulações pelo uuid
  useEffect(() => {

    async function fetchData() {

      const data = {
        'uuid_simulacao': uuid
      }

      const simulacao = await ConsultaSimulacao(data);

      if (simulacao.httpcode && simulacao.httpcode == 404) {

        Swal.fire({
          text: "Sua simulação não foi encontrada, faça uma nova simulação para prosseguir com sua contratação!",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Nova Simulação!',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/`)

          }
        })

      }
      // Não autenticado
      else if(simulacao.httpcode && simulacao.httpcode == 401){
        Swal.fire({
          text: "Para seguir sua contratação precisamos que faça seu Cadastro ou Login no App!",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Okay!',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/login?next=/contratacao/${uuid}`)

          }
        })
      }
      else if (simulacao.user_not_signed) {

        Swal.fire({
          text: "Para seguir sua contratação precisamos que faça seu Cadastro ou Login no App!",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Okay!',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/login?next=/contratacao/${uuid}`)

          }
        })

      }
      else if (simulacao.user_not_found) {

        Swal.fire({
          text: "Sua simulação não foi encontrada, faça uma nova simulação para prosseguir com sua contratação!",
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Nova Simulação!',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/`)

          }
        })

      }
      else {
        setSimulacoes(simulacao.simulacoes)
        setTipoSimulacao(simulacao.tipo_simulacao)

        if (simulacao.tipo_simulacao.includes("inss")) {

          window.gtag('event', 'conversion', { 'send_to': 'AW-11012337992/WQY_CN7uuYAYEMjii4Mp' });

        }
        else if (simulacao.tipo_simulacao == "cartao") {

          window.gtag('event', 'conversion', { 'send_to': 'AW-11012337992/C7z1COTuuYAYEMjii4Mp' });

        }
        else if (simulacao.tipo_simulacao == "fgts") {

          window.gtag('event', 'conversion', { 'send_to': 'AW-11012337992/BrPeCNvuuYAYEMjii4Mp' });

        }
        else {
          //outras simulacoes
          window.gtag('event', 'conversion', { 'send_to': 'AW-11012337992/iOWnCNT6uIAYEMjii4Mp' });
        }
      }
    }
    fetchData();

  }, [uuid, identify, navigate])


  if (obj_simulacao && simulacoes === null) {
    window.location = '/';
  }

  // Alteração de estado (processo de contratação)
  async function chageState(id) {

    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    // Busca id no array de simulacoes
    const simulacao = simulacoes.find(val => val.id_simulacao === id);
    simulacao.uuid_simulacao = uuid_simulacao;

    // Gerando novo Session Storage com a opção selecionada
    sessionStorage.setItem('obj_contratacao', JSON.stringify(simulacao));

    // Banco Selecionado
    const banco_selecionado = simulacao.nome_banco;
    // Valor da contratação
    const valor_selecionado = simulacao.valor_contrato;

    // INSS
    if (tipo_simulacao === "inss") {
      // Track do banco/valor contratado INSS
      ReactPixel.trackCustom(`ValorContratacaoInss${banco_selecionado}`, { currency: "BRL", value: valor_selecionado });
      // Track Seguir contratação INSS
      ReactPixel.trackCustom('SeguirContratacaoInss');
    }
    // Auxilio Brasil
    else if (tipo_simulacao === "auxilio") {
      // Track do banco/valor contratado AuxilioBrasil
      ReactPixel.trackCustom(`ValorContratacaoAuxilioBrasil${banco_selecionado}`, { currency: "BRL", value: valor_selecionado });
      // Track Seguir contratação AuxilioBrasil
      ReactPixel.trackCustom('SeguirContratacaoAuxilioBrasil');
    }
    // Energia
    else if (tipo_simulacao === "energia") {
      // Track do banco/valor contratado Energia
      ReactPixel.trackCustom(`ValorContratacaoEnergia${banco_selecionado}`, { currency: "BRL", value: valor_selecionado });
      // Track Seguir contratação Energia
      ReactPixel.trackCustom('SeguirContratacaoEnergia');
    }
    // Fgts
    else {
      // Track do banco/valor contratado FGTS
      ReactPixel.trackCustom(`ValorContratacaoFgts${banco_selecionado}`, { currency: "BRL", value: valor_selecionado });
      // Track Seguir contratação FGTS
      ReactPixel.trackCustom('SeguirContratacaoFgts');
    }

    setContratacao(true);
  }

  // Geração do Swal de Notificação
  async function semInteresse() {

    // INSS
    if (tipo_simulacao === "inss") {
      // Track Não quero Dinhero INSS
      ReactPixel.trackCustom('NaoQueroDinheiroInss');
    }
    // Auxilio Brasil
    else if (tipo_simulacao === "auxilio") {
      // Track Não quero Dinhero INSS
      ReactPixel.trackCustom('NaoQueroDinheiroAuxilioBrasil');
    }
    // Energia
    else if (tipo_simulacao === "energia") {
      // Track Não quero Dinhero Energia
      ReactPixel.trackCustom('NaoQueroDinheiroEnergia');
    }
    // FGTS
    else {
      // Track Não quero Dinhero FGTS
      ReactPixel.trackCustom('NaoQueroDinheiroFgts');
    }

    // Valor liberado
    const valor_liberado = simulacoes && simulacoes[0] && simulacoes[0].valor_contrato ? simulacoes[0].valor_contrato : '';

    swalNaoInteresse(
      {
        'nome': primeiroNome,
        'saldo': valor_liberado
      });
  }

  return (
    <>{
      loading
      ?
        elementLoading:
        <>
          <HelmetProvider>
            <Helmet>
              <title>Pega Troco | Contratação</title>
            </Helmet>
          </HelmetProvider>
          <div className="appHeader">
            <div className="left">
              <a href="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
              </a>
            </div>
            <div className="pageTitle">
              Contratação
            </div>
            <div className="right">
              <button type="button" className="btn-close" onClick={semInteresse}></button>
            </div>
          </div>
          <div id="appCapsule">
            <div className="section container mb-2">

              <div className="row mt-4 justify-content-center gap-2">

                {
                  contratacao === false ? (

                    simulacoes.map((conteudo, index) =>

                      <div className="col-lg-4 col-md-5 col-sm-12 col-12" key={index}>

                        {conteudo.suspenso == true ?
                          <>
                            <div className="card row">

                              <div className="transactions">
                                <div className="item" style={{ "boxShadow": "none" }}>
                                  <div className="detail">
                                    <img src={conteudo.img_banco} alt="img" className="image-block imaged w48" />
                                    <div>
                                      <strong className="fs-6">{conteudo && conteudo.nome_fantasia ? conteudo.nome_fantasia : null}</strong>
                                      <p>
                                        {
                                          tipo_simulacao && tipo_simulacao === "inss" ?
                                            'Empréstimo Consignado'
                                            : tipo_simulacao && tipo_simulacao === "auxilio" ?
                                              'Empréstimo Auxílio Brasil'
                                              : tipo_simulacao && tipo_simulacao === "cartao" ?
                                                'Cartão de Crédito Consignado'
                                                : tipo_simulacao && tipo_simulacao === "fgts" ?
                                                  'Saque Aniversário do FGTS'
                                                  :
                                                    ''
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="transactions">
                                <div className="item" style={{ "boxShadow": "none" }}>
                                  <div className="right">
                                    <div className="price text-dark">
                                      <p className="text-dark">
                                        A contratação de novos empréstimos está temporariamente indisponível. Se você já contratou, fique tranquilo que a sua proposta continua válida.
                                        <br></br>Para acompanhar seu contrato acesse a aba <a href="/contratos">Contratos</a>
                                      </p>
                                    </div>
                                  </div>
                                </div>

                              </div>

                            </div>

                          </> :
                          <>

                            <div className="card-oferta shaddow-md">
                              <div className="d-flex flex-row align-items-center gap-3">
                                <div>
                                  <img src={conteudo.img_banco} alt="logo banco" width="48" />
                                </div>
                                <div className="mt-2 text-dark">
                                  <h1 className="bank-name">{conteudo && conteudo.nome_fantasia ? conteudo.nome_fantasia : null}</h1>
                                  <p>
                                    {
                                      tipo_simulacao && tipo_simulacao === "inss" || tipo_simulacao && tipo_simulacao === "inss-rep" ?
                                        'Empréstimo Consignado'
                                        : tipo_simulacao && tipo_simulacao === "auxilio" ?
                                          'Empréstimo Auxílio Brasil'
                                          : tipo_simulacao && tipo_simulacao === "cartao" ?
                                            'Cartão de Crédito Consignado'
                                            : tipo_simulacao && tipo_simulacao === "fgts" ?
                                              'Saque Aniversário do FGTS'
                                              : tipo_simulacao && tipo_simulacao === "energia" ?
                                                'Empréstimo na Conta de Luz'
                                                : 
                                                  ''
                                    }
                                  </p>
                                </div>
                              </div>
                              <div>
                                <ul className="text-dark justify-content-left my-2">
                                  {
                                    tipo_simulacao != "energia" ?
                                    <li>
                                      <p>Taxa de juros mensal: <span className="fw-bold">{conteudo.taxa_juros}%</span></p>
                                    </li>
                                    : <></>
                                  }
                                  <li>
                                    <p>{tipo_simulacao === "fgts" ? ` ${conteudo.prazo} saques antecipados` : `Prazo: ${conteudo.prazo} vezes`}</p>
                                  </li>
                                  {
                                    tipo_simulacao == "energia" ?
                                    <li>
                                      <p>Valor de parcela: {conteudo.valor_parcela ? conteudo.valor_parcela.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : ''}</p>
                                    </li>
                                    : <></>
                                  }
                                  {
                                    tipo_simulacao != "energia" ?
                                    <li>
                                      <p>Pagamento no Pix <BsLightningFill /></p>
                                    </li>
                                    : <></>
                                  }
                                </ul>
                              </div>
                              <div className="row justify-content-center align-items-center gap-2">

                                <div className="text-dark">
                                  <div className="fw-bold mb-1">Contrate</div>
                                  <div className="fw-bold pt-verde fs-3">{conteudo.valor_contrato ? conteudo.valor_contrato.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null}</div>
                                </div>

                                <button className="btn btn-success btn mt-2" onClick={() => chageState(conteudo.id_simulacao)}>
                                  Quero esse!
                                </button>
                              </div>

                            </div>
                          </>
                        }
                      </div>

                    )
                  )
                    : <StepContratacao />
                }

              </div>
            </div>
          </div>
          <MenuFooter></MenuFooter>
        </>
    }
    </>
  );
}

export default Contratacao;