import React, { useEffect, useState } from "react";
import Select from 'react-select';
import ReactPixel from 'react-facebook-pixel';
import axios from "axios";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import MenuFooter from "../../components/menu-footer";

//icons
import { FcContacts, FcHome, FcLibrary, FcNews } from "react-icons/fc";
import LodingIcon from '../../assets/img/loading-icon.png';

//tools
import { LetraMask } from '../../tools/LetraMask';
import { OrgaoEmissor } from "../../tools/OrgaoEmissor";
import { DataMask } from "../../tools/DataMask";
import ConsultaIp from "../../helper/geoip";
import { CepMask } from "../../tools/CepMask";
import ConsultarCep from "../../helper/consultacep";
import { Bancos } from "../../tools/Bancos";
import { NumeroMask } from "../../tools/NumeroMask";
import { PrimeiroNome } from "../../tools/PrimeiroNome";
import { DataEnMask } from "../../tools/DataEnMask";

//SWEET ALERT
import Swal from 'sweetalert2';
import { swalNaoInteresse } from "../../components/notify/NaoInteresse";
import { swalNotificacaoInicial } from "../../components/notify/NotificacaoInicial";
import { swalNaoInteresseDadosPessoais } from "../../components/notify/saidaDadosPessoais";
import { swalNaoInteresseEndereco } from "../../components/notify/saidaDadosEndereco";
import { swalNaoInteresseBancario } from "../../components/notify/saidaDadosBancarios";
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";

const StepContratacao = () => {

  //DADOS PESSOAIS

  // Nome da Mãe
  const [nome_mae, setNomeMae] = useState('');
  // Nome do Pai
  const [nome_pai, setNomePai] = useState('');
  // Sexo
  const [sexo, setSexo] = useState('')
  // RG
  const [rg, setRg] = useState('');
  // Data Emissão
  const [data_emissao, setDataEmissao] = useState('');
  // Orgão Emissor
  const [orgao_emissor, setOrgaoEmissoe] = useState('');
  //Data Nascimento
  const [dataNascimento, setDataNascimento] = useState('');

  //DADOS ENDEREÇO

  // Endereço
  const [endereco, setEndereco] = useState('');
  // Numero do endereco
  const [numero_endereco, setNumeroEndereco] = useState('');
  // Bairro
  const [bairro, setBairro] = useState('');
  // Cep
  const [cep, setCep] = useState('');
  // Cidade
  const [cidade, setCidade] = useState('');
  // UF
  const [uf, setUf] = useState('');

  //DADOS BANCÁRIOS
  // Banco
  const [banco, setBanco] = useState('');
  // Nmero Agencia
  const [agencia, setAgencia] = useState('');
  // Numero da conta
  const [numero_conta, setNumeroConta] = useState('');
  // Digito da conta
  const [digito_conta, setDigitoConta] = useState('');
  // Tipo da conta
  const [tipo_conta, setTipoConta] = useState('');

  //LOADING
  const [loading, setLoading] = useState(false);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  // Consulta dos Dados do Cliente
  useEffect(() => {

    // Token
    const token = sessionStorage.getItem('identify');

    var config = {
      method: 'GET',
      url: process.env.REACT_APP_URL_API + `/cliente/consulta`,
      data:'',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    axios(config)
      .then(function (response) {

        // Desfragmentação do response
        const {
          data
        } = response;

        // Cliente localizado
        if (data.consulta) {

          // Notificação Inicial
          swalNotificacaoInicial()

          // Sexo
          const sexo = data.sexo ? data.sexo : '';
          setSexo(sexo);
          // Data de nascimento
          const dataNascimento = data.data_nascimento ? data.data_nascimento : '';
          setDataNascimento(DataEnMask(dataNascimento));
          // Rg
          const rg = data.rg ? data.rg : '';
          setRg(rg);
          // Orgão Emissor
          const orgaoEmissor = data.orgaoEmissor ? data.orgaoEmissor : '';
          setOrgaoEmissoe(orgaoEmissor);
          // Nome da Mãe
          const nomeMae = data.nome_mae ? data.nome_mae : '';
          setNomeMae(nomeMae);
          // Nome do Pai
          const nomePai = data.nome_pai ? data.nome_pai : '';
          setNomePai(nomePai);
          // Data emissão
          const dataEmissao = data.data_emissao ? data.data_emissao : '';
          setDataEmissao(DataEnMask(dataEmissao));
          // Dados de residencia
          const dados_endereco = data.dados_endereco ? data.dados_endereco : null;
          // Endereco
          const endereco = dados_endereco.endereco ? dados_endereco.endereco : '';
          setEndereco(endereco);
          // Numero
          const numero = dados_endereco.numero ? dados_endereco.numero : '';
          setNumeroEndereco(numero);
          // Bairro
          const bairro = dados_endereco.bairro ? dados_endereco.bairro : '';
          setBairro(bairro);
          // Cep
          const cep = dados_endereco.cep ? dados_endereco.cep : '';
          setCep(CepMask(cep));
          // Cidade
          const cidade = dados_endereco.cidade ? dados_endereco.cidade : '';
          setCidade(cidade);
          // UF
          const uf = dados_endereco.uf ? dados_endereco.uf : '';
          setUf(uf);

        }

        // Stop Loading
        setLoading(false);

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

          // Desfragmentação do erro
          const {
            status
          } = error.response;

          // Não autorizado
          if(status == 401){
            // Swal de notificação
            AutenticacaoInvalida();
          }
          // Erro generico
          else {
            Toast.fire({
              icon: 'warning',
              title: 'Ops, tivemos um problema!'
            });

            // window.location = "/";

          }
            
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });

          // window.location = "/";
        }

      });
  }, []);

  // Dados de retorno da companhia de luz
  const [dataConvenios, setDataConvenios] = useState([])
  const [nomeConvenios, setNomeConvenios] = useState('')

  //VERIFICAR TIPO DA SIMULAÇÃO
  const obj_simulacao = JSON.parse(sessionStorage.getItem('obj_simulacao'));
  var tipoSimulacao = obj_simulacao && obj_simulacao.tipo_simulacao ? obj_simulacao.tipo_simulacao : null;
  const [tipo_simulacao, setTipoSimulacao] = useState(tipoSimulacao ? tipoSimulacao : null);
  // Obj de contratação
  const obj_contratacao = JSON.parse(sessionStorage.getItem('obj_contratacao'));
  // Dados da Crefaz
  const crefaz = obj_contratacao && obj_contratacao.crefaz ? obj_contratacao.crefaz : {};
  // Dados de convenio Crefaz
  const dados_convenio = crefaz && crefaz.dados_convenio ? crefaz.dados_convenio : '';
  // Nome do convenio
  const nome_convenio = crefaz && crefaz.nome_convenio ? crefaz.nome_convenio : '';
  // Atualização do estado dos dados de retorno crefaz
  useEffect(() => {
    tipo_simulacao == "energia" ? setDataConvenios(dados_convenio) : null;
    tipo_simulacao == "energia" ? setNomeConvenios(nome_convenio) : null;
  },[]);

  // Dados da companhia de luz (dinamicos)
  const [infos, setInfos] = useState('')
  const dados_crefaz = { nome_convenio: nomeConvenios, dados_convenio: infos }
  function handleDinamicsInfo(index, event) {
      const { value } = event.target;
      setDataConvenios(prevInputs => {
          const valorInput = [...prevInputs];
          valorInput[index].valor = value;
          setInfos(valorInput)
          return valorInput;
      });

  }

  //ESTADO DO STEP
  const [step, setStep] = tipo_simulacao == "energia" ? useState(0) : useState(1);

  //PRIMEIRO NOME
  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  //OBJETO ORGAO EMISSOR
  const objEmissor = OrgaoEmissor();

  // OBJETO BANCOS
  const bancos = Bancos();

  //OBJETO GENEROS
  const genero =
    [
      {
        'value': '',
        'label': 'Selecione seu gênero'
      },
      {
        'value': 'M',
        'label': 'Masculino'
      },
      {
        'value': 'F',
        'label': 'Feminino'
      }
    ]

  //OBJETO TIPO CONTA
  const tipoDaConta = [
    {
      "value": "",
      "label": "Selecione o tipo..."
    },
    {
      "value": "CORRENTE",
      "label": "Corrente"
    },
    {
      "value": "POUPANCA",
      "label": "Poupança"
    }
  ]

  //DECLARAÇÃO DO ALERT TOAST
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // SWAL DE CONFIRMAÇÃO PARA CONTRATAÇÃO
  async function confirmacaoContratacao() {

    // Trackview
    // ReactPixel.trackCustom('ConfirmacaoDadosFgts');

    const obj_contratacao = JSON.parse(sessionStorage.getItem('obj_contratacao'));

    const valor_liquido = obj_contratacao.valor_contrato ? obj_contratacao.valor_contrato : null;
    const taxa = obj_contratacao.taxa_juros ? obj_contratacao.taxa_juros : null;
    const prazo = obj_contratacao.prazo ? obj_contratacao.prazo : null;
    const nome_banco = obj_contratacao.nome_fantasia ? obj_contratacao.nome_fantasia : null;

    const html = `<ul class="listview flush transparent simple-listview no-space mt-3">
                      <li>
                          <strong>Banco</strong>
                          <span class="text-dark"><strong>${nome_banco}</strong></span>
                      </li>
                      <li>
                          <strong>Valor liberado</strong>
                          <span>R$ ${valor_liquido}</span>
                      </li>
                      <li>
                          <strong>
                          ${
                            tipo_simulacao == "fgts" ?
                            `Saques adiantados` :
                            `Prazo`
                          }
                          </strong>
                          <span>${prazo}</span>
                      </li>
                      ${
                        taxa ?
                        `<li>
                          <strong>Taxa</strong>
                          <span>${taxa} am%</span>
                        </li>`
                        : ``
                      }
                      
                  </ul>`;

    Swal.fire({
      // icon: 'info',
      title: `Confirme a contratação!`,
      html: html,
      confirmButtonColor: '#1DCC70',
      confirmButtonText: `Confirmar`,
      // allowOutsideClick: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        // Trackview
        // ReactPixel.trackCustom('ConfirmacaoContratacaoFgts');

        window.gtag('event', 'conversion', {
          'send_to': 'AW-11012337992/xg2XCOruuYAYEMjii4Mp',
          'value': valor_liquido,
          'currency': 'BRL',
          'transaction_id': ''
        });

        gerarContratacao();
      }
    });

  }

  // MODAIS DE RETORNO DA CONTRATAÇÃO REALIZADA
  async function contratacaoRealizada(info) {

    // Obj de contratação
    const obj_contratacao = JSON.parse(sessionStorage.getItem('obj_contratacao'));
    // Valor do contrato
    const valor_liquido = obj_contratacao.valor_contrato ? obj_contratacao.valor_contrato.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : null;
    
    var html = `
      <div class="justify-content-center align-items-center">
        <img class="rounded-3 col-8 mb-3" src="https://media.tenor.com/f13ZfvovUmQAAAAC/yass-hell-yeah.gif"></img>
        <p align="left">
          Agora é só assinar o seu contrato para garantir <span class="pt-verde fw-bold">${valor_liquido}</span> na sua conta!
        </p>
      </div>`;

    // Retorno de link de formalização
    if (info && info.link_formalizacao) {
      html = `
        <div class="justify-content-center align-items-center">
          <img class="rounded-3 col-8 mb-3" src="https://media.tenor.com/f13ZfvovUmQAAAAC/yass-hell-yeah.gif"></img>
          <p align="left">
            Agora é só assinar o seu contrato para garantir <span class="pt-verde fw-bold">${valor_liquido}</span> na sua conta!
          </p>
        </div>`;
    }
    // Retorno de mensagem (PRE CONTRATACAO)
    else if (info && info.pre_contratacao) {
      html = `
        <div class="justify-content-center align-items-center">
          <img class="rounded-3 col-8 mb-3" src="https://media.tenor.com/f13ZfvovUmQAAAAC/yass-hell-yeah.gif"></img>
          <p align="left">
            Sua contratação foi realizada, o mais breve vamos te mandar uma mensagem no telefone informado, para finalizarmos a sua contratação.
          </p>
        </div>`;
    }
    // Retorno de mensagem (Sem link de formalização)
    else if (info && info.sem_link) {
      html = `
        <div class="justify-content-center align-items-center">
          <img class="rounded-3 col-8 mb-3" src="https://media.tenor.com/f13ZfvovUmQAAAAC/yass-hell-yeah.gif"></img>
          <p align="left">
            Sua contratação foi realizada, o mais breve vamos te mandar uma mensagem no telefone informado, para finalizarmos a sua contratação.
          </p>
        </div>`;
    }

    // Texto do botão
    const textButton = info.link_formalizacao ? 'Clique aqui para assinar!' : 'Ok';

    Swal.fire({
      html: html,
      confirmButtonColor: '#1DCC70',
      confirmButtonText: textButton,
      allowOutsideClick: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        if (info && info.link_formalizacao) {
          window.open(info.link_formalizacao, '_blank');
          window.location = '/contratos';
        }
        else {
          window.location = '/contratos';
        }

      }
    });

  }

  // GERAR CONTRATAÇÃO
  async function gerarContratacao() {

    // Inicia Loading
    setLoading(true);

    // Dados da simulação
    const dados_simulacao = sessionStorage.getItem('obj_contratacao') ? JSON.parse(sessionStorage.getItem('obj_contratacao')) : null;
    // Dadas da simulação selecionada
    const obj_simulacao = JSON.parse(sessionStorage.getItem('obj_simulacao'));
    // Token
    const token = sessionStorage.getItem('identify');
    // UUID Simulação
    const uuid_simulacao = dados_simulacao && dados_simulacao.uuid_simulacao ? dados_simulacao.uuid_simulacao : null;
    // ID da Simulação (simulação selecionada)
    const id_simulacao = dados_simulacao && dados_simulacao.id_simulacao ? dados_simulacao.id_simulacao : null;
    // Tipo da simulação
    const tipo_simulacao = obj_simulacao && obj_simulacao.tipo_simulacao ? obj_simulacao.tipo_simulacao : null;

    // CONSULTAR IP
    const consulta_ip = await ConsultaIp();
    // ENDEREÇO DE IP
    const ip = consulta_ip && consulta_ip.ip ? consulta_ip.ip : null;

    // Obj de dados do cliente
    const dados_cliente = {
      'sexo': sexo,
      'data_nascimento': dataNascimento,
      'rg': rg,
      'data_expedicao': data_emissao,
      'orgao_emissor': orgao_emissor,
      'nome_mae': nome_mae,
      'nome_pai': nome_pai,
      'endereco': endereco,
      'numero': numero_endereco,
      'bairro': bairro,
      'cep': cep,
      'cidade': cidade,
      'estado': uf,
      'banco': banco,
      'agencia': agencia,
      'conta': numero_conta,
      'digito_conta': digito_conta,
      'tipo_conta': tipo_conta
    };

    // Adição de chave Crefaz (tipo energia)
    tipo_simulacao == "energia" ? dados_cliente.crefaz = dados_crefaz : null;

    const obj_contratacao = {
      'dados':dados_cliente,
      'uuid_simulacao':uuid_simulacao,
      'id_simulacao':id_simulacao,
      'ip': ip
    };

    // Dados da simulação
    if (dados_simulacao) {

      var config = {
        method: 'POST',
        url: process.env.REACT_APP_URL_API + `/contratacao`,
        data: obj_contratacao,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      await axios(config)
        .then(async function (response) {

          // Para Loading
          setLoading(false);

          // Desfragmentação dos dados
          const {
            data
          } = response;

          // Proposta gerada
          if (data && data.contratacao) {
            contratacaoRealizada(data);
          }
          // Não gerou proposta
          else {

            Swal.fire({
              icon: 'warning',
              title: `${primeiroNome}`,
              text: 'Desculpe tivemos um problema para gerar o seu contrato, o mais breve possível um de nossos atendentes entrará em contato, por isso pedimos que fique atento ao telefone informado.',
              confirmButtonColor: '#ffc107',
              confirmButtonText: 'Ok',
              allowOutsideClick: false
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location = '/';
              }
            });

          }

        })
        .catch(function (error) {

          // Stop Loading
          setLoading(false);

          if (error.response) {

              // Desfragmentação do erro
              const {
                status
              } = error.response;
    
              // Não autorizado
              if(status == 401){
                // Swal de notificação
                AutenticacaoInvalida();
              }
              // Erro generico
              else {
                Swal.fire({
                  icon: 'warning',
                  title: `${primeiroNome}`,
                  text: 'Poxa, não estamos conseguindo finalizar a sua contratação isso é devido a uma instabilidade com o banco. Estamos aguardando o retorno deles para que tudo seja resolvido. , o mais breve possível um de nossos atendentes entrará em contato, por isso pedimos que fique atento ao telefone informado.',
                  confirmButtonColor: '#ffc107',
                  confirmButtonText: 'Ok',
                  showDenyButton: true,
                  denyButtonText: 'WhatsApp',
                  denyButtonColor: '#28a645',
                  allowOutsideClick: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    window.location = '/';
                  }
                  else if (result.isDenied) {
                    window.location.href = 'https://api.whatsapp.com/send?phone=551433138461'
                  }
                });
              }
              
          }
          // Erro generico
          else {
            Swal.fire({
              icon: 'warning',
              title: `${primeiroNome}`,
              text: 'Poxa, não estamos conseguindo finalizar a sua contratação isso é devido a uma instabilidade com o banco. Estamos aguardando o retorno deles para que tudo seja resolvido. , o mais breve possível um de nossos atendentes entrará em contato, por isso pedimos que fique atento ao telefone informado.',
              confirmButtonColor: '#ffc107',
              confirmButtonText: 'Ok',
              showDenyButton: true,
              denyButtonText: 'WhatsApp',
              denyButtonColor: '#28a645',
              allowOutsideClick: false
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location = '/';
              }
              else if (result.isDenied) {
                window.location.href = 'https://api.whatsapp.com/send?phone=551433138461'
              }
            });
          }

        });
    }

  }

  // FUNÇÃO PARA PREENCHER O ENDEREÇO
  async function ConsultaCep(cep) {

    setCep(CepMask(cep))
    const cep_consulta = cep.replace(/\D/g, "")

    if (cep_consulta.length == 8) {

      const cepJson = await ConsultarCep(cep);

      const data = cepJson.data ? cepJson.data : null;
      const status = cepJson.httpcode ? cepJson.httpcode : null;

      if (data != null && status == 200) {

        var bairro = data.bairro
        var cidade = data.localidade
        var estado = data.uf
        var rua = data.logradouro

        setEndereco(rua)
        setBairro(bairro)
        setCidade(cidade)
        setUf(estado)
      }

      else {
        Toast.fire({
          icon: 'error',
          title: 'CEP não encontrado !!!'
        });
      }

    }

  }

  //FUNÇÃO PARA MUDAR STEP E VERIFICAR SE OS CAMPOS FORAM PREENCHIDOS
  function changeStep() {

    if(step == 0){

      // Contador do convenio
      var contador_convenio = 0;

      for (var i = 0; i <= dataConvenios.length; i++) {
        // Index
        var index = dataConvenios[i];

        index && index.valor ? contador_convenio += 1 : null;
      };

      // Dados de convenio
      if (tipo_simulacao == 'energia' && contador_convenio < dataConvenios.length) {
        Toast.fire({
          icon: 'info',
          title: 'Preencha todos os campos!'
        });
      }
      // Segue para proximo step
      else {
        setStep(1)
      }

    }

    if (step == 1) {

      var nome_mae_completo = nome_mae.split(' ');

      if (!nome_mae || nome_mae_completo.length<2) {
        Toast.fire({
          icon: 'info',
          title: 'Preencha o nome completo da mãe!'
        });
      }
      else if (!rg) {
        Toast.fire({
          icon: 'info',
          title: 'Preencha o RG!'
        });
      }
      else if (!orgao_emissor) {
        Toast.fire({
          icon: 'info',
          title: 'Selecione o orgão emissor!'
        });
      }
      else if (!dataNascimento) {
        Toast.fire({
          icon: 'info',
          title: 'Preencha a data de nascimento!'
        });
      }
      else if (!data_emissao) {
        Toast.fire({
          icon: 'info',
          title: 'Preencha a data de expedição!'
        });
      }
      else if (!sexo) {
        Toast.fire({
          icon: 'info',
          title: 'Selecione o gênero!'
        });
      }
      else {
        setStep(2)
      }
    }

    if (step == 2) {
      if (!cep) {

        Toast.fire({
          icon: 'info',
          title: 'Preencha o CEP!'
        });

      }
      else if (!numero_endereco) {

        Toast.fire({
          icon: 'info',
          title: 'Preencha o número do endereço!'
        });

      }
      else if (!endereco) {

        Toast.fire({
          icon: 'info',
          title: 'Preencha a rua!'
        });

      }
      else if (!bairro) {

        Toast.fire({
          icon: 'info',
          title: 'Preencha o bairro!'
        });

      }
      else if (!cidade) {

        Toast.fire({
          icon: 'info',
          title: 'Preencha a cidade!'
        });

      }
      else if (!uf) {

        Toast.fire({
          icon: 'info',
          title: 'Preencha a UF!'
        });

      }
      else {
        setStep(3)
      }
    }

    if (step == 3) {
      if (!banco) {

        Toast.fire({
          icon: 'info',
          title: 'Selecione o Banco'
        });

      }
      else if (!agencia) {

        Toast.fire({
          icon: 'info',
          title: 'Informe a agência'
        });

      }
      else if (!tipo_conta) {
        Toast.fire({
          icon: 'info',
          title: 'Selecione o tipo da Conta!'
        });
      }
      else if (!numero_conta) {

        Toast.fire({
          icon: 'info',
          title: 'Informe a conta para pagamento!'
        });

      }
      else {
        confirmacaoContratacao();
      }

    }

  }

  //FUNÇÃO PARA MENSAGEM PERSONALIZADA AO ABANDONAR O STEP
  async function semInteresse() {

    if (tipo_simulacao === "inss") {
      // Track Não quero Dinhero INSS
      ReactPixel.trackCustom('NaoQueroDinheiroInss');
    }
    else if (tipo_simulacao === "auxilio") {
      // Track Não quero Dinhero INSS
      ReactPixel.trackCustom('NaoQueroDinheiroAuxilioBrasil');
    }
    // Tipo Energia
    else if(tipo_simulacao === "energia"){
      ReactPixel.trackCustom('NaoQueroDinheiroEnergia');
    }
    else {
      // Track Não quero Dinhero FGTS
      ReactPixel.trackCustom('NaoQueroDinheiroFgts');
    }

    // Desistiu etapa dados de energia
    if (step == 0) {
      ReactPixel.trackCustom('desistiuDadosEnergia');
      swalNaoInteresseDadosPessoais({
        'nome': primeiroNome ? primeiroNome : ''
      })
    }
    // Desistiu etapa dados pessoais
    if (step == 1) {
      ReactPixel.trackCustom('desistiuDadosPessoais');
      swalNaoInteresseDadosPessoais({
        'nome': primeiroNome ? primeiroNome : ''
      })
    }
    // Desistiu etapa dados de residencia
    if (step == 2) {
      ReactPixel.trackCustom('desistiuDadosEndereco');
      swalNaoInteresseEndereco(
        {
          'nome': primeiroNome ? primeiroNome : '',
          'saldo': obj_simulacao.simulacoes[0].valor_contrato ? obj_simulacao.simulacoes[0].valor_contrato : ''
        });
    }
    // Desistiu etapa dados bancarios
    if (step == 3) {
      ReactPixel.trackCustom('desistiuDadosBancarios');
      swalNaoInteresseBancario({
        'nome': primeiroNome ? primeiroNome : ''
      })
    }

  }


  return (
    <>{
      loading
        ?
        elementLoading :
        <>
          <HelmetProvider>
            <Helmet>
              <title>Pega Troco | Contratação</title>
            </Helmet>
          </HelmetProvider>
          <div className="appHeader">
            <div className="left">
              <a href="/" className="headerButton goBack">
                <ion-icon name="chevron-back-outline"></ion-icon>
              </a>
            </div>
            <div className="pageTitle">
              Contratação
            </div>
            <div className="right">
              <button type="button" className="btn-close" onClick={semInteresse}></button>
            </div>
          </div>
          <div id="appCapsule">
            <div className="section container mb-2">

              <div className="row justify-content-center mt-5">

                <div className="card col-lg-7">
                  <div className="card-body pb-1">
                    <form>

                      {
                        step === 0 &&
                        <>
                          <div className="d-flex flex-row gap-2 justify-content-center">
                            <FcNews size={25} />
                            <p className="fw-bold text-center text-dark">Informações da Conta de Luz</p>
                          </div>
                          <p className="text-center text-dark">Passo 0 de 3</p>
                          <p className="text-center text-dark">Sua companhia de luz: <strong>{nomeConvenios}</strong></p>
                          <p className="text-center text-dark">Preencha com os dados da sua fatura de energia</p>
                          
                          <div className="row">
                            {
                              dataConvenios.map(
                                (
                                  (item, index) => (
                                    <>
                                      <div className="form-group basic col-5 px-2 mx-auto">
                                        <div className="input-wrapper">
                                          <label className="label">{item.nome}*</label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            id={item.nome}
                                            autoComplete="off"
                                            placeholder={item.nome}
                                            value={item.valor}
                                            onChange={(event) => handleDinamicsInfo(index, event)}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )
                                )
                              )
                            }
                          </div>

                          <div className="row justify-content-center">
                            <button
                              type="button"
                              className="btn btn-success btn-lg mt-5 col-5"
                              onClick={() => changeStep()}
                            >
                              Próximo
                            </button>
                          </div>
                        </>
                      }

                      {
                        step === 1 &&
                        <>
                          <div className="d-flex flex-row gap-2 justify-content-center">
                            <FcContacts size={25} />
                            <p className="fw-bold text-center text-dark">Informações Pessoais</p>
                          </div>
                          <p className="text-center text-dark">Passo 1 de 3</p>
                          <div className="row">
                            <div className="form-group basic">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="nome">Nome da Mãe*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nome_mae"
                                  autoComplete="off"
                                  placeholder="Nome da Mãe"
                                  value={nome_mae}
                                  onChange={(event) => setNomeMae(LetraMask(event.target.value))}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group basic">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Nome do Pai</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nome_pai"
                                  placeholder="Nome do Pai"
                                  value={nome_pai}
                                  onChange={(event) => setNomePai(LetraMask(event.target.value))}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row gap-lg-4 gap-xl-5">
                            <div className="form-group basic col-lg-5 col-12">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Número do RG*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="rg"
                                  placeholder="Número do RG"
                                  value={rg}
                                  onChange={(event) => setRg(event.target.value)}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-6 col-12">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="orgao_emissor">Orgão Emissor*</label>
                                <Select
                                  className="custom-select"
                                  options={objEmissor}
                                  onChange={event => setOrgaoEmissoe(event.value)}
                                  value={objEmissor.value}
                                  name="orgao_emissor"
                                  id="orgao_emissor"
                                  placeholder="Selecione o emissor" />
                              </div>
                            </div>
                          </div>

                          <div className="row gap-lg-3 gap-xl-4">
                            <div className="form-group basic col-lg-3 col-12">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Data de Nascimento*</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="dt-nasicmento"
                                  placeholder="00/00/0000"
                                  value={dataNascimento}
                                  onChange={(event) => setDataNascimento(DataMask(event.target.value))}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-3 col-12">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-rg">Data de Expedição RG*</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="dt-rg"
                                  placeholder="Expedição do RG"
                                  value={data_emissao}
                                  onChange={(event) => setDataEmissao(DataMask(event.target.value))}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-5 col-12">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Gênero*</label>
                                <Select
                                  className="custom-select"
                                  options={genero}
                                  onChange={event => setSexo(event.value)}
                                  value={genero.value}
                                  name="orgao_emissor"
                                  id="orgao_emissor"
                                  placeholder="Selecione o gênero" />
                              </div>
                            </div>
                          </div>

                          <div className="row justify-content-center gap-4 gap-lg-5">
                            {
                              tipo_simulacao == "energia" ?
                              <button
                                type="button"
                                className="btn btn-secondary btn-lg mt-5 col-5"
                                onClick={() => setStep(step - 1)}>
                                Voltar
                              </button>
                              :
                              <></>
                            }
                            <button
                              type="button"
                              className="btn btn-success btn-lg mt-5 col-5"
                              onClick={() => changeStep()}
                            >
                              Próximo
                            </button>
                          </div>
                        </>
                      }

                      {
                        step === 2 &&
                        <>
                          <div className="d-flex flex-row gap-2 justify-content-center">
                            <FcHome size={25} />
                            <p className="fw-bold text-center text-dark">Informações Residenciais</p>
                          </div>
                          <p className="text-center text-dark">Passo 2 de 3</p>
                          <div className="row gap-xl-5 gap-lg-4">
                            <div className="form-group basic col-lg-6">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">CEP*</label>
                                <input
                                  type="tel"
                                  className="form-control"
                                  id="cep"
                                  placeholder="Informe o CEP"
                                  onChange={event => ConsultaCep(event.target.value)}
                                  value={cep}
                                  maxLength={9}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-5">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Número da residência*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="numero-residencia"
                                  placeholder="Informe o número"
                                  value={numero_endereco}
                                  onChange={(event) => setNumeroEndereco(event.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row gap-xl-5 gap-lg-4">
                            <div className="form-group basic col-lg-6">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Rua da residência*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="endereco"
                                  placeholder="Informe a rua"
                                  value={endereco}
                                  onChange={(event) => setEndereco(event.target.value)}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-5">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Bairro*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="bairro"
                                  placeholder="Informe o bairro"
                                  value={bairro}
                                  onChange={(event) => setBairro(event.target.value)}
                                />
                              </div>
                            </div>

                          </div>


                          <div className="row gap-xl-5 gap-lg-4">
                            <div className="form-group basic col-lg-6">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Cidade*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cidade"
                                  placeholder="Informe a cidade"
                                  value={cidade}
                                  onChange={(event) => setCidade(event.target.value)}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-5">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">UF do estado*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="dt-nasicmento"
                                  placeholder="Informe a UF"
                                  value={uf}
                                  onChange={(event) => setUf(LetraMask(event.target.value))}
                                  maxLength="2"
                                />
                              </div>
                            </div>

                          </div>

                          <div className="row justify-content-center gap-4 gap-lg-5">
                            <button
                              type="button"
                              className="btn btn-secondary btn-lg mt-5 col-5"
                              onClick={() => setStep(step - 1)}>
                              Voltar
                            </button>
                            <button
                              type="button"
                              className="btn btn-success btn-lg mt-5 col-5"
                              onClick={() => changeStep()}>
                              Próximo
                            </button>
                          </div>
                        </>
                      }

                      {
                        step === 3 &&
                        <>
                          <div className="d-flex flex-row gap-2 justify-content-center">
                            <FcLibrary size={25} />
                            <p className="fw-bold text-center text-dark">Informações para pagamento</p>
                          </div>
                          <p className="text-center text-dark">Passo 3 de 3</p>
                          <div className="row">
                            <div className="form-group basic">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="banco">Selecione o banco*</label>
                                <Select
                                  className="custom-select"
                                  options={bancos}
                                  onChange={event => setBanco(event.value)}
                                  value={bancos.value}
                                  name="banco"
                                  id="banco"
                                  placeholder="Selecione um banco" />
                              </div>
                            </div>
                          </div>

                          <div className="row gap-lg-5">
                            <div className="form-group basic col-lg-5">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="agencia">Agência bancária*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="agencia"
                                  placeholder="Informe a agência"
                                  value={agencia}
                                  onChange={(event) => setAgencia(NumeroMask(event.target.value))}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-6">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Tipo da conta*</label>
                                <Select
                                  className="custom-select"
                                  options={tipoDaConta}
                                  onChange={event => setTipoConta(event.value)}
                                  value={tipoDaConta.value}
                                  name="banco"
                                  id="banco"
                                  placeholder="Selecione o tipo da conta" />
                              </div>
                            </div>
                          </div>


                          <div className="row gap-lg-5">
                            <div className="form-group basic col-lg-5">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="conta">Conta bancária*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="conta"
                                  placeholder="Informe a conta para pagamento"
                                  value={numero_conta}
                                  onChange={(event) => setNumeroConta(event.target.value)}
                                />
                              </div>
                            </div>

                            <div className="form-group basic col-lg-6">
                              <div className="input-wrapper">
                                <label className="label" htmlFor="dt-nasicmento">Dígito da conta*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="dt-nasicmento"
                                  placeholder="Informe o dígito da conta"
                                  value={digito_conta}
                                  onChange={(event) => setDigitoConta(event.target.value)}
                                  maxLength="1"
                                />
                              </div>
                            </div>

                          </div>

                          <div className="row justify-content-center gap-5">
                            <button
                              type="button"
                              className="btn btn-secondary btn-lg mt-5 col-5"
                              onClick={() => setStep(step - 1)}>
                              Voltar
                            </button>
                            <button
                              type="button"
                              className="btn btn-success btn-lg mt-5 col-5"
                              onClick={() => changeStep()}>
                              Finalizar
                            </button>
                          </div>
                        </>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <MenuFooter></MenuFooter>
        </>
    }
    </>
  )
}

export default StepContratacao;