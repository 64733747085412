import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';

export const swalOperacaoIndisponivel = (nome,msg) => {

  // Pixel Facebok
  ReactPixel.trackCustom('OperacaoIndisponivel');

  // Track Google
  // var gta = window.gtag('event', 'conversion', {
  //   'send_to': 'AW-378290079/UM8ACPu6-PsCEJ__sLQB'
  // });

  // var elemetGtag = document.getElementById('gtag');
  
  // elemetGtag.append(gta);


  const mensagem = msg && msg !== null ? msg : null;

  const html = mensagem != null ? `<p align="left">${msg}</p>` : '<p align="left">Não conseguimos realizar sua simulação nesse momento pois verificamos que a operação não é permitida até a data do seu próximo aniversário.</p>';

  Swal.fire({
    // icon: 'info',
    title: `${nome}`,
    html: html,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
    allowOutsideClick: false
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
  });

}