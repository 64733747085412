export const VerificaNome = value => {

  var verifica_nome = value.split(' ');

  if (verifica_nome.length > 1) {
    return true;
  }
  else {
    return false;
  }
}