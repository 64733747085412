import Swal from 'sweetalert2';

export const swalNaoInteresse = data => {

  var nome = data && data.nome ? data.nome : '';
  var valor = data && data.saldo ? data.saldo : 'um trocado';

  Swal.fire({
    title: ``,
    denyButtonText:'Vou perder esta oferta',
    confirmButtonText: `Preciso do dinheiro`,
    confirmButtonColor:'#1DCC70',
    html: `<p align="left"><span>${nome}</span>, mais vale <span class="pt-verde fw-bold">${data && data.saldo ? valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : valor}</span> na mão que zero na conta 💸.<br>Vai desistir agora? Você está a um passo de ter este dinheiro.</p>`,
    allowOutsideClick: false,
    showDenyButton: true,
    denyButtonColor:'#6e6e6e',
    customClass:{
      denyButton:'button-size',
      confirmButton: 'button-size'
    }
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
    if(result.isDenied){
      window.location = '/';
    }
  });

}