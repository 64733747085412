import React, { useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import axios from "axios";
import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';

// Imagens
import { PrimeiroNome } from '../../tools/PrimeiroNome';
import LodingIcon from '../../assets/img/loading-icon.png';

// Tools
import { cpfMask } from "../../tools/CpfMask"
import { LetraMask } from "../../tools/LetraMask";
import { ValidacaoCpf } from "../../tools/ValidacaoCpf";

// Components
import MenuFooter from "../../components/menu-footer";
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";

const SimulacaoInss = () => {

  // Trackview
  ReactPixel.trackCustom('ClicouInss');

  // DADOS SIMULACAO
  const [cpf, setCPF] = useState('');
  const [nome, setNome] = useState('');

  const [step, setStep] = useState(1);

  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  const [loading, setLoading] = useState(false);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  //SIMULAÇÃO DO INSS COM REPRESENTANTE
  function simularRepresentante() {

    if (!cpf) {
      Toast.fire({
        icon: 'warning',
        title: 'Preencha o CPF do Representante!'
      });
    }
    else if (!nome) {
      Toast.fire({
        icon: 'warning',
        title: 'Preencha o Nome do Representante!'
      });

    }
    else {

      const validarCpf = cpf.replace(/\D/g, '');
      const cpfValidado = ValidacaoCpf(validarCpf);

      if (!cpfValidado) {
        Toast.fire({
          icon: 'warning',
          title: 'CPF Inválido!'
        });
      }
      else {

        const dados = {
          cpf: cpf ? cpf : null,
          nome: nome ? nome : null,
          'tipo_simulacao': 'inss-rep',
        }
        simularInss(dados)
      }

    }

  }

  // Simulação INSS
  async function simularInss(dados) {

    // Trackview
    ReactPixel.trackCustom('ClicouConsignado');

    // Inicia Loading
    setLoading(true);

    // Token
    const token = sessionStorage.getItem('identify');
    // Nome do cliente
    const nome_representante = dados.nome ? dados.nome : null;
    // CPF do representante
    const cpf_representante = dados.cpf ? dados.cpf : null;
    // Tipo da simulação
    const tipo_simulacao = dados.tipo_simulacao ? dados.tipo_simulacao : 'inss';

    var config = {
      method: 'POST',
      url: process.env.REACT_APP_URL_API + `/simulacao`,
      data: {
        'cpf_representante': nome_representante,
        'nome_representante': cpf_representante,
        'tipo_simulacao': tipo_simulacao
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(async function (response) {

        // Para Loading
        setLoading(false);

        // Desfragmentação do response
        const {
          data
        } = response;

        // Simulação gerada
        if (data && data.uuid_simulacao) {

          // Registrando as simulações na session storage
          sessionStorage.setItem('obj_simulacao', JSON.stringify(data));

          // Retorno das simulações
          const simulacoes = data.simulacoes ? data.simulacoes : null;
          const uuid = data.uuid_simulacao;

          // Redirecionamento
          window.location = `/contratacao/${uuid}`;

        }
        // Aprovado (mas não segue para autocontratação)
        else if (data && data.status === true) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, recebemos a sua solicitação.<br><br> Em breve um de nossos especialistas entrará em contato para finalizar o seu atendimento.</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `OK`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = '/';
            }
          });
        }
        // Reprovado (ENQUADROU FACTA FÁCIL)
        else if (data && data.facta_facil) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, temos uma proposta de crédito com débito em conta para você</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Falar com atendente`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = 'https://api.whatsapp.com/send?phone=551433138461';
            }
          });

        }
        // Blacklist
        else if(data && data.blacklist){
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, Acabei de verificar que você fez uma simulação recentemente conosco, já estou te encaminhando para nosso atendente continuar o seu atendimento. 😁</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Falar com atendente`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = 'https://api.whatsapp.com/send?phone=551433138461';
            }
          });
        }
        // Reprovado (Não enquadrou/falta de saldo)
        else if (data && data.status === false) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, não conseguimos realizar sua simulação, um dos motivos para que isso tenha ocorrido, é de que no momento você não possua o saldo mínimo necessário para a contratação. <br><br>Mas fique tranquilo, que estamos aperfeiçoando nossos métodos de consulta para que possamos atende-lo, e por isso pedimos que realize uma solicitação novamente em breve.</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `OK`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = '/';
            }
          });

        }
        // Proposta em andamento
        else if (data && data.andamento) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, você já possuí uma proposta em andamento e pode acompanhar suas atualizações na aba  CONTRATOS.</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Ver contrato`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = '/contratos';
            }
          });

        }
        // Não Localizado
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Tivemos um problema para realizar a simulação!'
          });
        }

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

            // Desfragmentação do erro
            const {
              status
            } = error.response;
  
            // Não autorizado
            if(status == 401){
              // Swal de notificação
              AutenticacaoInvalida();
            }
            // Erro generico
            else {
              Toast.fire({
                icon: 'warning',
                title: 'Ops, tivemos um problema!'
              });
            }
            
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'error',
            title: 'Simulação temporariamente indisponível!'
          });
        }

      });
  }


  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <HelmetProvider>
              <Helmet>
                <title>Pega Troco | Simulação Empréstimo Consignado</title>
              </Helmet>
            </HelmetProvider>
            <div className="appHeader">
              <div className="left">
                <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
              </div>
              <div className="pageTitle">
                Empréstimo Consignado do INSS
              </div>
            </div>
            <div id="appCapsule">
              <div className="section mb-2">
                <div className="row mt-2 justify-content-center">
                  <div className="col-md-7 align-self-center">
                    {
                      step == 1 &&
                      <>
                        <div className="bill-box">
                          <div className="price">Para realizarmos a sua simulação, precisamos saber:</div>
                          <div className="row">
                            <p className="fs-5 text-dark">Você é <span className="pt-azul fw-bold">Títular de um benefício do INSS</span>, ou você é <span className="pt-azul fw-bold">Representante Legal</span> de um beneficiário do INSS?</p>
                          </div>
                          <div className="row mt-5 pt-5 gap-2 mt-emprestimo-inss">
                            <div className="col-12 col-sm-12 col-md-12 col-lg">
                              <button className="btn btn-primary btn-lg" onClick={simularInss}>
                                Sou Títular do Benefício
                              </button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg">
                              <button className="btn btn-primary btn-lg" onClick={() => setStep(step + 1)}>
                                Sou Representante Legal do Beneficiário
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    }

                    {
                      step == 2 &&
                      <>
                        <>
                          <div className="bill-box">
                            <div className="price">Para realizarmos a sua simulação, vamos precisar das informações abaixo:</div>
                            <div className="row">
                              <p className="fw-bold text-dark fs-6">*As informações preenchidas abaixo devem ser do Representante Legal*</p>

                              <div className="form-group basic mt-3 mt-emprestimo-inss">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="nome">Nome do Representante Legal</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="nome"
                                    autoComplete="off"
                                    placeholder="Preencha com o Nome do Representante Legal"
                                    required
                                    onChange={event => setNome(LetraMask(event.target.value))}
                                    value={nome}
                                  />
                                </div>
                              </div>

                              <div className="form-group basic mt-3">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="CPF">CPF do Representante Legal</label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="CPF"
                                    autoComplete="off"
                                    placeholder="Preencha com o CPF do Representante Legal"
                                    required
                                    onChange={event => setCPF(cpfMask(event.target.value))}
                                    value={cpf}
                                    maxLength="15"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-5">
                              <div className="col">
                                <button className="btn btn-primary btn-lg" onClick={() => simularRepresentante()}>
                                  Simular
                                </button>
                              </div>
                            </div>

                          </div>
                        </>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
            <MenuFooter></MenuFooter>
          </>
      }
    </>
  );
}

export default SimulacaoInss;