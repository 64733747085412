import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../../assets/css/style.css';
import Swal from 'sweetalert2';

import LodingIcon from '../../assets/img/loading-icon.png';
import Logo from '../../assets/img/pegatroco.png';

import { auth } from '../../services/firebase';

import ReactPixel from 'react-facebook-pixel';

const EsqueceuSenha = () => {

  // Trackview
  ReactPixel.trackCustom('EsqueceuSenha');

  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  async function RedefinicaoSenha(event){

    event.preventDefault();

    if(email){

      await auth.sendPasswordResetEmail(email)
      .then((result) => {

        Toast.fire({
          icon: 'success',
          title: 'E-mail de redefinição enviado!'
        });
        
      })
      .catch((error) => {

        if(error && error.code && error.code === "auth/user-not-found"){
          Toast.fire({
            icon: 'warning',
            title: 'E-mail não localizado!'
          });
        }
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });
  
        }
        

      });
    }
    else {
      Toast.fire({
        icon: 'warning',
        title: 'As senhas são diferentes!'
      });
    }


  }

  const elementLoading = <div id="loader">
                          <img src={LodingIcon} alt="icon" className="loading-icon"/>
                        </div>;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  
  return (
    <>
      {
        loading 
        ? 
        elementLoading :
        <>
          <div id="appCapsule">
            <HelmetProvider>
              <Helmet>
                <title>Pegatroco | Esqueceu a senha</title>
              </Helmet>
            </HelmetProvider>
            <div className="section mt-2 text-center">
                <img src={Logo} alt="Logo" className="img-fluid mb-2" width="500"></img>
                <h4>Redefina sua senha, para acessar!</h4>
            </div>
            <div className="section mb-5 p-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-5 align-self-cente">
                  <form method="POST" onSubmit={RedefinicaoSenha}>
                      <div className="card">
                          <div className="card-body pb-1">
                              <div className="form-group basic">
                                  <div className="input-wrapper">
                                      <label className="label" htmlFor="email1">E-mail</label>
                                      <input 
                                        type="email"
                                        className="form-control"
                                        id="email1"
                                        placeholder="Seu e-mail"
                                        required
                                        onChange={event => setEmail(event.target.value)}
                                        value={email}
                                      />
                                  </div>
                              </div>

                              <button type="submit" className="btn btn-primary btn-block btn-lg mt-5">Redefinir Senha</button>

                          </div>
                      </div>


                      <div className="form-links mt-2">
                          <div>
                              <a href="/login">Lembrou sua senha!</a>
                          </div>
                      </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default EsqueceuSenha;