import axios from "axios";

const verificaCodigoWhats = async (obj, res) => {

    var config = {
        method: 'POST',
        url: process.env.REACT_APP_URL_API + '/valida-codigo',
        data: obj
    }
    const retorno_contrato = await axios(config)

    .then(function (response) {

        // Desfragmentação do data
        const {
            data
        } = response;

        return data;

    })
    .catch(function (error) {
        if (error.response) {
            return { 
                httpcode: error.response.status,
                valido: false,
                response: 'Erro ao realizar válidação do código!'
            };
        } else {
            return { 
                httpcode: 500,
                valido: false,
                response: 'Erro ao realizar válidação do código!'
            };
        }
    });

    return retorno_contrato;
}

export default verificaCodigoWhats;