// import Menu from "../menu";

import Logo from '../../assets/img/pegatrocobranco.png';

// import Avatar from '../../assets/img/sample/avatar/avatar1.jpg'

const Header = () => {
  return (
    <>
      <div className="appHeader bg-primary text-light">
          {/* <div className="left">
              <a href="#" className="headerButton" data-bs-toggle="modal" data-bs-target="#sidebarPanel">
                  <ion-icon name="menu-outline"></ion-icon>
              </a>
          </div> */}
          <div className="pageTitle">
              <img src={Logo} alt="logo" className="logo"/>
          </div>
          <div className="right">
              {/* <a href="app-notifications.html" className="headerButton">
                  <ion-icon className="icon" name="notifications-outline"></ion-icon>
                  <span className="badge badge-danger">4</span>
              </a> */}
              {/* <a href="app-settings.html" className="headerButton">
                  <img src={Avatar} alt="image" className="imaged w32"/>
                  <span className="badge badge-danger">6</span>
              </a> */}
          </div>
      </div>
      {/* <Menu></Menu> */}
    </>
  );
}

export default Header;