import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import '../../assets/css/style.css';

import LodingIcon from '../../assets/img/loading-icon.png';
import Logo from '../../assets/img/pegatroco.png';

const SmsVerificacao = () => {

  const [loading, setLoading] = useState(true);

  const elementLoading = <div id="loader">
                          <img src={LodingIcon} alt="icon" className="loading-icon"/>
                        </div>;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {
        loading 
        ? 
        elementLoading :
        <>
          <div id="appCapsule">
            <HelmetProvider>
              <Helmet>
                <title>Pegatroco | SMS Verificação</title>
              </Helmet>
            </HelmetProvider>
            <div className="section mt-2 text-center">
                <img src={Logo} alt="Logo" className="img-fluid mb-2" width="500"></img>
                <h4>Digite os 4 digitos do sms de verificação!</h4>
            </div>
            <div className="section mb-5 p-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-5 align-self-cente">
                  <form>
                      <div className="card">
                          <div className="card-body pb-1">

                              <div className="form-group basic">
                                  <input type="text" className="form-control verification-input" id="smscode" placeholder="••••"
                                      maxlength="4"/>
                              </div>

                              <button type="submit" className="btn btn-primary btn-block btn-lg mt-5">Verificar</button>

                          </div>
                      </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default SmsVerificacao;