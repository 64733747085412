import React, { useEffect, useState } from "react";
import MenuFooter from "../../components/menu-footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import axios from "axios";

import LodingIcon from '../../assets/img/loading-icon.png';
import NaoLocalizado from '../../assets/img/nao-localizado.png';
import { Manutencao } from "../../components/notify/Manutencao";
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";

const Contratos = () => {

  const [contratoAndamento, setContratoAndamento] = useState('');
  const [contratoFinalizado, setContratoFinalizado] = useState('');
  const [loading, setLoading] = useState(true);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  // Consulta contratos Andamento
  useEffect(() => {

    // Token
    const token = sessionStorage.getItem('identify');

    var config = {
      method: 'GET',
      url: process.env.REACT_APP_URL_API + `/contrato/consulta?status=todos`,
      data: '',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    axios(config)
      .then(function (response) {

        // Desfragmentação do response
        const {
          data
        } = response;

        // Retorno de contratos
        if (data && data.contratos) {

          // Contratos
          const contratos = data.contratos;

          // Contratos em andamento
          const retorno_andamento = contratos.andamento ? contratos.andamento : null;
          // Contratos finalizados
          const retorno_finalizado = contratos.finalizado ? contratos.finalizado : null;

          // Adição ao estado
          retorno_andamento ? setContratoAndamento(retorno_andamento) : null;
          retorno_finalizado ? setContratoFinalizado(retorno_finalizado) : null;

        }

        setLoading(false);

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

          // Desfragmentação do erro
          const {
            status
          } = error.response;

          // Não autorizado
          if(status == 401){
            // Swal de notificação
            AutenticacaoInvalida();
          }
          
        } 

      });
  }, []);

  // Estilização IMG Não localizado
  const naolocalizadoStyle = {
    width: '65%'
  };

  var count = 1;

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <HelmetProvider>
              <Helmet>
                <title>Pega Troco | Contratos</title>
              </Helmet>
            </HelmetProvider>
            <div className="appHeader">
              <div className="left">
                <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
              </div>
              <div className="pageTitle">
                Contratos
              </div>
            </div>

            <div className="extraHeader pe-0 ps-0">
              <ul className="nav nav-tabs lined" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-bs-toggle="tab" href="#andamento" role="tab">
                    Em andamento
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-bs-toggle="tab" href="#finalizado" role="tab">
                    Finalizado
                  </a>
                </li>
              </ul>
            </div>

            <div id="appCapsule" className="extra-header-active full-height">
              <div className="section tab-content mt-2">

                {/* Tab Andamento */}
                <div className="tab-pane fade show active" id="andamento" role="tabpanel">
                  <div className="row justify-content-center">
                    <div className="col-md-7 align-self-center">

                      <React.Fragment>

                        {

                          contratoAndamento && contratoAndamento.length ? contratoAndamento.map(conteudo =>

                            <div className="transactions mt-4" key={count += 1}>
                              <a href={"/contrato-detalhado/" + conteudo.id} className="item">
                                <div className="detail">
                                  <div>
                                    <strong>
                                      {
                                        conteudo && 
                                        conteudo.contratacao && 
                                        conteudo.contratacao.nome_banco ? 
                                        conteudo.contratacao.nome_banco :
                                        null
                                      }
                                    </strong>
                                    <p>{conteudo.produto}</p>
                                  </div>
                                </div>
                                <div className="right">
                                  <div className="price text-success">
                                    {
                                      conteudo && 
                                      conteudo.contratacao && 
                                      conteudo.contratacao.valor_emprestimo ? 
                                      conteudo.contratacao.valor_emprestimo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) 
                                      : null
                                    }
                                  </div>
                                </div>
                              </a>
                            </div>

                          ) : <div className="row justify-content-center mt-4">
                            <img src={NaoLocalizado} className="img-fluid" alt="Imagem não localizado" style={naolocalizadoStyle}></img>
                            <h4 className="text-center mt-4 fs-5">Nenhum contrato localizado!</h4>
                          </div>

                        }

                      </React.Fragment>
                    </div>
                  </div>
                </div>

                <div className="tab-pane fade" id="finalizado" role="tabpanel">
                  <div className="row justify-content-center">
                    <div className="col-md-7 align-self-center">

                      <React.Fragment>

                        {

                          contratoFinalizado && contratoFinalizado.length ? contratoFinalizado.map(conteudo =>

                            <div className="transactions mt-4" key={count += 1}>
                              <a href={"/contrato-detalhado/" + conteudo.id} className="item">
                                <div className="detail">
                                  <div>
                                    <strong>
                                      {
                                        conteudo && 
                                        conteudo.contratacao && 
                                        conteudo.contratacao.nome_banco ? 
                                        conteudo.contratacao.nome_banco :
                                        null
                                      }
                                    </strong>
                                    <p>{conteudo.produto}</p>
                                  </div>
                                </div>
                                <div className="right">
                                  <div className="price text-success">
                                    {
                                      conteudo && 
                                      conteudo.contratacao && 
                                      conteudo.contratacao.valor_emprestimo ? 
                                      conteudo.contratacao.valor_emprestimo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) 
                                      : null
                                    }
                                  </div>
                                </div>
                              </a>
                            </div>

                          ) : <div className="row justify-content-center mt-4">
                            <img src={NaoLocalizado} className="img-fluid" alt="Imagem não localizado" style={naolocalizadoStyle}></img>
                            <h4 className="text-center mt-4 fs-5">Nenhum contrato localizado!</h4>
                          </div>

                        }

                      </React.Fragment>

                    </div>
                  </div>
                </div>

              </div>
            </div>
            <MenuFooter></MenuFooter>
          </>
      }
    </>
  )

}

export default Contratos;