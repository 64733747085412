import React from "react";
import { BrowserRouter, Route, Routes, Navigate, Outlet } from "react-router-dom";

import Home from './pages/home';
import Login from "./pages/login";
import Cadastre from "./pages/cadastre-se";
import CompletarCadastro from "./pages/completar-cadastro";
import EsqueceuSenha from "./pages/esqueceu-senha";
import SmsVerificacao from "./pages/sms-verificacao";
import Contratos from "./pages/contratos";
import ContratoDetalhado from "./pages/contrato-detalhado";
import Duvidas from "./pages/duvidas";
import Conta from "./pages/conta";
import Configuracoes from "./pages/configuracoes";
import RedefinirSenha from "./pages/redefinicao-senha";
import Contratacao from "./pages/contratacao";
import Autorizacao from "./pages/autorizacao";
import SimulacaoAuxilioBrasil from "./pages/simulacao-auxilio-brasil";
import NotFoud from "./pages/404";
import SimulacaoEnergia from "./pages/simulacao-energia";
import SimulacaoInss from "./pages/simulacao-inss";
import SimulacaoMargemComplementar from "./pages/simulacao-aumento-margem";

const PrivateRoute = () => (

  sessionStorage.getItem('identify') ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  )
);

const Rotas = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" exact element={<Login />} />
      <Route path="/cadastro" exact element={<Cadastre />} />
      <Route path="/completar-cadastro" exact element={<CompletarCadastro />} />
      <Route path="/esqueceu-senha" exact element={<EsqueceuSenha />} />
      <Route path="/redefinir-senha" exact element={<RedefinirSenha />} />
      <Route path="/verificacao-sms" exact element={<SmsVerificacao />} />
      <Route exact path="/" element={<PrivateRoute />}>
        <Route path="/" exact element={<Home />} />
      </Route>
      <Route exact path="/simulacao-inss" element={<PrivateRoute />}>
        <Route path="/simulacao-inss" exact element={<SimulacaoInss />} />
      </Route>
      <Route exact path="/simulacao-margem-complementar" element={<PrivateRoute />}>
        <Route path="/simulacao-margem-complementar" exact element={<SimulacaoMargemComplementar />} />
      </Route>
      <Route exact path="/simulacao-auxilio-brasil" element={<PrivateRoute />}>
        <Route path="/simulacao-auxilio-brasil" exact element={<SimulacaoAuxilioBrasil />} />
      </Route>
      <Route exact path="/simulacao-energia" element={<PrivateRoute />}>
        <Route path="/simulacao-energia" exact element={<SimulacaoEnergia />} />
      </Route>
      <Route exact path="/autorizacao" element={<PrivateRoute />}>
        <Route path="/autorizacao" exact element={<Autorizacao />} />
      </Route>

      <Route path="/contratacao/:uuid" exact element={<Contratacao />} />

      <Route exact path="/contratos" element={<PrivateRoute />}>
        <Route path="/contratos" exact element={<Contratos />} />
      </Route>
      <Route exact path="/contrato-detalhado/:id" element={<PrivateRoute />}>
        <Route path="/contrato-detalhado/:id" exact element={<ContratoDetalhado />} />
      </Route>
      <Route exact path="/duvidas" element={<PrivateRoute />}>
        <Route path="/duvidas" exact element={<Duvidas />} />
      </Route>
      <Route exact path="/conta" element={<PrivateRoute />}>
        <Route path="/conta" exact element={<Conta />} />
      </Route>
      <Route exact path="/configuracoes" element={<PrivateRoute />}>
        <Route path="/configuracoes" exact element={<Configuracoes />} />
      </Route>
      <Route path="*" element={<NotFoud />} />
    </Routes>
  </BrowserRouter>
);

export default Rotas;