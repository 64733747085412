import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';

export const swalSemSaldo = nome => {

  // Pixel Facebok
  ReactPixel.trackCustom('LeadSemSaldo');

  // // Track Google
  // var gta = window.gtag('event', 'conversion', {
  //   'send_to': 'AW-378290079/jftpCM7wi_sCEJ__sLQB'
  // });

  // var elemetGtag = document.getElementById('gtag');
  
  // elemetGtag.append(gta);

  Swal.fire({
    // icon: 'info',
    // title: `😕 ${nome}!`,
    html: `<p align="left"><strong class="fs-5">${nome}</strong>, não conseguimos realizar a sua simulação, isso pode ter ocorrido caso você não possua o saldo mínimo de contratação. Mas fique tranquilo que estamos aperfeiçoando nossos métodos de consultas para que possamos atende-lo, e você poderá realizar uma simulação novamente em breve.</p>`,
    confirmButtonColor: '#1741C4',
    confirmButtonText: `OK`,
    allowOutsideClick: false
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
  });

}