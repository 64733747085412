import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Select from 'react-select';

import axios from "axios";

import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';

// Components
import MenuFooter from "../../components/menu-footer";
import { swalErroSimulacao } from "../../components/notify/ErroSimulacao";
import { swalPropostaAndamento } from "../../components/notify/PropostaAndamento";
import { swalNotificacaoReprovado } from "../../components/notify/NotificacaoReprovado";
import { NotificacaoIndisponivel } from "../../components/notify/energia/NotificacaoIndisponivel";

// Imagens
import LodingIcon from '../../assets/img/loading-icon.png';

// Tools
import { PrimeiroNome } from '../../tools/PrimeiroNome';
import { CepMask } from "../../tools/CepMask"
import ConsultarCep from "../../helper/consultacep";

const SimulacaoEnergia = () => {

  // Trackview
  ReactPixel.trackCustom('ClicouCrefaz');

  // CEP
  const [cep, setCep] = useState('');

  //Ocupacoes
  const [ocupacoes, setOcupacoes] = useState([]);

  // Primeiro nome
  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  const [loading, setLoading] = useState(false);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // Simulação CREFAZ
  async function simularCrefaz() {

    // CEP
    if (!cep) {
      Toast.fire({
        icon: 'info',
        title: 'Preencha o CEP!'
      });
    }
    // Realiza Simulação
    else {

      // Consulta Cep
      const consulta_cep = await ConsultarCep(cep);
      
      // Dados localizados
      if(consulta_cep.data){

        // Desfragmentação do data
        const {
          data
        } = consulta_cep;

        // Obj de simulação de energia
        const obj_energia = {
          cep:cep,
          bairro: data.bairro,
          endereco: data.logradouro,
          cidade: data.localidade,
          estado: data.uf,
          id_ocupacao: 1 // Assalariado
        }

        // Realiza simulação
        handleSimulacao(obj_energia);

      }
      // Endereço não localizado
      else {
        Toast.fire({
          icon: 'info',
          title: 'CEP inválido!'
        });
      }

      
    }

    // Simulação
    function handleSimulacao(obj) {
      
      // Inicia Loading
      setLoading(true);

      // Token
      const token = sessionStorage.getItem('identify');

      var config = {
        method: 'POST',
        url: process.env.REACT_APP_URL_API + `/simulacao`,
        data: {
          crefaz: obj,
          tipo_simulacao: 'energia'
        },
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

      axios(config)
        .then(async function (response) {

          // Stop Loading
          setLoading(false);

          // Desfragmentação do response
          const {
            data
          } = response;

          // Simulação gerada
          if (data && data.uuid_simulacao) {

            // Registrando as simulações na session storage
            sessionStorage.setItem('obj_simulacao', JSON.stringify(data));

            // Retorno das simulações
            const simulacoes = data.simulacoes ? data.simulacoes : null;
            const uuid = data.uuid_simulacao;

            // Redirecionamento
            window.location = `/contratacao/${uuid}`;

          }
          // Reprovado
          else if(data && data.reprovado){
            swalNotificacaoReprovado()
          }
          // Indisponivel
          else if(data && data.indisponivel){
            NotificacaoIndisponivel();
          }
          // proposta em andamento
          else if (data && data.response.includes("prosposta em andamento")) {
            swalPropostaAndamento(primeiroNome)
          }
          // reprovado na pre analise
          else if (data && data.response.includes("pré-analise")) {
            swalErroSimulacao(primeiroNome);
          }
          // Erro
          else {
            swalErroSimulacao(primeiroNome);
          }

        })
        .catch(function (error) {
          setLoading(false);

          Toast.fire({
            icon: 'error',
            title: 'Simulação temporariamente indisponível!'
          });
        });

    }

  }

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <HelmetProvider>
              <Helmet>
                <title>Pega Troco | Simulação Crefaz</title>
              </Helmet>
            </HelmetProvider>
            <div className="appHeader">
              <div className="left">
                <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
              </div>
              <div className="pageTitle">
                Empréstimo na conta de luz!
              </div>
            </div>
            <div id="appCapsule">
              <div className="section mb-2">
                <div className="row mt-2 justify-content-center">
                  <div className="col-md-7 align-self-center">
                    <div className="bill-box">
                      <div className="price">
                        Para realizarmos a sua simulação, preencha o seu CEP:
                      </div>
                      <p className="text-danger fs-6">
                        <strong>
                          A conta de luz deve estar em seu nome!
                        </strong>
                      </p>
                      <div className="row">
                        <br></br>
                        <div className="form-group basic">
                          <div className="input-wrapper">
                            <label className="label" htmlFor="codigo-familiar">CEP</label>
                            <input
                              type="tel"
                              className="form-control"
                              id="codigo-familiar"
                              autoComplete="off"
                              placeholder="CEP da sua residência"
                              required
                              onChange={event => setCep(CepMask(event.target.value))}
                              value={cep}
                              maxLength="9"
                            />
                          </div>
                        </div>

                      </div>
                      <div className="row mt-5">
                        <div className="col">
                          <button className="btn btn-primary btn-lg" onClick={simularCrefaz}>
                            Simular
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MenuFooter></MenuFooter>
          </>
      }
    </>
  );
}

export default SimulacaoEnergia;