import Swal from 'sweetalert2';

export const swalNaoInteresseDadosPessoais = data => {

  var nome = data && data.nome ? data.nome : '';

  Swal.fire({
    title: ``,
    denyButtonText:'Vou desistir',
    confirmButtonText: `Vou Prosseguir`,
    confirmButtonColor:'#1DCC70',
    html: `<p align="left"><span class="fw-bold">${nome}</span>, sabia que leva pouco mais de <span class="fw-bold">2 minutos para finalizar</span> esta contratação? É mais rápido que fritar pastel, <span class="fw-bold">então vamos lá!</span></p>`,
    allowOutsideClick: false,
    showDenyButton: true,
    denyButtonColor:'#6e6e6e',
    customClass:{
      denyButton:'button-size',
      confirmButton: 'button-size'
    }
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
    if(result.isDenied){
      window.location = '/';
    }
  });

}