const HorarioFuncionamento = async () => {
    // Horario de atendimento Segunda a Sexta das 08:00 as 17:00
    var date = new Date();

    // Dia (Segunda a Domingo)
    var dia = date.getDay();
    // Hora
    var hora = date.getHours();

    //  Dia de funcionamento Inicio
    const dia_funcionamento_inicio = process.env.REACT_APP_DIA_FUNCIONAMENTO_INICIO;
    // Dia de funcionamento Fim
    const dia_funcionamento_fim = process.env.REACT_APP_DIA_FUNCIONAMENTO_FIM;

    // Horario de funcionamento inicio
    const horario_funcionamento_inicio = process.env.REACT_APP_HORARIO_FUNCIONAMENTO_INICIO;
    // Horario de funcionamento fim
    const horario_funcionamento_fim = process.env.REACT_APP_HORARIO_FUNCIONAMENTO_FIM;

    // Dias de trabalho
    if (dia != dia_funcionamento_inicio && dia != dia_funcionamento_fim) {

        // Horario de atendimento
        if (hora >= horario_funcionamento_inicio && hora <= horario_funcionamento_fim) {
            return {
                'trabalha': true,
                'horario': true
            };
        }
        // Fora do horario
        else {
            return {
                'trabalha': true,
                'horario': false
            };
        }

    }
    // Dias que não trabalha
    else {
        return {
            'trabalha': false,
            'horario': false
        };
    }

};
export default HorarioFuncionamento;