import axios from "axios";

const ConsultaSimulacao = async (req, res) => {

    // Token
    const token = sessionStorage.getItem('identify');

    // Desfragmentação do req
    const {
        uuid_simulacao
    } = req;

    // Filtro
    const filtro = `?uuid_simulacao=${uuid_simulacao}`

    var config = {
        method: 'GET',
        url: process.env.REACT_APP_URL_API+`/simulacao/consulta${filtro}`,
        data: '',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    const retorno_simulacao = await axios(config)

        .then(function (response) {
            
            return(response.data)
          
        })
        .catch(function (error) {

            if (error.response) {

                // Desfragmentação do erro
                const {
                  status
                } = error.response;
      
                return {
                    httpcode: status,
                    consulta: false,
                    response: 'Não foi possivel consultar simulação!'
                }
                
            }
            // Erro generico
            else {
              return {
                httpcode: 500,
                consulta: false,
                response: 'Erro ao consultar simulação!'
              }
            }
              
        })
        
    return retorno_simulacao;
}

export default ConsultaSimulacao;