import Swal from 'sweetalert2';
import ReactPixel from 'react-facebook-pixel';

export const swalSemAutorizacao = nome => {

  // Pixel Facebok
  ReactPixel.trackCustom('LeadSemAutorizacaoBanco');

  // Track Google
  // var gta = window.gtag('event', 'conversion', {
  //   'send_to': 'AW-378290079/UM8ACPu6-PsCEJ__sLQB'
  // });

  // var elemetGtag = document.getElementById('gtag');
  
  // elemetGtag.append(gta);

  var html = `<a href="https://www.youtube.com/watch?v=9gj49JRbMpY&t=3s" target="_blank">youtube.com/pegatroco</a>`

  Swal.fire({
    // icon: 'info',
    // title: `${nome}, vamos lá!`,
    html: `<p align="left"><strong class="fs-5">${nome}</strong>, tentamos realizar a sua simulação e percebemos que o banco 321 Sociedade de Crédito não foi autorizado dentro do seu App FGTS ainda, tente realizar a autorização novamente para prosseguir, caso tenha dúvidas em como realizar a autorização siga o passo a passo a seguir:</p>`+html,
    confirmButtonColor: '#28a745',
    confirmButtonText: `Realizei a autorização`,
    allowOutsideClick: false
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      Swal.close();
    }
  });

}