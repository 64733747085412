import axios from "axios";

const ConsultarCep = async (req, res) => {

    var cep_consulta = req ? req : null;

    var config = {
        method: 'get',
        url: `https://viacep.com.br/ws/${cep_consulta}/json/`
    }
    const retorno_consulta = await axios(config)

        .then(function (response) {

            const data = response.data ? response.data : null;

            const status = response.status ? response.status : null;

            if (status == 200 && !data.erro) {
                const obj_retorno = {
                    data : data,
                    httpcode: status
                }
                return obj_retorno;

            }
            else {
                return { 'httpcode': 400 };
            }
        })

        .catch(function (error) {

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                return { 'httpcode': error.response.status };
            } else {
                // Something happened in setting up the request that triggered an Error
                return { 'httpcode': 500 };
            }
        })
    return retorno_consulta;

}

export default ConsultarCep;