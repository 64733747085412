import Swal from "sweetalert2";

// Helpers
import enviarMensagem from "../../helper/digisac/enviarMensagem";
// Component
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";
// Tools
import HorarioFuncionamento from "../../tools/verificaHorarioFuncionamento";

const simulacaoSiape = async () => {

    // Session de envio de mensagem
    const envioSiape = sessionStorage.getItem('whatsapp_siape');
    // Horario de funcionamento
    const funcionamento = await HorarioFuncionamento();

    // Empresa está no horario de atendimento
    if (funcionamento && (funcionamento.trabalha && funcionamento.horario)) {
        
        // Solicitação realizada
        if (envioSiape && envioSiape == "true") {
            Swal.fire({
                icon: 'info',
                title: `Simulação solicitada`,
                text: 'Recebemos a sua solicitação de simulação, e o mais breve possível um de nossos vendedores entrarão em contato, fique atento ao seu telefone!',
                showCloseButton: true,
                allowOutsideClick: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#DC3545',
                cancelButtonText: 'Fechar aviso'
            })

            sessionStorage.setItem('whatsapp_siape', 'true');
        }
        // Solicitação não realizada
        else if (!envioSiape) {

            // Mensagem
            const mensagem = `Olá, aqui é a Ana, atendente virtual da Pegatroco. Vi que pediu uma simulação em nosso app.\n\nUma de nossas especialistas vai iniciar uma conversa aqui com você, via whatsapp, em alguns minutos.\n\nPeço que fique com o telefone em mãos para garantir agilidade do contato. Obrigado!`

            // Obj de envio de mensagem
            const obj_mensagem = {
                mensagem: mensagem
            }

            // Envio da mensagem
            const envioWhatsapp = await enviarMensagem(obj_mensagem);

            // Mensagem enviada
            if (envioWhatsapp && envioWhatsapp.enviado) {
                Swal.fire({
                    icon: 'success',
                    title: `Simulação`,
                    text: `Obrigado! Acabamos de receber o seu pedido de simulação. Uma de nossas especialistas entrará em contato 
                    com você em alguns minutos via whatsapp para informar o possível valor liberado.`,
                    confirmButtonColor: '#1741C4',
                    confirmButtonText: `Ok`,
                    allowOutsideClick: false
                }).then((result) => {
                    Swal.close();
                });

                sessionStorage.setItem('whatsapp_siape', 'true');
            }
            // Não autenticado
            else if(envioWhatsapp.httpcode && envioWhatsapp.httpcode == 401){
                // Swal de notificação
                AutenticacaoInvalida();
            }
            // Erro ao realizar envio
            else {
                Swal.fire({
                    icon: 'info',
                    text: 'Desculpe, ocorreu um erro no sistema de consulta e não conseguimos realizar sua simulação agora. Estamos redirecionando você para um atendimento via whatsapp com uma de nossas especialistas.',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    showCloseButton: true
                });
                setTimeout(() => {
                    window.location.href = `https://api.whatsapp.com/send?phone=551433138461`
                }, 4000);

                sessionStorage.setItem('whatsapp_siape', 'true');
            }
        }
        // Erro generico
        else {
            Swal.fire({
                icon: 'info',
                text: 'Desculpe, ocorreu um erro no sistema de consulta e não conseguimos realizar sua simulação agora. Estamos redirecionando você para um atendimento via whatsapp com uma de nossas especialistas.',
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true
            });
            setTimeout(() => {
                window.location.href = `https://api.whatsapp.com/send?phone=551433138461`
            }, 4000);

            sessionStorage.setItem('whatsapp_siape', 'true');
        }
    }
    // Empresa não está no horario de atendimento
    else {

        // Solicitação realizada
        if (envioSiape && envioSiape == "true") {
            Swal.fire({
                icon: 'info',
                title: `Simulação solicitada`,
                text: 'Recebemos a sua solicitação de simulação, e o mais breve possível um de nossos vendedores entrarão em contato, fique atento ao seu telefone!',
                showCloseButton: true,
                allowOutsideClick: true,
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonColor: '#DC3545',
                cancelButtonText: 'Fechar aviso'
            })

            sessionStorage.setItem('whatsapp_siape', 'true');
        }
        // Solicitação não realizada
        else if (!envioSiape) {

            // Mensagem
            const mensagem = `Olá, aqui é a Ana, atendente virtual da Pegatroco. Recebi o seu pedido de simulação em nosso app!\n\nSalve agora nosso número em sua agenda, pois logo no primeiro horário de funcionamento você receberá uma mensagem para darmos sequência à sua solicitação.\n\nAtendemos de 2ª a 6ª feira das 9h00 às 18h00.`

            // Obj de envio de mensagem
            const obj_mensagem = {
                mensagem: mensagem
            }

            // Envio da mensagem
            const envioWhatsapp = await enviarMensagem(obj_mensagem);

            // Mensagem enviada
            if (envioWhatsapp && envioWhatsapp.enviado) {
                Swal.fire({
                    icon: 'success',
                    title: `Simulação`,
                    text: `Obrigado! Acabamos de receber o seu pedido de simulação. Uma de nossas especialistas entrará em contato com você o mais breve possível, via whatsapp, em horário comercial. Nosso atendimento é de 2ª a 6ª feira, das 9h00 às 18h00.`,
                    confirmButtonColor: '#1741C4',
                    confirmButtonText: `Ok`,
                    allowOutsideClick: false
                }).then((result) => {
                    Swal.close();
                });

                sessionStorage.setItem('whatsapp_siape', 'true');
            }
            // Não autenticado
            else if(envioWhatsapp.httpcode && envioWhatsapp.httpcode == 401){
                // Swal de notificação
                AutenticacaoInvalida();
            }
            // Erro generico
            else {
                Swal.fire({
                    icon: 'info',
                    text: `Olá, aqui é a Ana, atendente virtual da Pegatroco. 
                    Percebi que infelizmente ocorreu um erro no sistema de consulta e não conseguimos realizar sua simulação na nossa plataforma. Salve agora nosso número em sua agenda, pois logo no primeiro horário de funcionamento você receberá uma mensagem para darmos sequência à sua solicitação.
                    Atendemos de 2ª a 6ª feira das 9h00 às 18h00.`,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    showCloseButton: true
                });
                setTimeout(() => {
                    window.location.href = `https://api.whatsapp.com/send?phone=551433138461`
                }, 4000);

                sessionStorage.setItem('whatsapp_siape', 'true');
            }
        }
        // Erro generico
        else {
            Swal.fire({
                icon: 'info',
                text: `Olá, aqui é a Ana, atendente virtual da Pegatroco. 
                Percebi que infelizmente ocorreu um erro no sistema de consulta e não conseguimos realizar sua simulação na nossa plataforma. Salve agora nosso número em sua agenda, pois logo no primeiro horário de funcionamento você receberá uma mensagem para darmos sequência à sua solicitação.
                Atendemos de 2ª a 6ª feira das 9h00 às 18h00.`,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true
            });
            setTimeout(() => {
                window.location.href = `https://api.whatsapp.com/send?phone=551433138461`
            }, 4000);

            sessionStorage.setItem('whatsapp_siape', 'true');
        }
    }

}
export default simulacaoSiape;