import React, { useEffect, useState } from "react";
import MenuFooter from "../../components/menu-footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import axios from "axios";

// Imagem
import LodingIcon from '../../assets/img/loading-icon.png';

// Tools
import { cpfMask } from '../../tools/CpfMask';
import { CelularMask } from '../../tools/CelularMask';
import { LetraMask } from '../../tools/LetraMask';
import { DataMask } from '../../tools/DataMask';
import { DataEnMask } from '../../tools/DataEnMask';
import { CepMask } from '../../tools/CepMask';

// Components
import { AutenticacaoInvalida } from "../../components/notify/AutenticacaoInvalida";
import Swal from 'sweetalert2';

const Conta = () => {

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cpf, setCpf] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [rg, setRg] = useState('');
  const [dataEmissao, setDataEmissao] = useState('');
  const [endereco, setEndereco] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');

  const [loading, setLoading] = useState(true);
  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // Consulta dos Dados
  useEffect(() => {

    // Token
    const token = sessionStorage.getItem('identify');

    var config = {
      method: 'GET',
      url: process.env.REACT_APP_URL_API + `/cliente/consulta`,
      data:'',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }

    axios(config)
      .then(function (response) {

        // Desfragmentação do response
        const {
          data
        } = response;

        // Cliente localizado
        if (data.consulta) {

          // Nome do Cliente
          const nome_cliente = data.nome ? data.nome : '';
          setNome(nome_cliente);
          // Email
          const email = data.email ? data.email : '';
          setEmail(email);
          // Telefone
          const telefone = data.telefone ? data.telefone : '';
          setTelefone(CelularMask(telefone));
          // CPF
          const cpf = data.cpf ? data.cpf : '';
          setCpf(cpfMask(cpf));
          // Data de nascimento
          const data_nascimento = data.data_nascimento ? data.data_nascimento : '';
          setDataNascimento(DataEnMask(data_nascimento));
          // Rg
          const rg = data.rg ? data.rg : '';
          setRg(rg);
          // Data emissão
          const data_emissao = data.data_emissao ? data.data_emissao : '';
          setDataEmissao(DataEnMask(data_emissao));
          // Dados de endereço
          const dados_endereco = data.dados_endereco;
          // Endereco
          const endereco = dados_endereco.endereco ? dados_endereco.endereco : '';
          setEndereco(endereco);
          // Numero
          const numero = dados_endereco.numero ? dados_endereco.numero : '';
          setNumero(numero);
          // Bairro
          const bairro = dados_endereco.bairro ? dados_endereco.bairro : '';
          setBairro(bairro);
          // Cep
          const cep = dados_endereco.cep ? dados_endereco.cep : '';
          setCep(CepMask(cep));
          // Cidade
          const cidade = dados_endereco.cidade ? dados_endereco.cidade : '';
          setCidade(cidade);
          // UF
          const uf = dados_endereco.uf ? dados_endereco.uf : '';
          setUf(uf);

        }

        // Stop Loading
        setLoading(false);

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

          // Desfragmentação do erro
          const {
            status
          } = error.response;

          // Não autorizado
          if(status == 401){
            // Swal de notificação
            AutenticacaoInvalida();
          }
          // Erro generico
          else {
            Toast.fire({
              icon: 'warning',
              title: 'Ops, tivemos um problema!'
            });

            window.location = "/";

          }
            
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });

          window.location = "/";
        }
        
      });
  }, []);

  // Atualização do cliente
  async function AtualizarCliente(event) {

    event.preventDefault();

    // Inicia Loading
    setLoading(true);

    // Token
    const token = sessionStorage.getItem('identify');

    const obj_atualizacao = {
      'nome': nome,
      'cpf': cpf,
      'telefone': telefone,
      'data_nascimento': dataNascimento,
      'rg': rg,
      'data_emissao': dataEmissao,
      'endereco': endereco,
      'numero': numero,
      'bairro': bairro,
      'cep': cep,
      'cidade': cidade,
      'uf': uf
    };

    var config = {
      method: 'POST',
      url: process.env.REACT_APP_URL_API + `/cliente/atualizacao`,
      data: obj_atualizacao,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    await axios(config)
      .then(function (response) {

        // Para Loading
        setLoading(false);

        // Desfragmentação do response
        const {
          data
        } = response;

        // Sucesso
        if (data && data.cadastro) {
          Toast.fire({
            icon: 'success',
            title: 'Atualização realizada!'
          });
        }
        // Sem autorização
        else if (data  && data.httpcode && response.data.httpcode === 401) {
          Toast.fire({
            icon: 'warning',
            title: 'Sem autorização!'
          });
        }
        // Não Autorizado (Generico)
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });
        }

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

            // Desfragmentação do erro
            const {
              status
            } = error.response;
  
            // Não autorizado
            if(status == 401){
              // Swal de notificação
              AutenticacaoInvalida();
            }
            // Erro generico
            else {
              Toast.fire({
                icon: 'warning',
                title: 'Ops, tivemos um problema!'
              });
            }
            
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });
        }
        
      });

  }

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <HelmetProvider>
              <Helmet>
                <title>Pega Troco | Conta</title>
              </Helmet>
            </HelmetProvider>
            <div className="appHeader">
              <div className="left">
                <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
              </div>
              <div className="pageTitle">
                Conta
              </div>
            </div>
            <div id="appCapsule">
              <div className="section inset mt-2">
                <div className="row justify-content-center">
                  <div className="col-md-7 align-self-center">

                    <form method="POST" onSubmit={AtualizarCliente}>

                      <h3>Dados pessoais:</h3>
                      <div className="card mb-5">
                        <div className="card-body pb-1">
                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="nome">Nome completo</label>
                              <input
                                type="text"
                                className="form-control"
                                id="nome"
                                placeholder="Seu nome completo"
                                required
                                onChange={event => setNome(LetraMask(event.target.value))}
                                value={nome}
                              />
                            </div>
                          </div>

                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="email">E-mail</label>
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                autoComplete="off"
                                placeholder="Seu e-mail"
                                disabled
                                onChange={event => setEmail(event.target.value)}
                                value={email}
                              />
                            </div>
                          </div>

                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="telefone">Telefone</label>
                              <input
                                type="tel"
                                className="form-control"
                                id="telefone"
                                autoComplete="off"
                                placeholder="Número de telefone"
                                onChange={event => setTelefone(CelularMask(event.target.value))}
                                value={telefone}
                                maxLength={15}
                                required
                              />
                            </div>
                          </div>

                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="cpf">CPF</label>
                              <input
                                type="tel"
                                className="form-control"
                                id="cpf"
                                autoComplete="off"
                                placeholder="Número do cpf"
                                disabled
                                onChange={event => setCpf(cpfMask(event.target.value))}
                                value={cpf}
                              />
                            </div>
                          </div>

                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="data-nascimento">Data de nascimento</label>
                              <input
                                type="tel"
                                className="form-control"
                                id="data-nascimento"
                                autoComplete="off"
                                placeholder="Sua data de nascimento"
                                onChange={event => setDataNascimento(DataMask(event.target.value))}
                                value={dataNascimento}
                              />
                            </div>
                          </div>

                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="rg">RG</label>
                              <input
                                type="text"
                                className="form-control"
                                id="rg"
                                autoComplete="off"
                                placeholder="Número do rg"
                                onChange={event => setRg(event.target.value)}
                                value={rg}
                              />
                            </div>
                          </div>

                          <div className="form-group basic">
                            <div className="input-wrapper">
                              <label className="label" htmlFor="data-emissao">Data de emissão rg</label>
                              <input
                                type="tel"
                                className="form-control"
                                id="data-emissao"
                                autoComplete="off"
                                placeholder="Data de emissão do RG"
                                onChange={event => setDataEmissao(DataMask(event.target.value))}
                                value={dataEmissao}
                              />
                            </div>
                          </div>

                        </div>
                      </div>

                      <h3>Informação de residência:</h3>

                      <div className="card">
                        <div className="card-body pb-1">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="endereco">Endereço</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="endereco"
                                    placeholder="Seu endereço"
                                    onChange={event => setEndereco(event.target.value)}
                                    value={endereco}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="numero">Número</label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="numero"
                                    placeholder="Número do endereço"
                                    onChange={event => setNumero(event.target.value)}
                                    value={numero}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="bairro">Bairro</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="bairro"
                                    placeholder="Seu bairro"
                                    onChange={event => setBairro(event.target.value)}
                                    value={bairro}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="cep">Cep</label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="cep"
                                    placeholder="Cep"
                                    onChange={event => setCep(CepMask(event.target.value))}
                                    value={cep}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="cidade">Cidade</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="cidade"
                                    placeholder="Sua cidade"
                                    onChange={event => setCidade(event.target.value)}
                                    value={cidade}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col">
                              <div className="form-group basic">
                                <div className="input-wrapper">
                                  <label className="label" htmlFor="uf">UF</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="uf"
                                    placeholder="UF"
                                    onChange={event => setUf(event.target.value)}
                                    value={uf}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>


                      <button type="submit" className="btn btn-primary btn-block btn-lg mt-5 mb-5">Salvar Edição</button>

                    </form>
                  </div>
                </div>
              </div>
            </div>
            <MenuFooter></MenuFooter>
          </>
      }
    </>
  );
}

export default Conta;