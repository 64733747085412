import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import LodingIcon from '../../assets/img/loading-icon.png';
import Logo from '../../assets/img/pegatroco.png';
import ModalTermo from "../../components/modal-termo";

import { auth } from '../../services/firebase';

import '../../assets/css/style.css';
import Swal from 'sweetalert2';

const RedefinirSenha = () => {

  const [loading, setLoading] = useState(true);
  const [senha, setSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');

  var url_atual = window.location.search;
  const urlParams = new URLSearchParams(url_atual);
  const actionCode = urlParams.get('oobCode');

  if(actionCode == null){
    window.location = "/login";
  }

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // Verificando se Action Code é valido
  async function VericarCode(code){

    const consulta = await auth.verifyPasswordResetCode(code)
      .then((result) => {
        
        return {'httpcode':200};
        
      })
      .catch((error) => {

        return {'httpcode':401};

      });

    return consulta;
  }

  // Redefinir Senha
  async function RedefinirSenha(event){

    event.preventDefault();

    if(senha === confirmacaoSenha){

      const verificaCode = await VericarCode(actionCode);

      if(verificaCode.httpcode && verificaCode.httpcode === 200){
        await auth.confirmPasswordReset(actionCode, senha)
        .then((result) => {
  
          Toast.fire({
            icon: 'success',
            title: 'Senha redefinida com sucesso!'
          });
          
          window.location = '/login'
          
        })
        .catch((error) => {
         
          Toast.fire({
            icon: 'warning',
            title: 'Ops, tivemos um problema!'
          });
  
          window.location = '/login';
          
        });
      }
      else {
        Toast.fire({
          icon: 'info',
          title: 'Sua solicitação para redefinir a senha expirou !'
        });

        window.location = '/esqueceu-senha';
      }
      
    }
    else {
      Toast.fire({
        icon: 'warning',
        title: 'As senhas são diferentes!'
      });
    }


  }

  const elementLoading = <div id="loader">
                          <img src={LodingIcon} alt="icon" className="loading-icon"/>
                        </div>;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  
  return (
    <>
      {
        loading 
        ? 
        elementLoading :
        <>
          <div id="appCapsule">
            <HelmetProvider>
              <Helmet>
                <title>Pegatroco | Redefinição de senha</title>
              </Helmet>
            </HelmetProvider>
            <div className="section mt-2 text-center">
                <img src={Logo} alt="Logo" className="img-fluid mb-2" width="500"></img>
                <h4>Altere sua senha, para continuar :D</h4>
            </div>
            <div className="section mb-5 p-2">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-5 align-self-cente">
                  <form method="POST" onSubmit={RedefinirSenha}>
                      <div className="card">
                          <div className="card-body pb-1">

                              <div className="form-group basic">
                                  <div className="input-wrapper">
                                      <label className="label" htmlFor="password1">Senha</label>
                                      <input 
                                        type="password"
                                        className="form-control"
                                        id="password1"
                                        autoComplete="off"
                                        placeholder="Sua senha"
                                        required
                                        onChange={event => setSenha(event.target.value)}
                                        value={senha}
                                      />
                                  </div>
                              </div>

                              <div className="form-group basic">
                                  <div className="input-wrapper">
                                      <label className="label" htmlFor="password2">Confirmação da senha</label>
                                      <input
                                        type="password"
                                        className="form-control"
                                        id="password2"
                                        autoComplete="off"
                                        placeholder="Confirme a senha"
                                        required
                                        onChange={event => setConfirmacaoSenha(event.target.value)}
                                        value={confirmacaoSenha}
                                      />
                                  </div>
                              </div>

                              <button type="submit" className="btn btn-primary btn-block btn-lg mt-5">
                                Redefinir
                              </button>

                          </div>
                      </div>


                      <div className="form-links mt-2">
                          <div>
                              <a href="/login">Lembrou sua senha!</a>
                          </div>
                      </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
          <ModalTermo></ModalTermo>
        </>
      }
    </>
  );
}

export default RedefinirSenha;