export const Bancos = () => {

    const bancos = [
        {
            "value": "41",
            "label": "Banco Banrisul"
        },
        {
            "value": "318",
            "label": "Banco BMG"
        },
        {
            "value": "237",
            "label": "Banco Bradesco"
        },
        {
            "value": "104",
            "label": "Banco Caixa"
        },
        {
            "value": "1",
            "label": "Banco do Brasil"
        },
        {
            "value": "77",
            "label": "Banco Inter"
        },
        {
            "value": "341",
            "label": "Banco Itaú"
        },
        {
            "value": "389",
            "label": "Banco Mercantil"
        },
        {
            "value": "212",
            "label": "Banco Original"
        },
        {
            "value": "623",
            "label": "Banco Pan"
        },
        {
            "value": "422",
            "label": "Banco Safra"
        },
        {
            "value": "33",
            "label": "Banco Santander"
        },
        {
            "value": "756",
            "label": "Banco Sicoob"
        },
        {
            "value": "748",
            "label": "Banco Sicredi"
        },
        {
            "value": "655",
            "label": "Banco Votorantim"
        },
        {
            "value": "336",
            "label": "C6 Bank"
        },
        {
            "value": "260",
            "label": "Nubank"
        },
        {
            "value": "380",
            "label": "Picpay"
        }
    ]
    return bancos;
}
