import axios from "axios";

import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import LodingIcon from '../../assets/img/loading-icon.png';
import Logo from '../../assets/img/pegatroco.png';

import ReactPixel from 'react-facebook-pixel';

import '../../assets/css/style.css';
import Swal from 'sweetalert2';
import { cpfMask } from "../../tools/CpfMask";
import { ValidacaoCpf } from "../../tools/ValidacaoCpf";
import { useNavigate } from "react-router-dom";

const Cadastre = () => {

  const navigate = useNavigate();

  const [cadastro, setCadastro] = useState(1);
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(true);
  const [perfil, setPerfil] = useState('');
  const url_atual = window.location.search;

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // Steper Cadastro
  async function steperCadastro(values = {}) {

    // Step
    var step = values.step;

    // Nome/Telefone
    if (step === 2) {

      var perfil = values.perfil;

      // Track INSS
      if (perfil === 'inss') {
        ReactPixel.trackCustom('IniciouCadastroConsignado');
      }
      // Track FGTS
      else if (perfil === 'fgts') {
        ReactPixel.trackCustom('IniciouCadastroFgts');
      }
      // Track Auxilio Brasil
      else if (perfil === 'auxilio-brasil') {
        ReactPixel.trackCustom('IniciouCadastroAuxilioBrasil');
      }
      // Track Outros
      else {
        ReactPixel.trackCustom('IniciouCadastroOutros');
      }

      setPerfil(perfil);

      sessionStorage.setItem('perfil', perfil);

      setCadastro(2);
    }
  }

  //Verifica existência do cadastro
  async function verificaCadastro() {

    setLoading(true)

    if (!cpf) {
      setLoading(false)
      Toast.fire({
        icon: 'warning',
        title: 'Informe seu CPF para continuar!'
      });
    }
    else {

      setLoading(false)

      const valida_cpf = ValidacaoCpf(cpf)

      if (!valida_cpf) {
        Toast.fire({
          icon: 'warning',
          title: 'Por favor digite um CPF válido'
        });
      }
      else {

        sessionStorage.setItem('cpf', cpf);

        var config = {
          method: 'GET',
          url: process.env.REACT_APP_URL_API + `/cliente/usuario?cpf=${cpf}&usuario=true`,
          data: ''
        }
        axios(config)
          .then(function (response) {

            // Stop Loading
            setLoading(false);

            // Desfragmentação do response
            const {
              data
            } = response;

            // Retorno da Consulta (usuário localizado)
            if (data && data.usuario) {

              // E-mail do cliente
              const email = data && data.email ? data.email : '';

              Swal.fire({
                icon: `info`,
                html: `<p class="fs-5 text-dark">O CPF informado já possuí um cadastro, acesse com o email: <span class="fw-bold">${email}</span> <br>Caso não reconheça o e-mail informado, você pode contatar nosso suporte!</p>`,
                showDenyButton: true,
                confirmButtonText: 'Fazer Login',
                denyButtonText: `Falar com Suporte`,
                confirmButtonColor: `#11d962`,
                allowOutsideClick: false
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  navigate('/login');
                } else if (result.isDenied) {
                  window.location.href = 'https://api.whatsapp.com/send?phone=551433138461';
                }
              })

            }
            // Usuário não localizado
            else {
              setLoading(false)
              Toast.fire({
                icon: 'success',
                title: 'Cadastro iniciado'
              });

              navigate('/completar-cadastro')

            }

          })

          .catch(function (error) {

            // Stop Loading
            setLoading(false);

            // Retorno de response
            if (error.response) {

              // Desfragmentação do erro
              const {
                status
              } = error.response;
    
              // Não localizado
              if(status == 404){
                Toast.fire({
                  icon: 'success',
                  title: 'Cadastro iniciado'
                });
  
                navigate('/completar-cadastro')
  
              }
              // Erro generico
              else {
                Swal.fire({
                  icon: `warning`,
                  html: `<p class="fs-4 text-dark">Ops, tivemos um problema. Você pode entrar em contato com nosso suporte para receber ajuda.</p>`,
                  confirmButtonText: 'Falar com Suporte',
                  confirmButtonColor: `#11d962`,
                  allowOutsideClick: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    window.location.href = 'https://api.whatsapp.com/send?phone=551433138461';
                  }
                })
              }
              
            }
            // Erro generico
            else {
              Swal.fire({
                icon: `warning`,
                html: `<p class="fs-4 text-dark">Ops, tivemos um problema. Você pode entrar em contato com nosso suporte para receber ajuda.</p>`,
                confirmButtonText: 'Falar com Suporte',
                confirmButtonColor: `#11d962`,
                allowOutsideClick: false
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  window.location.href = 'https://api.whatsapp.com/send?phone=551433138461';
                }
              })
            }
          })
      }
    }

  }

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <div id="appCapsule">
              <HelmetProvider>
                <Helmet>
                  <title>Pegatroco | Cadastro</title>
                </Helmet>
              </HelmetProvider>
              <div className="section mt-2 text-center">
                <img src={Logo} alt="Logo" className="img-fluid mb-2" width="500"></img>
              </div>
              <div className="section mb-5 p-2">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-5 align-self-center">
                    <div className="card">
                      <div className="card-body pb-1">
                        {

                          // Perfil
                          cadastro === 1 ? (

                            <>
                              <div className="section-heading">
                                <h2 className="title">Você é...</h2>
                              </div>
                              <div className="transactions form-group basic">
                                <button className="btn btn-outline-dark btn-block border border-secondary item pt-4 pb-4" onClick={() => steperCadastro({ 'step': 2, 'perfil': 'inss' })}>
                                  <div className="">
                                    <div>
                                      <p className="text-dark fs-5">
                                        Aposentado e Pensionista do INSS
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right text-dark">
                                    <i className="fas fa-angle-right fs-3"></i>
                                  </div>
                                </button>
                                <button className="btn btn-outline-dark btn-block border border-secondary item mt-4 pt-4 pb-4 lh-1" onClick={() => steperCadastro({ 'step': 2, 'perfil': 'representante-legal' })}>
                                  <div className="">
                                    <div>
                                      <p className="text-dark fs-5">
                                        Representante Legal de Beneficiários INSS.
                                      </p>
                                    </div>
                                  </div>
                                  <div className="right text-dark">
                                    <i className="fas fa-angle-right fs-3"></i>
                                  </div>
                                </button>
                                <button className="btn btn-outline-dark btn-block border border-secondary item mt-4 pt-4 pb-4" onClick={() => steperCadastro({ 'step': 2, 'perfil': 'fgts' })}>
                                  <div className="">
                                    <div>
                                      <strong className="text-dark fs-5">
                                        Trabalhador CLT com FGTS ativo ou inativo
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="right text-dark">
                                    <i className="fas fa-angle-right fs-3"></i>
                                  </div>
                                </button>
                                <button className="btn btn-outline-dark btn-block border border-secondary item mt-4 pt-4 pb-4" onClick={() => steperCadastro({ 'step': 2, 'perfil': 'auxilio-brasil' })}>
                                  <div className="">
                                    <div>
                                      <strong className="text-dark fs-5">
                                        Auxílio Brasil
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="right text-dark">
                                    <i className="fas fa-angle-right fs-3"></i>
                                  </div>
                                </button>
                                <button className="btn btn-outline-dark btn-block border border-secondary item mt-4 pt-4 pb-4" onClick={() => steperCadastro({ 'step': 2, 'perfil': 'siape' })}>
                                  <div className="">
                                    <div>
                                      <strong className="text-dark fs-5">
                                        SIAPE - Servidor Federal
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="right text-dark">
                                    <i className="fas fa-angle-right fs-3"></i>
                                  </div>
                                </button>
                                <button className="btn btn-outline-dark btn-block border border-secondary item mt-4 mb-4 pt-4 pb-4" onClick={() => steperCadastro({ 'step': 2, 'perfil': 'fgts' })}>
                                  <div className="">
                                    <div>
                                      <strong className="text-dark fs-5">
                                        Outros
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="right text-dark">
                                    <i className="fas fa-angle-right fs-3"></i>
                                  </div>
                                </button>
                              </div>

                            </>

                          ) : (
                            cadastro === 2 ? (
                              <>
                                <div className="form-group basic">
                                  <p>Precisamos que informe os dados abaixo para que possamos iniciar o seu cadastro.</p>
                                  <div className="input-wrapper">
                                    <label className="label" htmlFor="celular">Número do CPF</label>
                                    <input
                                      type="tel"
                                      className="form-control"
                                      id="cpf"
                                      placeholder="Digite seu CPF"
                                      onChange={event => setCpf(cpfMask(event.target.value))}
                                      value={cpf}
                                      maxLength={15}
                                      required
                                    />
                                  </div>

                                </div>

                                <button className="btn btn-primary btn-block btn-lg mt-5"
                                  onClick={() => { verificaCadastro() }}
                                >
                                  {loading ? 'Iniciando...' : 'Iniciar Cadastro'}
                                </button>
                              </>
                            ) :
                              <></>
                          )
                        }
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  );
}

export default Cadastre;