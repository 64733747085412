import axios from "axios";

const ConsultaIp = async () => {

  var data = '';

  var config = {
    method: 'get',
    url: `https://api.ipify.org/?format=json`,
    data : data,
    timeout: 20000
  };

  const retorno_consulta = await axios(config)
  .then(function (response) {

    if(response && response.data && response.data.ip){

      const obj_retorno = {
        'httpcode':200,
        'ip':response.data.ip
      }

      return obj_retorno;
    }
    else {
      return {'httpcode':400};
    }
  })
  .catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return {'httpcode':error.response.status};
    } else {
      // Something happened in setting up the request that triggered an Error
      return {'httpcode':500};
    }
  });

  return retorno_consulta;

}

export default ConsultaIp;
