import React from "react";

import '../../assets/css/style.css';

import Header from "../../components/header";
import MenuFooter from "../../components/menu-footer";
import Produtos from "../../components/produtos";

import { PrimeiroNome } from '../../tools/PrimeiroNome';

import ReactPixel from 'react-facebook-pixel';

const Home = () => {

  // Trackview
  ReactPixel.trackCustom('PageViewHome');
                      
  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));
  
  return (
    <div>
      <Header></Header>
      <div id="appCapsule">
        <div className="section wallet-card-section">
          <div className="row justify-content-center">
            <div className="col-md-7 align-self-center ">
            
              <div className="wallet-card">
                  
                  <div className="balance">
                      <div className="left">
                        <h1 className="total">Olá, {primeiroNome}</h1>
                        <span className="title">Como podemos te ajudar hoje?</span>
                      </div>
                      {/* <div className="right">
                        <a href="#" className="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                          <ion-icon name="add-outline"></ion-icon>
                        </a>
                      </div> */}
                  </div>
                  
              </div>
            </div>
            <Produtos></Produtos>
          </div>
        </div>
      </div>
      
      <MenuFooter></MenuFooter>
    </div>
  );
}

export default Home;