import React, { useState } from "react";
import axios from "axios";

import Swal from 'sweetalert2';

import LodingIcon from '../../assets/img/loading-icon.png';
import FgtsLogo from '../../assets/img/FGTS.png'
import InssLogo from '../../assets/img/inss.png'
import BpcLoasLogo from '../../assets/img/bpcLoas.png'
import SiapeLogo from '../../assets/img/Siape.png'
import cataoBeneficio from '../../assets/img/cataoBeneficio.png'
import cataoBeneficioLoas from '../../assets/img/cataoBeneficioLoas.png'
import RepublicaLogo from '../../assets/img/republica-federativa-brasil.png'
import Crefaz from '../../assets/img/crefaz.png'

import { PrimeiroNome } from '../../tools/PrimeiroNome';

import { firestore } from '../../services/firebase';

import ReactPixel from 'react-facebook-pixel';

import { FaInfoCircle } from "react-icons/fa";
import simulacaoSiape from "../../controllers/siape/simulacaoSiape";


const Produtos = () => {

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const primeiroNome = PrimeiroNome(sessionStorage.getItem('nome'));

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  const elementLoading = <div id="loader">
    <img src={LodingIcon} alt="icon" className="loading-icon" />
  </div>;

  // Simulação Cartão
  async function simularCartao() {

    // Trackview
    ReactPixel.trackCustom('ClicouCartaoConsignado');

    // Inicia Loading
    setLoading(true);

    // Token
    const token = sessionStorage.getItem('identify');

    var config = {
      method: 'POST',
      url: process.env.REACT_APP_URL_API + `/simulacao`,
      data: {
        'tipo_simulacao': 'cartao'
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    };

    axios(config)
      .then(async function (response) {

        // Para Loading
        setLoading(false);

        // Desfragmentação do response
        const {
          data
        } = response;

        // Simulação gerada
        if (data && data.uuid_simulacao) {

          // Registrando as simulações na session storage
          sessionStorage.setItem('obj_simulacao', JSON.stringify(data));

          // Retorno das simulações
          const simulacoes = data.simulacoes ? data.simulacoes : null;
          const uuid = data.uuid_simulacao;

          // Redirecionamento
          window.location = `/contratacao/${uuid}`;

        }
        // Aprovado (mas não segue para autocontratação)
        else if (data && data.status) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, recebemos a sua solicitação.<br><br> Em breve um de nossos especialistas entrará em contato para finalizar o seu atendimento.</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `OK`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = '/';
            }
          });
        }
        // Reprovado (ENQUADROU FACTA FÁCIL)
        else if (data && data.facta_facil) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, temos uma proposta de crédito com débito em conta para você</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Falar com atendente`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = 'https://api.whatsapp.com/send?phone=551433138461';
            }
          });

        }
        // Reprovado (Não enquadrou/falta de saldo)
        else if (data && data.status === false) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, não conseguimos realizar sua simulação, um dos motivos para que isso tenha ocorrido, é de que no momento você não possua o saldo mínimo necessário para a contratação. <br><br>Mas fique tranquilo, que estamos aperfeiçoando nossos métodos de consulta para que possamos atende-lo, e por isso pedimos que realize uma solicitação novamente em breve.</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `OK`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = '/';
            }
          });

        }
        // Proposta em andamento
        else if (data && data.andamento) {
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, você já possuí uma proposta em andamento e pode acompanhar suas atualizações na aba  CONTRATOS.</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Ver contrato`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = '/contratos';
            }
          });

        }
        // Blacklist
        else if(data && data.blacklist){
          Swal.fire({
            // icon: 'info',
            // title: `😕 !`,
            html: `<p align="left"><strong class="fs-5">${primeiroNome}</strong>, Acabei de verificar que você fez uma simulação recentemente conosco, já estou te encaminhando para nosso atendente continuar o seu atendimento. 😁</p>`,
            confirmButtonColor: '#4a68b0',
            confirmButtonText: `Falar com atendente`,
            allowOutsideClick: false
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              Swal.close();
              window.location = 'https://api.whatsapp.com/send?phone=551433138461';
            }
          });
        }
        // Não Localizado
        else {
          Toast.fire({
            icon: 'warning',
            title: 'Tivemos um problema para realizar a simulação!'
          });
        }

      })
      .catch(function (error) {

        // Stop Loading
        setLoading(false);

        if (error.response) {

            // Desfragmentação do erro
            const {
              status
            } = error.response;
  
            // Não autorizado
            if(status == 401){
              // Swal de notificação
              AutenticacaoInvalida();
            }
            // Erro generico
            else {
              Toast.fire({
                icon: 'warning',
                title: 'Ops, tivemos um problema!'
              });
            }
            
        }
        // Erro generico
        else {
          Toast.fire({
            icon: 'error',
            title: 'Simulação temporariamente indisponível!'
          });
        }
      });
  }

  async function simularSiape() {
    setButtonLoading(true);

    await simulacaoSiape();

    setButtonLoading(false);
  }

  return (
    <>
      {
        loading
          ?
          elementLoading :
          <>
            <div className="section mb-5">
              <div className="row justify-content-center">
                <div className="col-md-7 align-self-center">

                  <div className="row justify-content-center">

                    {/* Crédito Consignado */}
                    <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <img className="me-2" src={InssLogo} alt="emprestimo-inss" />
                        {/* <span className="text-dark fs-5">|</span>
                        <img className="ms-2" src={BpcLoasLogo} alt="emprestimo-inss" /> */}
                        <div className="title-card fs-6 my-2 text-dark">Empréstimo Consignado</div>
                        <hr className="hr-card"></hr>
                        <p className="text-end juros-card">Juros à partir de: 1,45%</p>

                        <ul className="caracteristicas-produto">
                          <li>Disponível para Aposentado e Pensionista.</li>
                          <li>Valor mínimo para empréstimo: <span className="fw-bold">R$750,00.</span></li>
                          <li>Parcelas mensais de até: <span className="fw-bold">84x.</span></li>
                          <li>Pagamento após 2 horas.</li>
                        </ul>

                        <div className="d-flex justify-content-center">
                          {/* <button className="button-info btn">
                            <FaInfoCircle size={19} />
                          </button> */}
                          <a href="/simulacao-inss" className="btn button-produtos">
                            Simular
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Cartão Benefício INSS */}
                    <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <img className="me-2" src={cataoBeneficio} alt="cartao beneficio inss" />
                        {/* <span className="text-dark fs-5">|</span>
                        <img className="ms-2" src={cataoBeneficioLoas} alt="cartao beneficio bpc loas" /> */}
                        <div className="title-card fs-6 mt-3 my-2 text-dark">Cartão Benefício</div>
                        <hr className="hr-card"></hr>
                        <p className="text-end juros-card">Juros à partir de: 2,89%</p>

                        <ul className="caracteristicas-produto">
                          <li>Disponível para Aposentado e Pensionista.</li>
                          <li>Valor mínimo para empréstimo: <span className="fw-bold">R$500,00.</span></li>
                          <li>Parcelas mensais de até: <span className="fw-bold">84x.</span></li>
                          <li>Pagamento após 2 horas.</li>
                        </ul>

                        <div className="d-flex justify-content-center mb-lg-1">
                          {/* <button className="button-info btn">
                            <FaInfoCircle size={19} />
                          </button> */}

                          <button className="btn button-produtos" onClick={simularCartao}>
                            Simular
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Antecipação FGTS */}
                    <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <img src={FgtsLogo} alt="emprestimo-fgts" />
                        <div className="title-card fs-6 my-2 text-dark">Empréstimo Saque Aniversário</div>
                        <hr className="hr-card"></hr>
                        <p className="text-end juros-card">Juros à partir de: 1,69%</p>

                        <ul className="caracteristicas-produto">
                          <li>Disponível para todos com saldo ativo ou inativo no FGTS.</li>
                          <li>Valor mínimo para empréstimo: <span className="fw-bold">R$75,00.</span></li>
                          <li>Parcelas anuais: <span className="fw-bold">5 a 10.</span></li>
                          <li>Pagamento em até 30 minutos.</li>
                        </ul>

                        <div className="d-flex justify-content-center mb-lg-2">
                          {/* <button className="button-info btn">
                            <FaInfoCircle size={19} />
                          </button> */}
                          <a href="/autorizacao" className="btn button-produtos">
                            Simular
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Crefaz  */}
                    <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <div className="news-product text-center mb-2">Novo</div>
                        <img className="me-2" src={Crefaz} alt="Imagem bandeira do Brasil" width="45"/>
                        <div className="title-card fs-6 mt-2 my-2 text-dark">Empréstimo na Conta de Luz</div>
                        <hr className="hr-card"></hr>

                        <ul className="caracteristicas-produto mb-5">
                          <li>Disponível para titular da conta de luz.</li>
                          <li>Valor mínimo para empréstimo: <span className="fw-bold">R$400,00.</span></li>
                          <li>Parcelas mensais de até: <span className="fw-bold">22x.</span></li>
                          <li>Pagamento em até 24 horas úteis.</li>
                        </ul>

                        <div className="d-flex justify-content-center">
                          <a href="/simulacao-energia" className="btn button-produtos">
                            Simular
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* Margem do aumento INSS */}
                    {/* <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <div className="news-product text-center mb-2">Nova Margem</div>
                        <img className="me-2" src={InssLogo} alt="emprestimo-inss" />
                        <div className="title-card fs-6 mt-2 my-2 text-dark">Aumento de Margem</div>
                        <hr className="hr-card"></hr>
                        <p className="text-end juros-card">Juros à partir de: 1,45%</p>

                        <ul className="caracteristicas-produto mb-5">
                          <li>Disponível para Aposentado e Pensionista.</li>
                          <li>Valor mínimo para empréstimo: <span className="fw-bold">R$500,00.</span></li>
                          <li>Parcelas mensais de até: <span className="fw-bold">84x.</span></li>
                        </ul>

                        <div className="d-flex justify-content-center">

                          <a href="/simulacao-margem-complementar" className="btn button-produtos">
                            Simular
                          </a>
                        </div>
                      </div>
                    </div> */}

                    {/* Crédito Consignado Servidor Publico*/}
                    <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <img src={RepublicaLogo} alt="emprestimo-servidor" />
                        <div className="title-card fs-6 mt-2 my-2 text-dark">Empréstimo para Servidor Público</div>
                        <hr className="hr-card"></hr>
                        <p className="text-end juros-card">Juros à partir de: 2,05%</p>

                        <ul className="caracteristicas-produto">
                          <li>Disponível para servidor federal.</li>
                          <li>Valor mínimo para empréstimo: <span className="fw-bold">R$2.000,00.</span></li>
                          <li>Parcelas mensais de até: <span className="fw-bold">96x.</span></li>
                          <li>Pagamento em até 48 horas.</li>
                        </ul>

                        <div className="d-flex justify-content-center">
                          {
                            buttonLoading ? 
                              <>
                                <button className="d-flex align-items-center justify-content-center btn btn-lg button-produtos-spinner mx-2" disabled>
                                  <div className="spinner-border spinner-border-sm" role="status"></div>
                                  <p className='m-0 mx-2'> Simulando</p>
                                </button>
                              </> :
                                <button className="btn button-produtos"
                                  onClick={() => simularSiape()}>
                                  Simular
                                </button>
                          }
                        </div>
                      </div>
                    </div>

                    {/* Auxilio Brasil
                    <div className="col-md-6 mt-2">
                      <div className="stat-box">
                        <img src={BandeiraBrasil} className="img-fluid" alt="Imagem bandeira do Brasil" width="45" />
                        <div className="title fs-6 mb-2 text-dark">Auxílio Brasil</div>
                        <button className="btn btn-success" disabled>
                          Temporariamente indisponível
                        </ button>
                      </div>
                    </div> */}

                  </div>
                  <div className="row">
                    {/* Crédito com garantia de imóvel */}
                    {/* <div className="col-md-6 mt-2">
                    <div className="stat-box">
                        <i className="fas fa-home fa-2x text-primary"></i>
                        <div className="title fs-6 text-dark">Crédito com garantia de imóvel</div>
                        <br></br>
                        <p>
                          Utilize seu veículo ou imóvel como garantia, e tenha as melhores taxas!
                        </p>
                        <button className="btn btn-primary">
                          Simular
                        </button>
                    </div>
                  </div> */}
                    {/* Crédito com garantia de veiculo */}
                    {/* <div className="col mt-2">
                    <div className="stat-box">
                        <i className="fas fa-car-alt fa-2x text-primary"></i>
                        <div className="title fs-6 text-dark">Crédito com garantia de veículo</div>
                        <br></br>
                        <p>
                          Utilize seu veículo ou imóvel como garantia, e tenha as melhores taxas!
                        </p>
                        <button className="btn btn-primary">
                          Simular
                        </button>
                    </div>
                  </div> */}
                  </div>

                </div>
              </div>
            </div>
            <div className="section mb-2">
              <div className="row mt-2 justify-content-center">
                <div className="col-md-7 align-self-center">
                  {/* Precisa de Ajuda */}
                  <div className="bill-box">
                    <div className="img-wrapper">
                    </div>
                    <div className="price">Precisando de ajuda?</div>
                    <p className="fs-6">Estamos prontos para te ajuda.</p>

                    <a href="/duvidas" className="btn btn-primary">
                      Dúvidas
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  );

}

export default Produtos;