import MenuFooter from "../../components/menu-footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import ReactPixel from 'react-facebook-pixel';

// Modals
import ModalPoliticaPrivacidade from "../../components/modal-politica-privacidade";
import ModalTermoUso from "../../components/modal-termo-uso";

const Duvidas = () => {
  
  // Trackview
  ReactPixel.trackCustom('ClicouDuvidas');

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Pega Troco | Dúvidas</title>
        </Helmet>
      </HelmetProvider>
      <div className="appHeader">
          <div className="left">
              <a href="/" className="headerButton goBack">
                  <ion-icon name="chevron-back-outline"></ion-icon>
              </a>
          </div>
          <div className="pageTitle">
              Dúvidas
          </div>
      </div>
      <div id="appCapsule">

        {/* Dúvidas frequentes */}
        <div className="section inset mt-2">
          <div className="row justify-content-center">
            <div className="col-md-7 align-self-center">
              <div className="accordion" id="accordionExample1">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion01">
                            O que é empréstimo consignado?
                        </button>
                    </h2>
                    <div id="accordion01" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div className="accordion-body text-dark">
                          O empréstimo consignado é aquele tipo de crédito destinado a aposentados e pensionistas que você solicita e passa a ter o valor das parcelas cobrado direto na folha de pagamento ou seja, o desconto é feito diretamente no salário ou na aposentadoria. Dessa forma os juros são os menores da modalidade de crédito pessoal.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion02">
                            Quem pode contratar o Empréstimo Consignado?
                        </button>
                    </h2>
                    <div id="accordion02" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div className="accordion-body text-dark">
                          Essa modalidade de crédito está disponível apenas para aposentados e pensionistas do INSS, e também para servidores públicos. Se você se encaixa em algum desses grupos você pode solicitar o seu crédito consignado, seja para organizar a vida financeira ou tirar um projeto do papel.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion03">
                            O que é o Saque Aniversário do FGTS?
                        </button>
                    </h2>
                    <div id="accordion03" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div className="accordion-body text-dark">
                          Essa é uma nova modalidade de crédito para o trabalhador que optar pelo saque aniversário e possuir saldo disponível em sua conta FGTS, ativa ou inativa. Dessa forma poderá utilizar o FGTS como garantia para realizar a antecipação de até 10 parcelas anuais do saque aniversário FGTS.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion04">
                            O empréstimo com garantia do Saque Aniverśario do FGTS compromente outras linhas de crédito?
                        </button>
                    </h2>
                    <div id="accordion04" className="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div className="accordion-body text-dark">
                          Não. Esta linha de crédito não compromete a contratação de outras linhas de crédito e não depende de margem consignável.
                        </div>
                    </div>
                </div>

                {/* O que é Auxilio Brasil */}

                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion05">
                      O que é Auxílio Brasil
                    </button>
                  </h2>
                  <div id="accordion05" className="accordion-collapse collapse" data-bs-parent="#accordionExample5">
                    <div className="accordion-body text-dark">
                      O Auxílio Brasil é o novo programa social do governo federal, criado para substituir o Bolsa Família. De acordo com o Ministério da Cidadania, responsável pelo benefício, o Auxílio Brasil “integra em um só programa de benefícios as várias políticas públicas de assistência social, saúde, educação, emprego e renda”. O valor mínimo é de R$400,00, mas os valores máximos variam para cada benefício.
                    </div>
                  </div>
                </div> */}

                {/* Como funciona o empréstimo para Auxilio Brasil */}

                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion06">
                      Como funciona o empréstimo para Auxílio Brasil?
                    </button>
                  </h2>
                  <div id="accordion06" className="accordion-collapse collapse" data-bs-parent="#accordionExample6">
                    <div className="accordion-body text-dark">
                      O empréstimo consignado Auxílio Brasil terá o valor descontado do próprio benefício Auxílio Brasil. O valor do Empréstimo Auxílio Brasil comprometerá até 40% do valor do Auxílio Brasil. Desses 40%, é possível utilizar 5% para saque ou amortização de dívidas no cartão. Ou seja, quem recebe o Auxílio Brasil de 400 reais terá direito a parcelas de R$160,00 do empréstimo Auxílio Brasil (40% de R$ 400,00). Além disso, o empréstimo terá um prazo de até 24 meses, além de juros mais baixos, cerca de 2% a.m.
                    </div>
                  </div>
                </div> */}


                {/* Quando os contratos estarão disponíveis para pagamentos */}
                {/* <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#accordion07">
                      Quando os contratos do Auxílio Brasil estarão disponíveis para pagamento?
                    </button>
                  </h2>
                  <div id="accordion07" className="accordion-collapse collapse" data-bs-parent="#accordionExample7">
                    <div className="accordion-body text-dark">
                      Todas as solicitações começarão a ser encaminhadas para pagamento a partir do dia 17/07.                    
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Termo de privacidade/Uso */}
        <div className="section inset mt-2">
          <div className="row justify-content-center">
            <div className="col-md-7 align-self-center">
              <div className="accordion">
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed termos" type="button" data-bs-toggle="modal" data-bs-target="#politicaPrivacidadeModal">
                          Política de privacidade
                        </button>
                    </h2>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed termos" type="button" data-bs-toggle="modal" data-bs-target="#termosUsoModal">
                          Termos de uso
                        </button>
                    </h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section mb-2">
          <div className="row mt-2 justify-content-center">
            <div className="col-md-7 align-self-center">
              {/* Precisa de Ajuda */}
              <div className="bill-box">
                  <div className="img-wrapper">
                      {/* <img src="assets/img/sample/brand/1.jpg" alt="img" className="image-block imaged w48"> */}
                  </div>
                  <div className="price">Ainda continua com dúvidas?</div>
                  <p className="fs-6">Entre em contato conosco:</p>
                  <a href="https://api.whatsapp.com/send?phone=551433138461" className="btn btn-success" target="_blank" rel="noreferrer">
                    <i className="fab fa-whatsapp fs-6 mx-2"></i>
                    WhatsApp
                  </a>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Politica de privacidade */}
        <ModalPoliticaPrivacidade></ModalPoliticaPrivacidade>
        {/* Modal Termo de uso */}
        <ModalTermoUso></ModalTermoUso>
      </div>
      <MenuFooter></MenuFooter>
    </>
  );
}

export default Duvidas;