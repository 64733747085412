import axios from "axios";

const enviarMensagem = async (dados) => {

    // Token
    const token = sessionStorage.getItem('identify');

    // Obj para request
    const data = {
       ...dados,
        departamento: process.env.REACT_APP_DEPARTAMENTO_SIAPE_DIGISAC
    }

    var config = {
        method: 'POST',
        url: process.env.REACT_APP_URL_API + '/mensagem/enviar',
        data: data,
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }
    const retorno_mensagem = await axios(config)
        .then(function (response) {
            return response?.data;
        })
        .catch(function (error) {
            if (error.response) {

                // Desfragmentação do erro
                const {
                  status
                } = error.response;
      
                return {
                    httpcode: status,
                    enviado: false,
                    response: 'Não foi possivel enviar a mensagem!'
                }
                
            }
            // Erro generico
            else {
              return {
                httpcode: 500,
                enviado: false,
                response: 'Erro ao enviar mensagem!'
              }
            }
        })
    return retorno_mensagem;

}
export default enviarMensagem;